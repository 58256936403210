.u-center-text {
  text-align: center !important;
}

.u-justify-text {
  text-align: justify !important;
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-medium {
  margin-top: 4rem !important;
}
 
.u-margin-top-huge {
  margin-top: 10rem !important;
}

.u-margin-top-14 {
  margin-top: 14rem !important;
}

.u-margin-right-small {
  margin-right: 3rem !important;
}
.u-border-small {
  border-width: 0.1rem;
  border-style: solid;
  border-color: $color-black;
}

.u-clear-margin-right {
  margin-right: 0 !important;
}

.u-right {
  text-align: right;
}

.u-clear-padding {
  padding: 0 !important;
}


.u-mt-1x {
  margin-top: 1rem !important;
}
.u-margin-right-clear {
  margin-right: 0 !important;
}

.u-width-auto {
  width: auto !important;
}

.u-min-width-sm {
  min-width: 15rem;
}
.u-min-width-md {
  min-width: 25rem;
}

.u-min-width-lg {
  min-width: 35rem;
}

.u-overflow-hide {
  overflow: hidden;
}

.bg-color-light-1 {
  background-color: $color-grey-light-1;
}

.bg-color-light-2 {
  background-color: $color-grey-light-2;
}

.bg-color-dark {
  background-color: $color-grey-dark;
}

.bg-color-grey-dark-2 {
  background-color: $color-grey-dark-2;
}
.bg-color-grey-dark-3 {
  background-color: $color-grey-dark-3;
}
