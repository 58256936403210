/*
COLORS:
*/

$color-yellow: #ffc107;
$color-skyblue-light: #93c7c3;
$color-primary: #3399ff;
$color-primary-light: #66ccff;
$color-primary-dark: #30b2ff;

$color-secondary-light: #484581;
$color-secondary-dark: #ff7730;
$color-secondary-light-1: #EEEDFD;

$color-tertiary-light: #e97951;
$color-tertiary-dark: #5643fa;
$color-tertiary-dark-1: #484581;
$color-tertiary-light-1: #FCF3EF;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eeeeee;

$color-grey-dark: #777777;
$color-grey-dark-2: #999999;
$color-grey-dark-3: #333333;

$color-white: #ffffff;
$color-black: #000000;
$color-danger: #bb0606;

//FONT
$default-font-size: 1rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-horizontal: 6rem;
