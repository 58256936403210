.card-one {
  min-height: 25rem;
  position: relative;
  width: 80%;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -40%);
  &-left {
    position: absolute;
    width: 4rem;
    height: 15rem;
    background-color: $color-secondary-light;
    border-radius: 2rem;
    bottom: 1rem;
    z-index: 1;
    box-shadow: 0 3px 6px rgba($color-secondary-light, 20%);
  }
  &-body {
    position: absolute;
    width: 92%;
    height: 80%;
    background-color: $color-white;
    border-radius: 2rem;
    border: 0.4rem solid $color-white;
    left: 22%;
    top: 50%;
    transform: translate(-20%, -50%);
    z-index: 2;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 20%);
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }
  &-right {
    position: absolute;
    width: 4rem;
    height: 15rem;
    background-color: $color-tertiary-light;
    border-radius: 2rem;
    right: 0;
    top: 5%;
    z-index: 1;
    box-shadow: 0 3px 6px rgba($color-tertiary-light, 20%);
  }
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

.card-two {
  width: 70%;
  display: flex;
  background: #eeedfd;
  margin: 2rem;
  border-radius: 1rem;
  color: $color-tertiary-dark-1;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 0 3px 8px rgba($color-secondary-light, 50%);
  }

  &-img {
    width: 15rem;
    height: 15rem;
    padding: 2rem;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  &-h6 {
    align-items: center;
    display: flex;
  }
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

.card-three {
  display: flex;
  justify-content: space-between;
  background: rgba($color-tertiary-light, 20%);
  padding: 1rem 2rem;
  align-items: center;
  height: 12rem;
  &-img {
    padding: 1.5rem;
  }

  & body {
    background: none;
  }
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

.card-four {
  background: none !important;
  box-shadow: none !important;

  & body {
    margin-top: 1rem;
    background: none !important;
  }
}

.card-mini {
  display: inline-block;
  padding: 1rem 2rem;
  text-align: center;
  background: white;
  border-radius: 2rem;
  margin: 2rem;
  & img {
    width: 18rem;
    height: 18rem;
  }
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
    margin: 1rem;
    padding: 1rem;
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

.card-tech {
  min-height: 25rem;
  position: relative;
  width: 80%;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);

  &-two {
    width: 60%;
    margin: 0 auto;
    text-align: center;

    &-media {
      width: 25rem;
      padding: 2rem;
      margin-top: 3rem;
      margin-bottom: 2rem;
    }
    &-title {
      margin-bottom: 1rem;
    }
  }
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

.card-tile {
  width: 5rem;
  height: 5rem;
  display: inline-block;

  &:not(:last-child) {
    margin-right: 2rem;
    margin-bottom: 3rem;
  }
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

.card-connect {
  min-height: 25rem;
  position: relative;

  left: 50%;
  top: 40%;
  transform: translate(-50%, -40%);
  text-align: center;
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}
