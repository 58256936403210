$icomoon-font-family: "LineIcons-Light" !default;
$icomoon-font-path: "fonts" !default;

$lnil-24-7: "\f38c";
$lnil-24-hours-truck: "\f38d";
$lnil-3d: "\f386";
$lnil-5g: "\f387";
$lnil-5g-alt: "\f388";
$lnil-5g-alt-1: "\f389";
$lnil-5g-sim: "\f38a";
$lnil-360-degree: "\f38b";
$lnil-hen: "\e900";
$lnil-scythe: "\e901";
$lnil-tomato: "\e902";
$lnil-cow: "\e903";
$lnil-weathercock: "\e904";
$lnil-pruning-shears: "\e905";
$lnil-windmill: "\e906";
$lnil-chicken: "\e907";
$lnil-barn: "\e908";
$lnil-watering-can: "\e909";
$lnil-tob-plant: "\e90a";
$lnil-seeds-1: "\e90b";
$lnil-sunflower: "\e90c";
$lnil-silo: "\e90d";
$lnil-reservoir-1: "\e90e";
$lnil-scythe-1: "\e90f";
$lnil-seeds: "\e910";
$lnil-egg: "\e911";
$lnil-flower: "\e912";
$lnil-sprout: "\e913";
$lnil-reservoir: "\e914";
$lnil-grass: "\e915";
$lnil-well: "\e916";
$lnil-baloon-alt-3: "\e917";
$lnil-baloon-alt-2: "\e918";
$lnil-firework-alt-1: "\e919";
$lnil-candy-corn: "\e91a";
$lnil-candle: "\e91b";
$lnil-christmas-star: "\e91c";
$lnil-candy: "\e91d";
$lnil-confetti: "\e91e";
$lnil-ballon-alt-1: "\e91f";
$lnil-candy-alt-1: "\e920";
$lnil-cake: "\e921";
$lnil-firecrackers-alt: "\e922";
$lnil-fireworks: "\e923";
$lnil-candy-alt-2: "\e924";
$lnil-candy-alt-3: "\e925";
$lnil-bowtie-alt: "\e926";
$lnil-petard: "\e927";
$lnil-spray: "\e928";
$lnil-firecrackers-alt-4: "\e929";
$lnil-lolipop-1: "\e92a";
$lnil-firecracker: "\e92b";
$lnil-lolipop-3: "\e92c";
$lnil-mask: "\e92d";
$lnil-pumpkin: "\e92e";
$lnil-candy-cane: "\e92f";
$lnil-lolipop-2: "\e930";
$lnil-heart-baloon: "\e931";
$lnil-firecrackers-alt-3: "\e932";
$lnil-flags: "\e933";
$lnil-firecrackers-alt-2: "\e934";
$lnil-ballon-alt: "\e935";
$lnil-guitar: "\e936";
$lnil-candy-alt: "\e937";
$lnil-firecrackers-alt-1: "\e938";
$lnil-baloon: "\e939";
$lnil-candle-alt-1: "\e93a";
$lnil-wand: "\e93b";
$lnil-skull-mask: "\e93c";
$lnil-birthday-hat: "\e93d";
$lnil-lolipop: "\e93e";
$lnil-candle-alt: "\e93f";
$lnil-mustache: "\e940";
$lnil-bell-remove: "\e941";
$lnil-time-smartphone: "\e942";
$lnil-clock-alt: "\e943";
$lnil-notification-alt-1: "\e944";
$lnil-quarter-alt-1: "\e945";
$lnil-three-querters: "\e946";
$lnil-hour: "\e947";
$lnil-wristwatch: "\e948";
$lnil-hourglass-alt: "\e949";
$lnil-bell-alt: "\e94a";
$lnil-calender-minus: "\e94b";
$lnil-alarm-timer: "\e94c";
$lnil-add-notification: "\e94d";
$lnil-time: "\e94e";
$lnil-timer-chat: "\e94f";
$lnil-time-settings: "\e950";
$lnil-time-folder: "\e951";
$lnil-timer-desktop: "\e952";
$lnil-wristwatch-alt: "\e953";
$lnil-bell-mute: "\e954";
$lnil-notification: "\e955";
$lnil-half-time: "\e956";
$lnil-mute: "\e957";
$lnil-half-an-hour: "\e958";
$lnil-timer-desktop-alt: "\e959";
$lnil-minus-notification: "\e95a";
$lnil-quarter-alt: "\e95b";
$lnil-smartwatch-alt: "\e95c";
$lnil-browser-timer: "\e95d";
$lnil-clock-alt-1: "\e95e";
$lnil-alarm-check: "\e95f";
$lnil-secure-time: "\e960";
$lnil-bell-add: "\e961";
$lnil-time-share: "\e962";
$lnil-bell-alt-1: "\e963";
$lnil-three-querters-alt: "\e964";
$lnil-alarm-clock-alt: "\e965";
$lnil-history: "\e966";
$lnil-smartphone-watch-alt: "\e967";
$lnil-timer: "\e968";
$lnil-laptop-timer: "\e969";
$lnil-t-shirt: "\e96a";
$lnil-crown-alt-1: "\e96b";
$lnil-old: "\e96c";
$lnil-crown-alt-2: "\e96d";
$lnil-thought: "\e96e";
$lnil-home: "\e96f";
$lnil-family: "\e970";
$lnil-t-shirt-alt: "\e971";
$lnil-baby-kid: "\e972";
$lnil-glasses: "\e973";
$lnil-sunglass: "\e974";
$lnil-crown: "\e975";
$lnil-cup-alt: "\e976";
$lnil-old-man: "\e977";
$lnil-gift-bag: "\e978";
$lnil-crown-alt: "\e979";
$lnil-gift-1: "\e97a";
$lnil-gift: "\e97b";
$lnil-cotton-bud: "\e97c";
$lnil-t-shirt-alt-1: "\e97d";
$lnil-cup: "\e97e";
$lnil-ladies-t-shirt: "\e97f";
$lnil-family-alt: "\e980";
$lnil-columns: "\e981";
$lnil-grid: "\e982";
$lnil-grid-alt: "\e983";
$lnil-pallet: "\e984";
$lnil-highlight-alt: "\e985";
$lnil-layout-2: "\e986";
$lnil-layout-3: "\e987";
$lnil-spiral-tool: "\e988";
$lnil-shape-builder-tool: "\e989";
$lnil-layout-1: "\e98a";
$lnil-highlight: "\e98b";
$lnil-layers-alt-1: "\e98c";
$lnil-free-transform: "\e98d";
$lnil-color-palette: "\e98e";
$lnil-align-left-2: "\e98f";
$lnil-delete-anchor-point: "\e990";
$lnil-rotate: "\e991";
$lnil-align-left-3: "\e992";
$lnil-bottom: "\e993";
$lnil-crop: "\e994";
$lnil-right-align: "\e995";
$lnil-vector: "\e996";
$lnil-up-align-1: "\e997";
$lnil-up-align-3: "\e998";
$lnil-vector-alt: "\e999";
$lnil-transform: "\e99a";
$lnil-align-left-1: "\e99b";
$lnil-image-crop: "\e99c";
$lnil-move: "\e99d";
$lnil-up-align-2: "\e99e";
$lnil-layout-alt: "\e99f";
$lnil-layout-alt-2: "\e9a0";
$lnil-header-1: "\e9a1";
$lnil-brush: "\e9a2";
$lnil-rounded-rectangle: "\e9a3";
$lnil-ellipse: "\e9a4";
$lnil-outer-align: "\e9a5";
$lnil-magic-wand: "\e9a6";
$lnil-size: "\e9a7";
$lnil-layout-alt-1: "\e9a8";
$lnil-layers: "\e9a9";
$lnil-grid-1: "\e9aa";
$lnil-inner-align: "\e9ab";
$lnil-sidebar-1: "\e9ac";
$lnil-down-align-1: "\e9ad";
$lnil-grid-alt-1: "\e9ae";
$lnil-down-align: "\e9af";
$lnil-minus-front: "\e9b0";
$lnil-poligon: "\e9b1";
$lnil-down-align-3: "\e9b2";
$lnil-down-align-2: "\e9b3";
$lnil-panel-1: "\e9b4";
$lnil-add-anchor-point: "\e9b5";
$lnil-align-right: "\e9b6";
$lnil-ux: "\e9b7";
$lnil-panel-2: "\e9b8";
$lnil-brush-alt: "\e9b9";
$lnil-sidebar: "\e9ba";
$lnil-rotate-alt: "\e9bb";
$lnil-table: "\e9bc";
$lnil-panel-3: "\e9bd";
$lnil-vector-pen: "\e9be";
$lnil-corner: "\e9bf";
$lnil-columns-1: "\e9c0";
$lnil-arc: "\e9c1";
$lnil-align-right-2: "\e9c2";
$lnil-corners: "\e9c3";
$lnil-up-align: "\e9c4";
$lnil-panel-4: "\e9c5";
$lnil-intersection: "\e9c6";
$lnil-resize: "\e9c7";
$lnil-margin: "\e9c8";
$lnil-columns-2: "\e9c9";
$lnil-panel-5: "\e9ca";
$lnil-align-right-1: "\e9cb";
$lnil-select: "\e9cc";
$lnil-layers-alt: "\e9cd";
$lnil-crop-alt: "\e9ce";
$lnil-rows: "\e9cf";
$lnil-rows-1: "\e9d0";
$lnil-center-align-1: "\e9d1";
$lnil-center-align-3: "\e9d2";
$lnil-rows-2: "\e9d3";
$lnil-center-align-2: "\e9d4";
$lnil-panel: "\e9d5";
$lnil-center-align-6: "\e9d6";
$lnil-no-border: "\e9d7";
$lnil-bottom-1: "\e9d8";
$lnil-header: "\e9d9";
$lnil-center-align: "\e9da";
$lnil-center-align-5: "\e9db";
$lnil-slice: "\e9dc";
$lnil-center-align-4: "\e9dd";
$lnil-scaling: "\e9de";
$lnil-layout: "\e9df";
$lnil-align-left: "\e9e0";
$lnil-passport: "\e9e1";
$lnil-hand: "\e9e2";
$lnil-checkmark: "\e9e3";
$lnil-list: "\e9e4";
$lnil-lock-alt-1: "\e9e5";
$lnil-round-box-minus: "\e9e6";
$lnil-funnel-alt: "\e9e7";
$lnil-list-alt: "\e9e8";
$lnil-information: "\e9e9";
$lnil-funnel-alt-1: "\e9ea";
$lnil-pointer: "\e9eb";
$lnil-lock-alt-2: "\e9ec";
$lnil-lock-alt-3: "\e9ed";
$lnil-heart-plus: "\e9ee";
$lnil-hotel-sign: "\e9ef";
$lnil-thumbs-down-alt: "\e9f0";
$lnil-circle: "\e9f1";
$lnil-radio-button: "\e9f2";
$lnil-funnel-alt-2: "\e9f3";
$lnil-music: "\e9f4";
$lnil-round-box-cross: "\e9f5";
$lnil-ticket: "\e9f6";
$lnil-round-box-plus: "\e9f7";
$lnil-sign-language-alt: "\e9f8";
$lnil-ticket-alt: "\e9f9";
$lnil-question-circle: "\e9fa";
$lnil-thumbs-up-alt: "\e9fb";
$lnil-lock: "\e9fc";
$lnil-key: "\e9fd";
$lnil-more-alt: "\e9fe";
$lnil-thumbs-down: "\e9ff";
$lnil-shield: "\ea00";
$lnil-lifering: "\ea01";
$lnil-bolt: "\ea02";
$lnil-flag-alt: "\ea03";
$lnil-funnel: "\ea04";
$lnil-playlist: "\ea05";
$lnil-flag: "\ea06";
$lnil-record: "\ea07";
$lnil-plus: "\ea08";
$lnil-shield-alt-1-check: "\ea09";
$lnil-music-note: "\ea0a";
$lnil-warning: "\ea0b";
$lnil-infinite: "\ea0c";
$lnil-barrier: "\ea0d";
$lnil-close: "\ea0e";
$lnil-check-circle: "\ea0f";
$lnil-heart-filled: "\ea10";
$lnil-round-box-check: "\ea11";
$lnil-passport-4: "\ea12";
$lnil-thumbs-up: "\ea13";
$lnil-notification1: "\ea14";
$lnil-playlist-alt: "\ea15";
$lnil-lock-alt: "\ea16";
$lnil-more-alt-1: "\ea17";
$lnil-key-alt-2: "\ea18";
$lnil-more: "\ea19";
$lnil-key-alt-3: "\ea1a";
$lnil-circle-plus: "\ea1b";
$lnil-passport-2: "\ea1c";
$lnil-idea-alt: "\ea1d";
$lnil-key-alt-1: "\ea1e";
$lnil-passport-alt: "\ea1f";
$lnil-checkmark-circle: "\ea20";
$lnil-unlock: "\ea21";
$lnil-shield-alt-2-check: "\ea22";
$lnil-passport-3: "\ea23";
$lnil-key-alt: "\ea24";
$lnil-check-box: "\ea25";
$lnil-teabag: "\ea26";
$lnil-ticket-alt-2: "\ea27";
$lnil-shield-alt-3: "\ea28";
$lnil-protection: "\ea29";
$lnil-eye: "\ea2a";
$lnil-electricity: "\ea2b";
$lnil-power-switch: "\ea2c";
$lnil-shield-alt-2: "\ea2d";
$lnil-ticket-alt-3: "\ea2e";
$lnil-identity: "\ea2f";
$lnil-list-alt-1: "\ea30";
$lnil-thunderbolt: "\ea31";
$lnil-sort: "\ea32";
$lnil-ticket-alt-1: "\ea33";
$lnil-switch: "\ea34";
$lnil-sign-language: "\ea35";
$lnil-shield-alt-1: "\ea36";
$lnil-bolt-alt: "\ea37";
$lnil-circle-minus: "\ea38";
$lnil-help: "\ea39";
$lnil-invention: "\ea3a";
$lnil-cross-circle: "\ea3b";
$lnil-find: "\ea3c";
$lnil-minus: "\ea3d";
$lnil-eye-alt: "\ea3e";
$lnil-shield-alt-3-check: "\ea3f";
$lnil-heart: "\ea40";
$lnil-calculator-paper: "\ea41";
$lnil-shopping-bag-15: "\ea42";
$lnil-online-shopping: "\ea43";
$lnil-shopping-cart-4: "\ea44";
$lnil-dollar-bubble: "\ea45";
$lnil-like: "\ea46";
$lnil-commercial-machine: "\ea47";
$lnil-truck-secure: "\ea48";
$lnil-pen-alt: "\ea49";
$lnil-discount-label: "\ea4a";
$lnil-air-mail: "\ea4b";
$lnil-shopping-cart-5: "\ea4c";
$lnil-shopping-bag-14: "\ea4d";
$lnil-dollars-gift: "\ea4e";
$lnil-shopping-bag-16: "\ea4f";
$lnil-ship-delivery-box: "\ea50";
$lnil-ship-star: "\ea51";
$lnil-shopping-cart-7: "\ea52";
$lnil-boxes: "\ea53";
$lnil-truck-up-arrow: "\ea54";
$lnil-remove-basket: "\ea55";
$lnil-shopping-cart-6: "\ea56";
$lnil-coupon-scissors: "\ea57";
$lnil-truck-feedback: "\ea58";
$lnil-shopping-bag-17: "\ea59";
$lnil-truck-private: "\ea5a";
$lnil-package-box: "\ea5b";
$lnil-shopping-bag-13: "\ea5c";
$lnil-fast-delivery: "\ea5d";
$lnil-truck-search: "\ea5e";
$lnil-bar-code-search: "\ea5f";
$lnil-dollar-bundle: "\ea60";
$lnil-shopping-cart-2: "\ea61";
$lnil-cargo-mover: "\ea62";
$lnil-ship-favourite: "\ea63";
$lnil-piggy-bank-1: "\ea64";
$lnil-saving-pig: "\ea65";
$lnil-label-off: "\ea66";
$lnil-shopping-cart-3: "\ea67";
$lnil-shopping-bag-12: "\ea68";
$lnil-dollar-coin-stack: "\ea69";
$lnil-ship-down-arrow: "\ea6a";
$lnil-ship-locked: "\ea6b";
$lnil-dollar-monitor: "\ea6c";
$lnil-shopping-bag-10: "\ea6d";
$lnil-telephone-3: "\ea6e";
$lnil-shopping-cart-1: "\ea6f";
$lnil-fuel: "\ea70";
$lnil-piggy-bank-2: "\ea71";
$lnil-box-percentage: "\ea72";
$lnil-int-ecommerce: "\ea73";
$lnil-truck-down-arrow: "\ea74";
$lnil-truck-blocked: "\ea75";
$lnil-box-open: "\ea76";
$lnil-telephone-2: "\ea77";
$lnil-add-basket: "\ea78";
$lnil-shopping-bag-11: "\ea79";
$lnil-box-3: "\ea7a";
$lnil-ship-reload: "\ea7b";
$lnil-box-remove: "\ea7c";
$lnil-piggy-bank: "\ea7d";
$lnil-basket-2: "\ea7e";
$lnil-ship-international: "\ea7f";
$lnil-truck-location: "\ea80";
$lnil-dislike: "\ea81";
$lnil-bills: "\ea82";
$lnil-box-2: "\ea83";
$lnil-truck-star: "\ea84";
$lnil-ship: "\ea85";
$lnil-calculator-card: "\ea86";
$lnil-mathmetics: "\ea87";
$lnil-basket-1: "\ea88";
$lnil-truck-global: "\ea89";
$lnil-delivery-truck-4: "\ea8a";
$lnil-medal-first: "\ea8b";
$lnil-box-1: "\ea8c";
$lnil-monitor-dollar-lock: "\ea8d";
$lnil-shopping-bag-9: "\ea8e";
$lnil-box-5: "\ea8f";
$lnil-shopping-cart-12: "\ea90";
$lnil-delivery-truck-1: "\ea91";
$lnil-credit-cards: "\ea92";
$lnil-close-sign: "\ea93";
$lnil-credit-card-lock: "\ea94";
$lnil-shield-alt: "\ea95";
$lnil-price-tag: "\ea96";
$lnil-aeroplane-delivery: "\ea97";
$lnil-newspaper: "\ea98";
$lnil-cube: "\ea99";
$lnil-ship-container: "\ea9a";
$lnil-box-4: "\ea9b";
$lnil-shopping-bag-8: "\ea9c";
$lnil-paid-sign: "\ea9d";
$lnil-truck-box: "\ea9e";
$lnil-ship-check: "\ea9f";
$lnil-box-6: "\eaa0";
$lnil-dump-truck: "\eaa1";
$lnil-shopping-cart-11: "\eaa2";
$lnil-oil-tanker: "\eaa3";
$lnil-box-10: "\eaa4";
$lnil-open-sign: "\eaa5";
$lnil-delivery-truck-2: "\eaa6";
$lnil-delivery-truck-3: "\eaa7";
$lnil-shopping-cart-10: "\eaa8";
$lnil-euro-coin-stack: "\eaa9";
$lnil-box-7: "\eaaa";
$lnil-truck-box-1: "\eaab";
$lnil-shopping-bag-6: "\eaac";
$lnil-coins: "\eaad";
$lnil-price-tag-1: "\eaae";
$lnil-like-3: "\eaaf";
$lnil-add-cart: "\eab0";
$lnil-percentage-ticket: "\eab1";
$lnil-padlock: "\eab2";
$lnil-truck-warning: "\eab3";
$lnil-box-add: "\eab4";
$lnil-dollar-symbol: "\eab5";
$lnil-like-2: "\eab6";
$lnil-shopping-bag-7: "\eab7";
$lnil-badge: "\eab8";
$lnil-box-9: "\eab9";
$lnil-shopping-bag-5: "\eaba";
$lnil-trailer: "\eabb";
$lnil-ship-thumbs-up: "\eabc";
$lnil-percentage-alt: "\eabd";
$lnil-shopping-bag-4: "\eabe";
$lnil-box-8: "\eabf";
$lnil-ship-unknown: "\eac0";
$lnil-truck-reload: "\eac1";
$lnil-parcel-air: "\eac2";
$lnil-express-shopping: "\eac3";
$lnil-check-sign: "\eac4";
$lnil-label-new: "\eac5";
$lnil-shopping-bag-1: "\eac6";
$lnil-faq: "\eac7";
$lnil-shopping-bag-3: "\eac8";
$lnil-cash: "\eac9";
$lnil-credit-card-delete: "\eaca";
$lnil-hours-truck: "\eacb";
$lnil-shopping-bag-2: "\eacc";
$lnil-7: "\eacd";
$lnil-search-store: "\eace";
$lnil-ship-search: "\eacf";
$lnil-locked-window: "\ead0";
$lnil-ship-delivery-box-alt-1: "\ead1";
$lnil-shopping-mobile: "\ead2";
$lnil-sign-pole: "\ead3";
$lnil-ship-stop: "\ead4";
$lnil-id-card: "\ead5";
$lnil-truck-unknown: "\ead6";
$lnil-truck-timer: "\ead7";
$lnil-box-cart: "\ead8";
$lnil-ship-delivery-box-alt: "\ead9";
$lnil-delivery-truck: "\eada";
$lnil-headphones-alt: "\eadb";
$lnil-ship-cargo: "\eadc";
$lnil-invoice-1: "\eadd";
$lnil-profits: "\eade";
$lnil-remove-cart: "\eadf";
$lnil-star-dollar: "\eae0";
$lnil-money-security: "\eae1";
$lnil-ship-secure: "\eae2";
$lnil-secure-shopping: "\eae3";
$lnil-finger-shopping: "\eae4";
$lnil-bills-1: "\eae5";
$lnil-truck-checked: "\eae6";
$lnil-add-cart-2: "\eae7";
$lnil-calculator: "\eae8";
$lnil-money-bag: "\eae9";
$lnil-ship-timer: "\eaea";
$lnil-shopping-cart-8: "\eaeb";
$lnil-int-commercial-mail: "\eaec";
$lnil-sale-sign: "\eaed";
$lnil-ship-up-arrow: "\eaee";
$lnil-parachute-parcel: "\eaef";
$lnil-dollar-settings: "\eaf0";
$lnil-shopping-cart-9: "\eaf1";
$lnil-ship-warning: "\eaf2";
$lnil-add-cart-1: "\eaf3";
$lnil-comments-alt-1: "\eaf4";
$lnil-chat-alt-3: "\eaf5";
$lnil-chat-alt-1: "\eaf6";
$lnil-chat-alt: "\eaf7";
$lnil-chat-alt-4: "\eaf8";
$lnil-chat-alt-5: "\eaf9";
$lnil-chat-alt-7: "\eafa";
$lnil-chat-check: "\eafb";
$lnil-chat-alt-6: "\eafc";
$lnil-chat-alt-12: "\eafd";
$lnil-chat-alt-8: "\eafe";
$lnil-bubble-alt: "\eaff";
$lnil-chat-alt-10: "\eb00";
$lnil-chat-alt-11: "\eb01";
$lnil-chat-alt-9: "\eb02";
$lnil-chat-delete: "\eb03";
$lnil-chat-check-alt: "\eb04";
$lnil-comments-alt: "\eb05";
$lnil-chat-add-alt: "\eb06";
$lnil-chat-remove: "\eb07";
$lnil-chat-add: "\eb08";
$lnil-squares-1: "\eb09";
$lnil-triangle-6: "\eb0a";
$lnil-triangle-7: "\eb0b";
$lnil-dots: "\eb0c";
$lnil-triangle-5: "\eb0d";
$lnil-triangle-4: "\eb0e";
$lnil-squares-3: "\eb0f";
$lnil-circle1: "\eb10";
$lnil-hexagons-1: "\eb11";
$lnil-triangle-3: "\eb12";
$lnil-squares: "\eb13";
$lnil-hexagons: "\eb14";
$lnil-triangle-9: "\eb15";
$lnil-triangle-8: "\eb16";
$lnil-rhombus: "\eb17";
$lnil-triangles: "\eb18";
$lnil-bootstrap: "\eb19";
$lnil-connectdevelop: "\eb1a";
$lnil-medium: "\eb1b";
$lnil-soundcloud-original: "\eb1c";
$lnil-telegram-original: "\eb1d";
$lnil-figma: "\eb1e";
$lnil-imdb: "\eb1f";
$lnil-steam: "\eb20";
$lnil-tumblr: "\eb21";
$lnil-firefox-original: "\eb22";
$lnil-dropbox: "\eb23";
$lnil-microsoft: "\eb24";
$lnil-squarespace: "\eb25";
$lnil-javascript: "\eb26";
$lnil-cloudflare: "\eb27";
$lnil-airbnb: "\eb28";
$lnil-drupal: "\eb29";
$lnil-dev: "\eb2a";
$lnil-dropbox-original: "\eb2b";
$lnil-android: "\eb2c";
$lnil-adobe: "\eb2d";
$lnil-visa: "\eb2e";
$lnil-aws: "\eb2f";
$lnil-java: "\eb30";
$lnil-twitter-original: "\eb31";
$lnil-bitbucket: "\eb32";
$lnil-creative-commons: "\eb33";
$lnil-github: "\eb34";
$lnil-google-drive: "\eb35";
$lnil-px: "\eb36";
$lnil-facebook-oval: "\eb37";
$lnil-netlify: "\eb38";
$lnil-joomla: "\eb39";
$lnil-npm: "\eb3a";
$lnil-instagram-filled: "\eb3b";
$lnil-gatsby: "\eb3c";
$lnil-ycombinator: "\eb3d";
$lnil-angular: "\eb3e";
$lnil-vk: "\eb3f";
$lnil-vimeo: "\eb40";
$lnil-hacker-news: "\eb41";
$lnil-apple-music: "\eb42";
$lnil-instagram: "\eb43";
$lnil-shopify: "\eb44";
$lnil-microsoft-edge: "\eb45";
$lnil-lineicons: "\eb46";
$lnil-edge: "\eb47";
$lnil-python: "\eb48";
$lnil-docker: "\eb49";
$lnil-git: "\eb4a";
$lnil-blogger: "\eb4b";
$lnil-discover: "\eb4c";
$lnil-mailchimp: "\eb4d";
$lnil-facebook-original: "\eb4e";
$lnil-envato: "\eb4f";
$lnil-bitcoin: "\eb50";
$lnil-instagram-original: "\eb51";
$lnil-telegram: "\eb52";
$lnil-markdown: "\eb53";
$lnil-goodreads: "\eb54";
$lnil-drupal-original: "\eb55";
$lnil-magento: "\eb56";
$lnil-diners-club: "\eb57";
$lnil-nodejs-alt: "\eb58";
$lnil-spotify: "\eb59";
$lnil-google-wallet: "\eb5a";
$lnil-nodejs: "\eb5b";
$lnil-slideshare: "\eb5c";
$lnil-snapchat: "\eb5d";
$lnil-html5: "\eb5e";
$lnil-quora: "\eb5f";
$lnil-slack-line: "\eb60";
$lnil-laravel: "\eb61";
$lnil-wordpress-filled: "\eb62";
$lnil-bluetooth-original: "\eb63";
$lnil-ubuntu: "\eb64";
$lnil-paypal: "\eb65";
$lnil-facebook-line: "\eb66";
$lnil-play-store: "\eb67";
$lnil-unsplash: "\eb68";
$lnil-playstation: "\eb69";
$lnil-facebook: "\eb6a";
$lnil-amazon-pay: "\eb6b";
$lnil-amazon: "\eb6c";
$lnil-css-3: "\eb6d";
$lnil-pinterest: "\eb6e";
$lnil-mastercard: "\eb6f";
$lnil-wechat: "\eb70";
$lnil-whatsapp: "\eb71";
$lnil-google: "\eb72";
$lnil-chromecast: "\eb73";
$lnil-android-original: "\eb74";
$lnil-line: "\eb75";
$lnil-atlassian: "\eb76";
$lnil-php: "\eb77";
$lnil-stripe: "\eb78";
$lnil-apple-pay: "\eb79";
$lnil-stackoverflow: "\eb7a";
$lnil-amex: "\eb7b";
$lnil-joomla-original: "\eb7c";
$lnil-azure: "\eb7d";
$lnil-paypal-original: "\eb7e";
$lnil-playstore: "\eb7f";
$lnil-google-pay: "\eb80";
$lnil-twitch: "\eb81";
$lnil-windows: "\eb82";
$lnil-btc: "\eb83";
$lnil-linkedin-original: "\eb84";
$lnil-youtube: "\eb85";
$lnil-app-store: "\eb86";
$lnil-github-original: "\eb87";
$lnil-stumbleupon: "\eb88";
$lnil-jcb: "\eb89";
$lnil-jsfiddle: "\eb8a";
$lnil-yahoo: "\eb8b";
$lnil-producthunt: "\eb8c";
$lnil-sketch: "\eb8d";
$lnil-reddit: "\eb8e";
$lnil-angellist: "\eb8f";
$lnil-codepen: "\eb90";
$lnil-dribbble: "\eb91";
$lnil-xbox: "\eb92";
$lnil-trello: "\eb93";
$lnil-linkedin: "\eb94";
$lnil-react: "\eb95";
$lnil-twitter: "\eb96";
$lnil-patreon: "\eb97";
$lnil-swift: "\eb98";
$lnil-discord: "\eb99";
$lnil-opera: "\eb9a";
$lnil-facebook-filled: "\eb9b";
$lnil-css3: "\eb9c";
$lnil-slack: "\eb9d";
$lnil-soundcloud: "\eb9e";
$lnil-skype: "\eb9f";
$lnil-cpanel: "\eba0";
$lnil-wordpress: "\eba1";
$lnil-behance-original: "\eba2";
$lnil-spotify-original: "\eba3";
$lnil-facebook-messenger: "\eba4";
$lnil-chrome: "\eba5";
$lnil-apple: "\eba6";
$lnil-lineicons-alt: "\eba7";
$lnil-digitalocean: "\eba8";
$lnil-amazon-original: "\eba9";
$lnil-tiktok: "\ebaa";
$lnil-behance: "\ebab";
$lnil-firefox: "\ebac";
$lnil-flickr: "\ebad";
$lnil-twitter-filled: "\ebae";
$lnil-luggage: "\ebaf";
$lnil-bowtie: "\ebb0";
$lnil-high-heels: "\ebb1";
$lnil-home1: "\ebb2";
$lnil-candelabra: "\ebb3";
$lnil-wedding-arch: "\ebb4";
$lnil-microphone: "\ebb5";
$lnil-love-potion: "\ebb6";
$lnil-love-alt: "\ebb7";
$lnil-shopping-wedding: "\ebb8";
$lnil-wedding-dress: "\ebb9";
$lnil-love-letter: "\ebba";
$lnil-clipboard: "\ebbb";
$lnil-car-wedding: "\ebbc";
$lnil-location-wedding: "\ebbd";
$lnil-bell-wedding: "\ebbe";
$lnil-bed-wedding: "\ebbf";
$lnil-padlock-love: "\ebc0";
$lnil-wedding-video-camera: "\ebc1";
$lnil-wedding-song: "\ebc2";
$lnil-toast: "\ebc3";
$lnil-cupcake: "\ebc4";
$lnil-invitation: "\ebc5";
$lnil-champagne: "\ebc6";
$lnil-love: "\ebc7";
$lnil-plate: "\ebc8";
$lnil-cupid-alt: "\ebc9";
$lnil-wedding-cake: "\ebca";
$lnil-necklace: "\ebcb";
$lnil-door-hanger: "\ebcc";
$lnil-diamond: "\ebcd";
$lnil-calendar-wedding: "\ebce";
$lnil-video-wedding: "\ebcf";
$lnil-flower1: "\ebd0";
$lnil-dove: "\ebd1";
$lnil-gift1: "\ebd2";
$lnil-cupid: "\ebd3";
$lnil-bouquet: "\ebd4";
$lnil-wedding-shoe: "\ebd5";
$lnil-hearts: "\ebd6";
$lnil-balloons-wedding: "\ebd7";
$lnil-camera-wedding: "\ebd8";
$lnil-kiss: "\ebd9";
$lnil-key-wedding: "\ebda";
$lnil-ring: "\ebdb";
$lnil-bow-tie: "\ebdc";
$lnil-waiter: "\ebdd";
$lnil-eraser: "\ebde";
$lnil-school-bench: "\ebdf";
$lnil-flask-alt: "\ebe0";
$lnil-lockers: "\ebe1";
$lnil-book-alt: "\ebe2";
$lnil-certificate-alt: "\ebe3";
$lnil-sketchbook: "\ebe4";
$lnil-world-2: "\ebe5";
$lnil-h2o: "\ebe6";
$lnil-library: "\ebe7";
$lnil-quill: "\ebe8";
$lnil-backpack: "\ebe9";
$lnil-whiteboard: "\ebea";
$lnil-brackets: "\ebeb";
$lnil-books-alt: "\ebec";
$lnil-books: "\ebed";
$lnil-clipboard1: "\ebee";
$lnil-certificate: "\ebef";
$lnil-graduate-alt: "\ebf0";
$lnil-ebook: "\ebf1";
$lnil-cubes: "\ebf2";
$lnil-telescope: "\ebf3";
$lnil-flask-alt-1: "\ebf4";
$lnil-certificate-alt-1: "\ebf5";
$lnil-knowledge: "\ebf6";
$lnil-flask-alt-2: "\ebf7";
$lnil-book: "\ebf8";
$lnil-medal-alt: "\ebf9";
$lnil-whiteboard-alt-2: "\ebfa";
$lnil-sinusoid: "\ebfb";
$lnil-book-2: "\ebfc";
$lnil-molecule-alt: "\ebfd";
$lnil-flask: "\ebfe";
$lnil-whiteboard-alt-1: "\ebff";
$lnil-backpack-alt: "\ec00";
$lnil-world: "\ec01";
$lnil-school-bus: "\ec02";
$lnil-whiteboard-alt: "\ec03";
$lnil-letterblocks: "\ec04";
$lnil-classroom: "\ec05";
$lnil-pencil-alt: "\ec06";
$lnil-molecule: "\ec07";
$lnil-blackboard: "\ec08";
$lnil-write: "\ec09";
$lnil-classroom-alt: "\ec0a";
$lnil-world-alt: "\ec0b";
$lnil-geometry: "\ec0c";
$lnil-compass: "\ec0d";
$lnil-beta: "\ec0e";
$lnil-pencil: "\ec0f";
$lnil-graduation: "\ec10";
$lnil-school-bench-alt: "\ec11";
$lnil-medal: "\ec12";
$lnil-graduate: "\ec13";
$lnil-atom: "\ec14";
$lnil-investment: "\ec15";
$lnil-briefcase-alt: "\ec16";
$lnil-target: "\ec17";
$lnil-analytics-alt-1: "\ec18";
$lnil-lni-lni-scale-machine: "\ec19";
$lnil-flow-chart-alt: "\ec1a";
$lnil-digital-currency: "\ec1b";
$lnil-pie-chart-alt: "\ec1c";
$lnil-tag: "\ec1d";
$lnil-briefcase: "\ec1e";
$lnil-coin-dollar: "\ec1f";
$lnil-exchange-alt: "\ec20";
$lnil-euro-coin: "\ec21";
$lnil-quotation: "\ec22";
$lnil-handshake-alt-1: "\ec23";
$lnil-pie-chart-dollar: "\ec24";
$lnil-cart-empty: "\ec25";
$lnil-delivery: "\ec26";
$lnil-stats-down: "\ec27";
$lnil-apartment: "\ec28";
$lnil-cart-down: "\ec29";
$lnil-office-plan: "\ec2a";
$lnil-cart: "\ec2b";
$lnil-pin: "\ec2c";
$lnil-invest-monitor: "\ec2d";
$lnil-offer: "\ec2e";
$lnil-desk: "\ec2f";
$lnil-tablet-alt: "\ec30";
$lnil-seo-consulting: "\ec31";
$lnil-notepad: "\ec32";
$lnil-grow: "\ec33";
$lnil-global: "\ec34";
$lnil-pie-chart: "\ec35";
$lnil-apartment-alt: "\ec36";
$lnil-pin-alt: "\ec37";
$lnil-finance: "\ec38";
$lnil-paperclip: "\ec39";
$lnil-target-alt: "\ec3a";
$lnil-agenda: "\ec3b";
$lnil-table1: "\ec3c";
$lnil-megaphone-alt: "\ec3d";
$lnil-balance-1: "\ec3e";
$lnil-target-achievement: "\ec3f";
$lnil-cart-alt: "\ec40";
$lnil-swatches: "\ec41";
$lnil-target-remove: "\ec42";
$lnil-tie: "\ec43";
$lnil-pie-report: "\ec44";
$lnil-hierchy-alt: "\ec45";
$lnil-sales-report: "\ec46";
$lnil-megaphone-2: "\ec47";
$lnil-handshake: "\ec48";
$lnil-megaphone-1: "\ec49";
$lnil-license: "\ec4a";
$lnil-balance: "\ec4b";
$lnil-achievement: "\ec4c";
$lnil-stats-up: "\ec4d";
$lnil-handshake-alt: "\ec4e";
$lnil-cart-full: "\ec4f";
$lnil-hierchy: "\ec50";
$lnil-target-revenue: "\ec51";
$lnil-revenue: "\ec52";
$lnil-target-alt-1: "\ec53";
$lnil-hierchy-alt-1: "\ec54";
$lnil-chinese-yuan: "\ec55";
$lnil-customer: "\ec56";
$lnil-stamp: "\ec57";
$lnil-cool-1: "\ec58";
$lnil-nervous: "\ec59";
$lnil-cool-3: "\ec5a";
$lnil-cool-6: "\ec5b";
$lnil-muted-1: "\ec5c";
$lnil-sick-1: "\ec5d";
$lnil-cool-7: "\ec5e";
$lnil-cool-5: "\ec5f";
$lnil-yawn: "\ec60";
$lnil-muted-3: "\ec61";
$lnil-muted-2: "\ec62";
$lnil-tounge: "\ec63";
$lnil-cool-4: "\ec64";
$lnil-cool-14: "\ec65";
$lnil-sleep: "\ec66";
$lnil-piss: "\ec67";
$lnil-sad: "\ec68";
$lnil-cool-15: "\ec69";
$lnil-kiss-2: "\ec6a";
$lnil-cry: "\ec6b";
$lnil-cool-17: "\ec6c";
$lnil-cool-16: "\ec6d";
$lnil-cool-12: "\ec6e";
$lnil-cool-13: "\ec6f";
$lnil-cool-11: "\ec70";
$lnil-suspect: "\ec71";
$lnil-happy: "\ec72";
$lnil-cool-10: "\ec73";
$lnil-happy-3: "\ec74";
$lnil-super-cool: "\ec75";
$lnil-speechless: "\ec76";
$lnil-smile: "\ec77";
$lnil-cool-20: "\ec78";
$lnil-happy-2: "\ec79";
$lnil-cute: "\ec7a";
$lnil-crying-4: "\ec7b";
$lnil-wow: "\ec7c";
$lnil-happy-1: "\ec7d";
$lnil-love1: "\ec7e";
$lnil-mute1: "\ec7f";
$lnil-crying-1: "\ec80";
$lnil-sick: "\ec81";
$lnil-loved-surprise: "\ec82";
$lnil-happy-4: "\ec83";
$lnil-cool-18: "\ec84";
$lnil-crying-2: "\ec85";
$lnil-crying-3: "\ec86";
$lnil-cool-19: "\ec87";
$lnil-cool-9: "\ec88";
$lnil-sad-2: "\ec89";
$lnil-dead-3: "\ec8a";
$lnil-smile-1: "\ec8b";
$lnil-bored: "\ec8c";
$lnil-dead-2: "\ec8d";
$lnil-sad-3: "\ec8e";
$lnil-cool-8: "\ec8f";
$lnil-super-happy: "\ec90";
$lnil-sad-1: "\ec91";
$lnil-cool: "\ec92";
$lnil-dead-1: "\ec93";
$lnil-cool-kiss: "\ec94";
$lnil-dead: "\ec95";
$lnil-friendly: "\ec96";
$lnil-sad-4: "\ec97";
$lnil-surprised-1: "\ec98";
$lnil-dead-5: "\ec99";
$lnil-kiss1: "\ec9a";
$lnil-dead-4: "\ec9b";
$lnil-wink: "\ec9c";
$lnil-sad-5: "\ec9d";
$lnil-happy-smile: "\ec9e";
$lnil-angry: "\ec9f";
$lnil-bored-1: "\eca0";
$lnil-dead-6: "\eca1";
$lnil-meat: "\eca2";
$lnil-dinner: "\eca3";
$lnil-chef-hat-alt-1: "\eca4";
$lnil-cutlery: "\eca5";
$lnil-chicken-roast: "\eca6";
$lnil-pizza-alt: "\eca7";
$lnil-silverware: "\eca8";
$lnil-chef-hat-alt: "\eca9";
$lnil-chef-hat-alt-3: "\ecaa";
$lnil-chef-hat-alt-2: "\ecab";
$lnil-restaurant: "\ecac";
$lnil-ice-cream-alt-1: "\ecad";
$lnil-chocolate-bar: "\ecae";
$lnil-steak: "\ecaf";
$lnil-spatula: "\ecb0";
$lnil-coffee-cup: "\ecb1";
$lnil-restaurant-menu: "\ecb2";
$lnil-cocktail-alt: "\ecb3";
$lnil-chicken1: "\ecb4";
$lnil-coffee-machine: "\ecb5";
$lnil-chilli-sauce: "\ecb6";
$lnil-service: "\ecb7";
$lnil-burger-alt: "\ecb8";
$lnil-banana-alt: "\ecb9";
$lnil-bread: "\ecba";
$lnil-milk-bottole: "\ecbb";
$lnil-satay: "\ecbc";
$lnil-drumstick: "\ecbd";
$lnil-cocktail: "\ecbe";
$lnil-hotdog: "\ecbf";
$lnil-bowl: "\ecc0";
$lnil-ice-cream-alt: "\ecc1";
$lnil-cutlery-alt-1: "\ecc2";
$lnil-popcorn: "\ecc3";
$lnil-soup: "\ecc4";
$lnil-burger: "\ecc5";
$lnil-juice: "\ecc6";
$lnil-taco: "\ecc7";
$lnil-noodles: "\ecc8";
$lnil-lolypop: "\ecc9";
$lnil-wine-glass-1: "\ecca";
$lnil-cupcake1: "\eccb";
$lnil-restaurant-table: "\eccc";
$lnil-donut: "\eccd";
$lnil-cupcake-alt: "\ecce";
$lnil-apple-alt: "\eccf";
$lnil-wine-glass: "\ecd0";
$lnil-cutlery-alt: "\ecd1";
$lnil-plate1: "\ecd2";
$lnil-burger-alt-1: "\ecd3";
$lnil-toilet: "\ecd4";
$lnil-chef-hat: "\ecd5";
$lnil-pizza: "\ecd6";
$lnil-banana: "\ecd7";
$lnil-service-alt: "\ecd8";
$lnil-cocktail-alt-1: "\ecd9";
$lnil-burger-alt-2: "\ecda";
$lnil-bowl-alt-1: "\ecdb";
$lnil-french-fries: "\ecdc";
$lnil-chicken-leg: "\ecdd";
$lnil-brocolli: "\ecde";
$lnil-bowl-alt: "\ecdf";
$lnil-dinner-table: "\ece0";
$lnil-avocado: "\ece1";
$lnil-baguette: "\ece2";
$lnil-fresh-juice: "\ece3";
$lnil-coffee-cup-alt: "\ece4";
$lnil-ice-cream: "\ece5";
$lnil-pancake: "\ece6";
$lnil-orange: "\ece7";
$lnil-user: "\ece8";
$lnil-user-reload: "\ece9";
$lnil-user-identification: "\ecea";
$lnil-personal-data: "\eceb";
$lnil-user-money: "\ecec";
$lnil-user-globe: "\eced";
$lnil-user-key: "\ecee";
$lnil-add-group-alt: "\ecef";
$lnil-user-delete: "\ecf0";
$lnil-user-block: "\ecf1";
$lnil-user-timer: "\ecf2";
$lnil-user-lock: "\ecf3";
$lnil-user-remove: "\ecf4";
$lnil-team: "\ecf5";
$lnil-user-top-arrow: "\ecf6";
$lnil-user-star: "\ecf7";
$lnil-user-love: "\ecf8";
$lnil-add-group: "\ecf9";
$lnil-user-idea: "\ecfa";
$lnil-user-edit: "\ecfb";
$lnil-user-search: "\ecfc";
$lnil-remove-user: "\ecfd";
$lnil-user-information: "\ecfe";
$lnil-add-user-alt: "\ecff";
$lnil-personal-file: "\ed00";
$lnil-user-settings: "\ed01";
$lnil-user-download: "\ed02";
$lnil-add-user: "\ed03";
$lnil-user-question: "\ed04";
$lnil-remove-group: "\ed05";
$lnil-user-check: "\ed06";
$lnil-friends: "\ed07";
$lnil-user-shield: "\ed08";
$lnil-shoe: "\ed09";
$lnil-briefcase-3: "\ed0a";
$lnil-briefcase-2: "\ed0b";
$lnil-glasses-1: "\ed0c";
$lnil-mitten: "\ed0d";
$lnil-purse-1: "\ed0e";
$lnil-briefcase-1: "\ed0f";
$lnil-handbag: "\ed10";
$lnil-briefcase-5: "\ed11";
$lnil-shirt: "\ed12";
$lnil-skirt-1: "\ed13";
$lnil-briefcase1: "\ed14";
$lnil-bracelet: "\ed15";
$lnil-perfume: "\ed16";
$lnil-wristwatch1: "\ed17";
$lnil-shopping-bag: "\ed18";
$lnil-perfume-1: "\ed19";
$lnil-ring-1: "\ed1a";
$lnil-handbag-3: "\ed1b";
$lnil-giftbox: "\ed1c";
$lnil-nail-polish: "\ed1d";
$lnil-bag-1: "\ed1e";
$lnil-perfume-2: "\ed1f";
$lnil-hair-curler: "\ed20";
$lnil-hat: "\ed21";
$lnil-hair: "\ed22";
$lnil-handbag-4: "\ed23";
$lnil-handbag-5: "\ed24";
$lnil-handbag-7: "\ed25";
$lnil-handbag-6: "\ed26";
$lnil-hat-alt: "\ed27";
$lnil-winter-hat: "\ed28";
$lnil-nail-alt: "\ed29";
$lnil-shirt-3: "\ed2a";
$lnil-bag: "\ed2b";
$lnil-skirt: "\ed2c";
$lnil-glasses1: "\ed2d";
$lnil-shirt-2: "\ed2e";
$lnil-sock: "\ed2f";
$lnil-nail-polish-1: "\ed30";
$lnil-blade: "\ed31";
$lnil-high-heel: "\ed32";
$lnil-shirt-1: "\ed33";
$lnil-nail-polish-2: "\ed34";
$lnil-dress: "\ed35";
$lnil-cream: "\ed36";
$lnil-high-heel-2: "\ed37";
$lnil-cream-1: "\ed38";
$lnil-gel: "\ed39";
$lnil-shirt-4: "\ed3a";
$lnil-high-heel-3: "\ed3b";
$lnil-cream-2: "\ed3c";
$lnil-mackup-3: "\ed3d";
$lnil-mackup-2: "\ed3e";
$lnil-diadem: "\ed3f";
$lnil-necklace1: "\ed40";
$lnil-tie1: "\ed41";
$lnil-hat-2: "\ed42";
$lnil-mackup: "\ed43";
$lnil-hanger: "\ed44";
$lnil-comb-3: "\ed45";
$lnil-comb-2: "\ed46";
$lnil-gift2: "\ed47";
$lnil-hat-3: "\ed48";
$lnil-purse-alt: "\ed49";
$lnil-hat-1: "\ed4a";
$lnil-comb-1: "\ed4b";
$lnil-shorts: "\ed4c";
$lnil-hat-4: "\ed4d";
$lnil-dress-2: "\ed4e";
$lnil-bow-1: "\ed4f";
$lnil-hairdryer: "\ed50";
$lnil-dress-3: "\ed51";
$lnil-ring1: "\ed52";
$lnil-hat-5: "\ed53";
$lnil-bow-tie1: "\ed54";
$lnil-mirror: "\ed55";
$lnil-dress-1: "\ed56";
$lnil-nail: "\ed57";
$lnil-flip-flop: "\ed58";
$lnil-triangle: "\ed59";
$lnil-brackets-alt-1: "\ed5a";
$lnil-division: "\ed5b";
$lnil-pi: "\ed5c";
$lnil-plus-minus-symbol: "\ed5d";
$lnil-brackets-alt: "\ed5e";
$lnil-brackets1: "\ed5f";
$lnil-root-sign-alt: "\ed60";
$lnil-abstract: "\ed61";
$lnil-at: "\ed62";
$lnil-parentheses: "\ed63";
$lnil-plus-minus-symbol-1: "\ed64";
$lnil-root-sign: "\ed65";
$lnil-root-sign-alt-1: "\ed66";
$lnil-parentheses-alt-2: "\ed67";
$lnil-plus-minus-symbol-alt: "\ed68";
$lnil-pi-alt-1: "\ed69";
$lnil-parentheses-alt-1: "\ed6a";
$lnil-division-alt: "\ed6b";
$lnil-abstract-1: "\ed6c";
$lnil-division-alt-1: "\ed6d";
$lnil-asterisk: "\ed6e";
$lnil-pi-alt: "\ed6f";
$lnil-spinner-11: "\ed70";
$lnil-spinner-10: "\ed71";
$lnil-hourglass: "\ed72";
$lnil-spinner-9: "\ed73";
$lnil-spinner-8: "\ed74";
$lnil-spinner-3: "\ed75";
$lnil-spinner-2: "\ed76";
$lnil-spinner-arrow: "\ed77";
$lnil-spinner-1: "\ed78";
$lnil-spinner-5: "\ed79";
$lnil-spinner-4: "\ed7a";
$lnil-spinner-6: "\ed7b";
$lnil-spinner: "\ed7c";
$lnil-spinner-7: "\ed7d";
$lnil-spinner-solid: "\ed7e";
$lnil-desert-1: "\ed7f";
$lnil-forrest-alt-1: "\ed80";
$lnil-mushroom-alt-1: "\ed81";
$lnil-cactus-alt: "\ed82";
$lnil-desert-2: "\ed83";
$lnil-forrest-alt-2: "\ed84";
$lnil-mushroom-alt-2: "\ed85";
$lnil-forrest-alt-3: "\ed86";
$lnil-leaf-alt-2: "\ed87";
$lnil-trees-alt-9: "\ed88";
$lnil-cactus: "\ed89";
$lnil-mushroom-alt: "\ed8a";
$lnil-trees-alt-8: "\ed8b";
$lnil-mushrooms-alt: "\ed8c";
$lnil-leaf-alt-1: "\ed8d";
$lnil-tornado: "\ed8e";
$lnil-mashroom: "\ed8f";
$lnil-island-alt: "\ed90";
$lnil-forrest: "\ed91";
$lnil-island: "\ed92";
$lnil-cactus-3: "\ed93";
$lnil-island-alt-1: "\ed94";
$lnil-mushrooms: "\ed95";
$lnil-cactus-alt-2: "\ed96";
$lnil-mushrooms-alt-1: "\ed97";
$lnil-cactus-2: "\ed98";
$lnil-island-alt-2: "\ed99";
$lnil-mountains-4: "\ed9a";
$lnil-island-alt-3: "\ed9b";
$lnil-tree-alt: "\ed9c";
$lnil-cactus-1: "\ed9d";
$lnil-plant-pot-alt-1: "\ed9e";
$lnil-forrest-1: "\ed9f";
$lnil-plant: "\eda0";
$lnil-mountains-1: "\eda1";
$lnil-nature-shield: "\eda2";
$lnil-sprout-alt: "\eda3";
$lnil-trees-alt: "\eda4";
$lnil-island-alt-4: "\eda5";
$lnil-mountains-3: "\eda6";
$lnil-mountains-2: "\eda7";
$lnil-desert: "\eda8";
$lnil-hop: "\eda9";
$lnil-bug-alt: "\edaa";
$lnil-leaf-alt: "\edab";
$lnil-trees: "\edac";
$lnil-leaves: "\edad";
$lnil-trees-alt-10: "\edae";
$lnil-love-nature: "\edaf";
$lnil-leaf: "\edb0";
$lnil-bug: "\edb1";
$lnil-flower-alt: "\edb2";
$lnil-plant-pot-alt: "\edb3";
$lnil-trees-alt-6: "\edb4";
$lnil-tree-alt-3: "\edb5";
$lnil-flower2: "\edb6";
$lnil-sprout1: "\edb7";
$lnil-trees-alt-7: "\edb8";
$lnil-trees-alt-5: "\edb9";
$lnil-tree: "\edba";
$lnil-tree-alt-1: "\edbb";
$lnil-plant-alt: "\edbc";
$lnil-trees-alt-4: "\edbd";
$lnil-mountain: "\edbe";
$lnil-mountains: "\edbf";
$lnil-trees-alt-1: "\edc0";
$lnil-plant-pot: "\edc1";
$lnil-closet: "\edc2";
$lnil-lamp-1: "\edc3";
$lnil-bookshelf-1: "\edc4";
$lnil-bed: "\edc5";
$lnil-microwave: "\edc6";
$lnil-lamp-2: "\edc7";
$lnil-bookshelf-3: "\edc8";
$lnil-bookshelf-2: "\edc9";
$lnil-home2: "\edca";
$lnil-dressing-table: "\edcb";
$lnil-cradle: "\edcc";
$lnil-cupboard: "\edcd";
$lnil-couch: "\edce";
$lnil-bath-tub-1: "\edcf";
$lnil-wall-drawer: "\edd0";
$lnil-cabinet: "\edd1";
$lnil-air-conditioner: "\edd2";
$lnil-desk-1: "\edd3";
$lnil-bed-4: "\edd4";
$lnil-window-1: "\edd5";
$lnil-light-umbrella: "\edd6";
$lnil-elegant-table-1: "\edd7";
$lnil-bed-5: "\edd8";
$lnil-table-fan: "\edd9";
$lnil-bed-7: "\edda";
$lnil-elegant-table-3: "\eddb";
$lnil-window-2: "\eddc";
$lnil-window-3: "\eddd";
$lnil-elegant-table-2: "\edde";
$lnil-desk1: "\eddf";
$lnil-bed-6: "\ede0";
$lnil-bed-2: "\ede1";
$lnil-sofa-9: "\ede2";
$lnil-sofa-8: "\ede3";
$lnil-mirror-1: "\ede4";
$lnil-bed-3: "\ede5";
$lnil-bed-1: "\ede6";
$lnil-chest-of-drawers: "\ede7";
$lnil-elegant-table-4: "\ede8";
$lnil-hanger-1: "\ede9";
$lnil-office-cabinet: "\edea";
$lnil-closet-4: "\edeb";
$lnil-bath-tub: "\edec";
$lnil-chest-of-drawers-1: "\eded";
$lnil-bookshelf: "\edee";
$lnil-sofa-6: "\edef";
$lnil-sofa-7: "\edf0";
$lnil-lamp: "\edf1";
$lnil-closet-5: "\edf2";
$lnil-oven: "\edf3";
$lnil-table-5: "\edf4";
$lnil-light-stand: "\edf5";
$lnil-table-lamp: "\edf6";
$lnil-chest-of-drawers-2: "\edf7";
$lnil-sofa-5: "\edf8";
$lnil-sofa-4: "\edf9";
$lnil-picture: "\edfa";
$lnil-chest-of-drawers-3: "\edfb";
$lnil-office-desk: "\edfc";
$lnil-table-4: "\edfd";
$lnil-closet-2: "\edfe";
$lnil-chest-of-drawers-7: "\edff";
$lnil-weight-machine: "\ee00";
$lnil-wardrobe: "\ee01";
$lnil-sofa-1: "\ee02";
$lnil-chest-of-drawers-6: "\ee03";
$lnil-stairs: "\ee04";
$lnil-table-1: "\ee05";
$lnil-closet-3: "\ee06";
$lnil-closet-1: "\ee07";
$lnil-table-3: "\ee08";
$lnil-chest-of-drawers-4: "\ee09";
$lnil-sofa-3: "\ee0a";
$lnil-sofa-2: "\ee0b";
$lnil-juicer: "\ee0c";
$lnil-chest-of-drawers-5: "\ee0d";
$lnil-desk-chair: "\ee0e";
$lnil-iron-table: "\ee0f";
$lnil-table-2: "\ee10";
$lnil-home-1: "\ee11";
$lnil-table-lamp-1: "\ee12";
$lnil-hanger1: "\ee13";
$lnil-chair-3: "\ee14";
$lnil-light-bulb: "\ee15";
$lnil-table-lamp-2: "\ee16";
$lnil-stove: "\ee17";
$lnil-refrigerator: "\ee18";
$lnil-chair-1: "\ee19";
$lnil-window: "\ee1a";
$lnil-washing-machine: "\ee1b";
$lnil-chair: "\ee1c";
$lnil-cabinet-1: "\ee1d";
$lnil-chair-4: "\ee1e";
$lnil-clean: "\ee1f";
$lnil-fridge: "\ee20";
$lnil-mirror1: "\ee21";
$lnil-locker: "\ee22";
$lnil-prayer-rug: "\ee23";
$lnil-star-of-david-1: "\ee24";
$lnil-goblet: "\ee25";
$lnil-christian-candle: "\ee26";
$lnil-moshjid-location: "\ee27";
$lnil-church: "\ee28";
$lnil-islamic-book-alt: "\ee29";
$lnil-star-of-david: "\ee2a";
$lnil-muslim-symbol: "\ee2b";
$lnil-zen: "\ee2c";
$lnil-islamic-flag: "\ee2d";
$lnil-islamic-book: "\ee2e";
$lnil-bible: "\ee2f";
$lnil-bible-alt: "\ee30";
$lnil-tombstone-1: "\ee31";
$lnil-tombstone: "\ee32";
$lnil-torii-gate: "\ee33";
$lnil-cross: "\ee34";
$lnil-islam: "\ee35";
$lnil-yin-yang: "\ee36";
$lnil-ladder: "\ee37";
$lnil-derrick-alt-2: "\ee38";
$lnil-house: "\ee39";
$lnil-fuel-truck: "\ee3a";
$lnil-derrick-alt-3: "\ee3b";
$lnil-tools: "\ee3c";
$lnil-building-alt: "\ee3d";
$lnil-derrick-alt-1: "\ee3e";
$lnil-toolbox: "\ee3f";
$lnil-measuring-tape: "\ee40";
$lnil-cutter: "\ee41";
$lnil-door-alt: "\ee42";
$lnil-truck-alt: "\ee43";
$lnil-cone: "\ee44";
$lnil-drilling: "\ee45";
$lnil-hammer-alt: "\ee46";
$lnil-backhoe: "\ee47";
$lnil-trowel: "\ee48";
$lnil-boundary: "\ee49";
$lnil-derrick: "\ee4a";
$lnil-screw: "\ee4b";
$lnil-hook: "\ee4c";
$lnil-cone-alt: "\ee4d";
$lnil-paint-roller: "\ee4e";
$lnil-paint-bucket-alt: "\ee4f";
$lnil-house-plan: "\ee50";
$lnil-triangle-ruler: "\ee51";
$lnil-bricks: "\ee52";
$lnil-house-heart: "\ee53";
$lnil-magnet: "\ee54";
$lnil-demolition: "\ee55";
$lnil-derrick-alt: "\ee56";
$lnil-construction-hammer: "\ee57";
$lnil-ruler-alt-1: "\ee58";
$lnil-trowel-alt: "\ee59";
$lnil-paint-roller-alt: "\ee5a";
$lnil-ruler: "\ee5b";
$lnil-fence: "\ee5c";
$lnil-saw: "\ee5d";
$lnil-garbage-truck: "\ee5e";
$lnil-truck-alt-1: "\ee5f";
$lnil-house-plan-alt: "\ee60";
$lnil-doors: "\ee61";
$lnil-construction-rular: "\ee62";
$lnil-triangle-ruler-1: "\ee63";
$lnil-trowel-alt-1: "\ee64";
$lnil-ruler-pencil: "\ee65";
$lnil-bulldozer: "\ee66";
$lnil-truck-load: "\ee67";
$lnil-broom: "\ee68";
$lnil-screw-alt: "\ee69";
$lnil-house-plan-alt-4: "\ee6a";
$lnil-cement-truck: "\ee6b";
$lnil-flags1: "\ee6c";
$lnil-stairs1: "\ee6d";
$lnil-paint-roller-alt-1: "\ee6e";
$lnil-construction: "\ee6f";
$lnil-house-plan-alt-1: "\ee70";
$lnil-stopcock: "\ee71";
$lnil-derricks: "\ee72";
$lnil-wrench: "\ee73";
$lnil-repair-tools: "\ee74";
$lnil-hammer: "\ee75";
$lnil-crane: "\ee76";
$lnil-pipe: "\ee77";
$lnil-helmet-alt: "\ee78";
$lnil-shovel: "\ee79";
$lnil-compass1: "\ee7a";
$lnil-paint-roller-alt-2: "\ee7b";
$lnil-house-plan-alt-2: "\ee7c";
$lnil-helmet: "\ee7d";
$lnil-building-alt-1: "\ee7e";
$lnil-brick-wall-alt: "\ee7f";
$lnil-ruler-alt: "\ee80";
$lnil-trolley-cart: "\ee81";
$lnil-brick-wall: "\ee82";
$lnil-axe: "\ee83";
$lnil-pulley: "\ee84";
$lnil-door: "\ee85";
$lnil-bricks-alt: "\ee86";
$lnil-drill: "\ee87";
$lnil-house-alt-2: "\ee88";
$lnil-wheelbarrow-alt: "\ee89";
$lnil-screwdriver: "\ee8a";
$lnil-house-alt-1: "\ee8b";
$lnil-barrier-alt: "\ee8c";
$lnil-paint-bucket: "\ee8d";
$lnil-saw-alt: "\ee8e";
$lnil-wheelbarrow: "\ee8f";
$lnil-butterfly: "\ee90";
$lnil-gorilla: "\ee91";
$lnil-shark: "\ee92";
$lnil-cow1: "\ee93";
$lnil-chicken2: "\ee94";
$lnil-camel: "\ee95";
$lnil-dolphin: "\ee96";
$lnil-rabbit: "\ee97";
$lnil-whale: "\ee98";
$lnil-crocodile: "\ee99";
$lnil-walrus: "\ee9a";
$lnil-koala: "\ee9b";
$lnil-seal: "\ee9c";
$lnil-bat: "\ee9d";
$lnil-jellyfish: "\ee9e";
$lnil-frog: "\ee9f";
$lnil-cat: "\eea0";
$lnil-badge-1: "\eea1";
$lnil-prize-1: "\eea2";
$lnil-badge-2: "\eea3";
$lnil-badge-3: "\eea4";
$lnil-badge-7: "\eea5";
$lnil-badge-6: "\eea6";
$lnil-diploma-2: "\eea7";
$lnil-badge-4: "\eea8";
$lnil-badge-5: "\eea9";
$lnil-diploma-1: "\eeaa";
$lnil-medal-8: "\eeab";
$lnil-trophy-5: "\eeac";
$lnil-diploma: "\eead";
$lnil-trophy-4: "\eeae";
$lnil-trophy-1: "\eeaf";
$lnil-trophy-3: "\eeb0";
$lnil-trophy-2: "\eeb1";
$lnil-nd-prize: "\eeb2";
$lnil-medal-1: "\eeb3";
$lnil-badge1: "\eeb4";
$lnil-medal-2: "\eeb5";
$lnil-crown1: "\eeb6";
$lnil-medal-3: "\eeb7";
$lnil-medal-7: "\eeb8";
$lnil-medal-6: "\eeb9";
$lnil-medal-4: "\eeba";
$lnil-badge-10: "\eebb";
$lnil-medal-5: "\eebc";
$lnil-badge-8: "\eebd";
$lnil-badge-9: "\eebe";
$lnil-patent: "\eebf";
$lnil-st-prize: "\eec0";
$lnil-rook: "\eec1";
$lnil-seo: "\eec2";
$lnil-bar-check: "\eec3";
$lnil-pie-chart-alt1: "\eec4";
$lnil-target-audience-alt: "\eec5";
$lnil-strategy: "\eec6";
$lnil-bar-chart-1: "\eec7";
$lnil-bar-chart: "\eec8";
$lnil-graph-alt: "\eec9";
$lnil-target-customer: "\eeca";
$lnil-graph-alt-4: "\eecb";
$lnil-keyword-research: "\eecc";
$lnil-website-seo: "\eecd";
$lnil-graph-alt-3: "\eece";
$lnil-billboard: "\eecf";
$lnil-network: "\eed0";
$lnil-website-rank: "\eed1";
$lnil-analytics-chat: "\eed2";
$lnil-graph-alt-2: "\eed3";
$lnil-pyramid: "\eed4";
$lnil-network-alt: "\eed5";
$lnil-billboard-alt: "\eed6";
$lnil-graph-alt-1: "\eed7";
$lnil-analytics: "\eed8";
$lnil-graph-increase: "\eed9";
$lnil-pyramid-decrease: "\eeda";
$lnil-hand-chart: "\eedb";
$lnil-graph: "\eedc";
$lnil-pyramid-increase: "\eedd";
$lnil-seo-alt: "\eede";
$lnil-graph-decrease: "\eedf";
$lnil-search: "\eee0";
$lnil-alarm-2: "\eee1";
$lnil-trash-can-alt: "\eee2";
$lnil-full-battery: "\eee3";
$lnil-vr-alt: "\eee4";
$lnil-bulb: "\eee5";
$lnil-display-alt: "\eee6";
$lnil-alarm-3: "\eee7";
$lnil-trash-can: "\eee8";
$lnil-cog: "\eee9";
$lnil-flash-drive-alt: "\eeea";
$lnil-laptop-thumbs-down: "\eeeb";
$lnil-cloud-sync-alt: "\eeec";
$lnil-file-name: "\eeed";
$lnil-cloud-server-1: "\eeee";
$lnil-ai-alt: "\eeef";
$lnil-cloud-download-1: "\eef0";
$lnil-user1: "\eef1";
$lnil-tab: "\eef2";
$lnil-camera-alt-1: "\eef3";
$lnil-cloud-computing: "\eef4";
$lnil-recycle: "\eef5";
$lnil-cloud-network-2: "\eef6";
$lnil-bulb-alt: "\eef7";
$lnil-alarm-4: "\eef8";
$lnil-cloud-check: "\eef9";
$lnil-database-11: "\eefa";
$lnil-database-10: "\eefb";
$lnil-reload-browser: "\eefc";
$lnil-code-alt: "\eefd";
$lnil-wifi-alt-1: "\eefe";
$lnil-tap: "\eeff";
$lnil-laptop-alt-switch: "\ef00";
$lnil-programmer: "\ef01";
$lnil-presentation: "\ef02";
$lnil-vector-node-1: "\ef03";
$lnil-folder-alt-1: "\ef04";
$lnil-map: "\ef05";
$lnil-microphone1: "\ef06";
$lnil-laptop-alt: "\ef07";
$lnil-database: "\ef08";
$lnil-change: "\ef09";
$lnil-cellphone-vr: "\ef0a";
$lnil-cloud-upload-alt: "\ef0b";
$lnil-signal: "\ef0c";
$lnil-virtual-reality-alt: "\ef0d";
$lnil-degree: "\ef0e";
$lnil-files: "\ef0f";
$lnil-wifi-alt: "\ef10";
$lnil-wifi-alt-2: "\ef11";
$lnil-internet-alt: "\ef12";
$lnil-vr-monitor: "\ef13";
$lnil-website-browser: "\ef14";
$lnil-keyboard: "\ef15";
$lnil-empty-battery: "\ef16";
$lnil-vr-monitor-1: "\ef17";
$lnil-map-marker: "\ef18";
$lnil-zip: "\ef19";
$lnil-bookmark-alt: "\ef1a";
$lnil-cloud-upload: "\ef1b";
$lnil-cloud-1: "\ef1c";
$lnil-ban: "\ef1d";
$lnil-live-stream: "\ef1e";
$lnil-menu-alt: "\ef1f";
$lnil-networking: "\ef20";
$lnil-appointment: "\ef21";
$lnil-website-alt: "\ef22";
$lnil-g-sim: "\ef23";
$lnil-star-fill: "\ef24";
$lnil-package: "\ef25";
$lnil-vr-glasses: "\ef26";
$lnil-link: "\ef27";
$lnil-database-4: "\ef28";
$lnil-mobile-alt-button: "\ef29";
$lnil-save-alt: "\ef2a";
$lnil-database-down: "\ef2b";
$lnil-vr-monitor-2: "\ef2c";
$lnil-network-cloud: "\ef2d";
$lnil-website: "\ef2e";
$lnil-5g-alt-11: "\ef2f";
$lnil-vector-node: "\ef30";
$lnil-vr-laptop: "\ef31";
$lnil-vr-motion: "\ef32";
$lnil-database-6: "\ef33";
$lnil-shortcode: "\ef34";
$lnil-menu-alt-4: "\ef35";
$lnil-game: "\ef36";
$lnil-archive: "\ef37";
$lnil-mobile-application-alt: "\ef38";
$lnil-magnet1: "\ef39";
$lnil-recycle-alt: "\ef3a";
$lnil-headphone-alt: "\ef3b";
$lnil-download: "\ef3c";
$lnil-rotation: "\ef3d";
$lnil-menu-alt-5: "\ef3e";
$lnil-cloud-3: "\ef3f";
$lnil-database-7: "\ef40";
$lnil-link-alt-1: "\ef41";
$lnil-calender-alt: "\ef42";
$lnil-server: "\ef43";
$lnil-tv: "\ef44";
$lnil-scroll-down: "\ef45";
$lnil-folder-networking-1: "\ef46";
$lnil-server-8: "\ef47";
$lnil-database-3: "\ef48";
$lnil-bot: "\ef49";
$lnil-plug: "\ef4a";
$lnil-menu-alt-1: "\ef4b";
$lnil-cloud-network: "\ef4c";
$lnil-dashboard: "\ef4d";
$lnil-code: "\ef4e";
$lnil-reload-alt-box: "\ef4f";
$lnil-database-2: "\ef50";
$lnil-battery: "\ef51";
$lnil-files-alt: "\ef52";
$lnil-server-9: "\ef53";
$lnil-mobile-alt-rounded-bottom: "\ef54";
$lnil-star-half: "\ef55";
$lnil-vr-bug: "\ef56";
$lnil-folder-alt: "\ef57";
$lnil-cpu: "\ef58";
$lnil-harddrive: "\ef59";
$lnil-laptop-alt-keyboard: "\ef5a";
$lnil-folder-networking-2: "\ef5b";
$lnil-server-19: "\ef5c";
$lnil-share-alt: "\ef5d";
$lnil-block-user: "\ef5e";
$lnil-menu-alt-2: "\ef5f";
$lnil-bluetooth: "\ef60";
$lnil-file-protection: "\ef61";
$lnil-live-stream-alt: "\ef62";
$lnil-cloud-check-alt: "\ef63";
$lnil-wifi: "\ef64";
$lnil-menu-alt-3: "\ef65";
$lnil-database-1: "\ef66";
$lnil-server-18: "\ef67";
$lnil-domain: "\ef68";
$lnil-cellphone-vr-1: "\ef69";
$lnil-vr-browser: "\ef6a";
$lnil-search-alt: "\ef6b";
$lnil-server-7: "\ef6c";
$lnil-mic: "\ef6d";
$lnil-server-15: "\ef6e";
$lnil-cloud-sync: "\ef6f";
$lnil-copy: "\ef70";
$lnil-zoom-in: "\ef71";
$lnil-empty-file: "\ef72";
$lnil-reload: "\ef73";
$lnil-media-server: "\ef74";
$lnil-billboard-alt-1: "\ef75";
$lnil-remove-file: "\ef76";
$lnil-coding: "\ef77";
$lnil-low-battery: "\ef78";
$lnil-file-download: "\ef79";
$lnil-alart-database: "\ef7a";
$lnil-image: "\ef7b";
$lnil-cloud-search-2: "\ef7c";
$lnil-mobile-alt-rounded: "\ef7d";
$lnil-save: "\ef7e";
$lnil-server-14: "\ef7f";
$lnil-scan: "\ef80";
$lnil-server-6: "\ef81";
$lnil-g: "\ef82";
$lnil-coding-alt-1: "\ef83";
$lnil-server-4: "\ef84";
$lnil-zoom-out: "\ef85";
$lnil-g-alt: "\ef86";
$lnil-add-server: "\ef87";
$lnil-cogs: "\ef88";
$lnil-software-deployment: "\ef89";
$lnil-bluetooth-alt: "\ef8a";
$lnil-cloud-search-1: "\ef8b";
$lnil-cloud-computing-1: "\ef8c";
$lnil-server-5: "\ef8d";
$lnil-camera: "\ef8e";
$lnil-printer: "\ef8f";
$lnil-ai: "\ef90";
$lnil-sim-card: "\ef91";
$lnil-rss-feed: "\ef92";
$lnil-server-1: "\ef93";
$lnil-bot-1: "\ef94";
$lnil-camera-alt: "\ef95";
$lnil-cloud-search: "\ef96";
$lnil-laptop-thumbs-up: "\ef97";
$lnil-no-charge-battery: "\ef98";
$lnil-billboard1: "\ef99";
$lnil-alarmclock: "\ef9a";
$lnil-network1: "\ef9b";
$lnil-ci-cd-alt: "\ef9c";
$lnil-vr: "\ef9d";
$lnil-tv-1: "\ef9e";
$lnil-star: "\ef9f";
$lnil-code-browser: "\efa0";
$lnil-unlink: "\efa1";
$lnil-server-2: "\efa2";
$lnil-devops: "\efa3";
$lnil-database-9: "\efa4";
$lnil-coding-alt: "\efa5";
$lnil-billboard-alt1: "\efa6";
$lnil-wire: "\efa7";
$lnil-automation: "\efa8";
$lnil-database-8: "\efa9";
$lnil-half-battery: "\efaa";
$lnil-webcam-alt: "\efab";
$lnil-vr-alt-1: "\efac";
$lnil-server-3: "\efad";
$lnil-network-1: "\efae";
$lnil-vr-tech: "\efaf";
$lnil-infrared: "\efb0";
$lnil-link-alt: "\efb1";
$lnil-mobile: "\efb2";
$lnil-trash-can-alt-2: "\efb3";
$lnil-headphone: "\efb4";
$lnil-incognito-browser: "\efb5";
$lnil-user-alt-1: "\efb6";
$lnil-laptop-phone: "\efb7";
$lnil-processor-alt: "\efb8";
$lnil-share: "\efb9";
$lnil-check-server: "\efba";
$lnil-thumbnail: "\efbb";
$lnil-d: "\efbc";
$lnil-microphone-alt: "\efbd";
$lnil-cms: "\efbe";
$lnil-magnifier: "\efbf";
$lnil-charging: "\efc0";
$lnil-users-2: "\efc1";
$lnil-calendar: "\efc2";
$lnil-trash-can-alt-1: "\efc3";
$lnil-laptop-star: "\efc4";
$lnil-user-alt-2: "\efc5";
$lnil-laptop: "\efc6";
$lnil-display: "\efc7";
$lnil-calender-alt-4: "\efc8";
$lnil-vr-chat: "\efc9";
$lnil-check-database: "\efca";
$lnil-cut: "\efcb";
$lnil-user-alt: "\efcc";
$lnil-add-alt: "\efcd";
$lnil-reload-alt: "\efce";
$lnil-upload: "\efcf";
$lnil-mail-server: "\efd0";
$lnil-mobile-application: "\efd1";
$lnil-pendrive: "\efd2";
$lnil-down-server: "\efd3";
$lnil-bookmark: "\efd4";
$lnil-file-upload: "\efd5";
$lnil-rocket: "\efd6";
$lnil-up-server: "\efd7";
$lnil-microphone-alt-1: "\efd8";
$lnil-star-empty: "\efd9";
$lnil-IOT: "\efda";
$lnil-cloud-download: "\efdb";
$lnil-add-files: "\efdc";
$lnil-vector-node-alt: "\efdd";
$lnil-calender-alt-1: "\efde";
$lnil-menu: "\efdf";
$lnil-brightness: "\efe0";
$lnil-menu-circle: "\efe1";
$lnil-backspace: "\efe2";
$lnil-mouse: "\efe3";
$lnil-control-panel: "\efe4";
$lnil-alert-server: "\efe5";
$lnil-laptop-star-1: "\efe6";
$lnil-shopping-basket: "\efe7";
$lnil-ps: "\efe8";
$lnil-alarm-clock-alt1: "\efe9";
$lnil-calender-alt-3: "\efea";
$lnil-folder: "\efeb";
$lnil-alarm: "\efec";
$lnil-file-lock: "\efed";
$lnil-users: "\efee";
$lnil-ci-cd: "\efef";
$lnil-timer1: "\eff0";
$lnil-walkie-talkie: "\eff1";
$lnil-cloud-server: "\eff2";
$lnil-calender-alt-2: "\eff3";
$lnil-vr-design: "\eff4";
$lnil-sleeveless: "\eff5";
$lnil-lifting: "\eff6";
$lnil-gym-bag: "\eff7";
$lnil-measure-tape: "\eff8";
$lnil-jump-rope: "\eff9";
$lnil-gym: "\effa";
$lnil-yoga-mat: "\effb";
$lnil-warming-up: "\effc";
$lnil-dumbell-alt: "\effd";
$lnil-shirt1: "\effe";
$lnil-shower: "\efff";
$lnil-towel: "\f000";
$lnil-medicine-ball: "\f001";
$lnil-bench: "\f002";
$lnil-supplement: "\f003";
$lnil-whistle: "\f004";
$lnil-stopwatch: "\f005";
$lnil-no-smoking: "\f006";
$lnil-muscles: "\f007";
$lnil-punching-bag: "\f008";
$lnil-beverage: "\f009";
$lnil-chest-press: "\f00a";
$lnil-scale: "\f00b";
$lnil-stationary-bike: "\f00c";
$lnil-trampoline: "\f00d";
$lnil-tshirt: "\f00e";
$lnil-contest: "\f00f";
$lnil-uniform: "\f010";
$lnil-smartwatch: "\f011";
$lnil-pull-up: "\f012";
$lnil-kettlebell: "\f013";
$lnil-water-bottle: "\f014";
$lnil-treadmill: "\f015";
$lnil-underwear: "\f016";
$lnil-boxing-glove: "\f017";
$lnil-resistance-band: "\f018";
$lnil-tank-top: "\f019";
$lnil-schedule: "\f01a";
$lnil-yoga-ball: "\f01b";
$lnil-rings: "\f01c";
$lnil-medal1: "\f01d";
$lnil-dumbell: "\f01e";
$lnil-trainers: "\f01f";
$lnil-hand-grip: "\f020";
$lnil-apple1: "\f021";
$lnil-monitor: "\f022";
$lnil-swimming-pool: "\f023";
$lnil-sunrise-alt-1: "\f024";
$lnil-crescent: "\f025";
$lnil-cloud-alt-1: "\f026";
$lnil-celsius-alt: "\f027";
$lnil-humidity-alt: "\f028";
$lnil-sunrise-alt-2: "\f029";
$lnil-umbrella: "\f02a";
$lnil-cloud-alt-2: "\f02b";
$lnil-umbrella-rain: "\f02c";
$lnil-night-wind: "\f02d";
$lnil-lnio-lni-cloudy: "\f02e";
$lnil-sunrise-alt: "\f02f";
$lnil-wind-sun: "\f030";
$lnil-clouds: "\f031";
$lnil-night-thunder: "\f032";
$lnil-tornado1: "\f033";
$lnil-cloud-alt: "\f034";
$lnil-sunset-alt: "\f035";
$lnil-thunder: "\f036";
$lnil-cloud-sun: "\f037";
$lnil-umbrella-alt: "\f038";
$lnil-sun-rain: "\f039";
$lnil-air: "\f03a";
$lnil-kelvin-alt: "\f03b";
$lnil-snowfall: "\f03c";
$lnil-farenheit: "\f03d";
$lnil-new-moon: "\f03e";
$lnil-wind-rose: "\f03f";
$lnil-rainbow: "\f040";
$lnil-rain: "\f041";
$lnil-tide-alt: "\f042";
$lnil-night: "\f043";
$lnil-wind-cloud: "\f044";
$lnil-mist: "\f045";
$lnil-kelvin: "\f046";
$lnil-cloudy-sun: "\f047";
$lnil-sun: "\f048";
$lnil-moon-alt: "\f049";
$lnil-drop-alt: "\f04a";
$lnil-cloud-moon: "\f04b";
$lnil-air-flow: "\f04c";
$lnil-moon-star: "\f04d";
$lnil-thunder-alt: "\f04e";
$lnil-quarter-moon-1: "\f04f";
$lnil-water-drops: "\f050";
$lnil-cloud: "\f051";
$lnil-icicles: "\f052";
$lnil-umbrella-rain-alt: "\f053";
$lnil-tide: "\f054";
$lnil-drop: "\f055";
$lnil-moon: "\f056";
$lnil-quarter-moon: "\f057";
$lnil-farenheit-alt: "\f058";
$lnil-angle-double-left: "\f059";
$lnil-move-down: "\f05a";
$lnil-arrow-up-circle: "\f05b";
$lnil-arrow-down: "\f05c";
$lnil-corner-right-up: "\f05d";
$lnil-arrow-double-right: "\f05e";
$lnil-corner-up-right: "\f05f";
$lnil-push-right: "\f060";
$lnil-arrows-exchange-alt-v-1: "\f061";
$lnil-arrow-long-right-c: "\f062";
$lnil-corner-double-up-right: "\f063";
$lnil-pointer1: "\f064";
$lnil-arrow-top-right: "\f065";
$lnil-corner-double-right-up: "\f066";
$lnil-push-left: "\f067";
$lnil-scroll-h: "\f068";
$lnil-corner-left-up: "\f069";
$lnil-arrows-break-h: "\f06a";
$lnil-arrow-align-v: "\f06b";
$lnil-arrows-scroll-v: "\f06c";
$lnil-chevron-down: "\f06d";
$lnil-arrows-expand-left-alt: "\f06e";
$lnil-arrow-double-left: "\f06f";
$lnil-exit-up: "\f070";
$lnil-move-task: "\f071";
$lnil-pointer-right: "\f072";
$lnil-arrow-double-top: "\f073";
$lnil-arrows-shrink-h: "\f074";
$lnil-arrow-long-down-l: "\f075";
$lnil-chevron-up: "\f076";
$lnil-arrows-horizontal: "\f077";
$lnil-chevron-left-circle: "\f078";
$lnil-arrow-long-left-i: "\f079";
$lnil-north: "\f07a";
$lnil-chevron-right: "\f07b";
$lnil-swap-vertical: "\f07c";
$lnil-corner-down-right: "\f07d";
$lnil-arrow-right: "\f07e";
$lnil-arrow-left-circle: "\f07f";
$lnil-arrows-exchange-alt: "\f080";
$lnil-select-r: "\f081";
$lnil-shift-right: "\f082";
$lnil-compress: "\f083";
$lnil-signaling: "\f084";
$lnil-corner-right-down: "\f085";
$lnil-arrows-expand-up-right: "\f086";
$lnil-arrows-expand-down-left: "\f087";
$lnil-arrow-top-left: "\f088";
$lnil-arrow-minimize-alt: "\f089";
$lnil-angle-double-right: "\f08a";
$lnil-top-arrow-box: "\f08b";
$lnil-right-alt: "\f08c";
$lnil-train: "\f08d";
$lnil-top-left-arrow-box: "\f08e";
$lnil-direction-alt: "\f08f";
$lnil-corner-double-down-right: "\f090";
$lnil-flag1: "\f091";
$lnil-right-arrow-box: "\f092";
$lnil-direction-rtl: "\f093";
$lnil-merge-vertical: "\f094";
$lnil-arrows-merge-alt-h: "\f095";
$lnil-direction: "\f096";
$lnil-pointer-top: "\f097";
$lnil-corner-up-left: "\f098";
$lnil-pointer-down: "\f099";
$lnil-corner-double-right-down: "\f09a";
$lnil-corner-double-down-left: "\f09b";
$lnil-axis: "\f09c";
$lnil-select-o: "\f09d";
$lnil-arrow-right-circle: "\f09e";
$lnil-push-up: "\f09f";
$lnil-navigation: "\f0a0";
$lnil-right: "\f0a1";
$lnil-arrows-exchange: "\f0a2";
$lnil-arrows-expand-up-left: "\f0a3";
$lnil-chevron-up-circle: "\f0a4";
$lnil-arrow-long-left-c: "\f0a5";
$lnil-route-alt: "\f0a6";
$lnil-corner-double-left-up: "\f0a7";
$lnil-arrows-exchange-alt-v: "\f0a8";
$lnil-down-left-arrow-box: "\f0a9";
$lnil-arrows-expand-right-alt: "\f0aa";
$lnil-flags2: "\f0ab";
$lnil-pointer-left: "\f0ac";
$lnil-exit-down: "\f0ad";
$lnil-corner-left-down: "\f0ae";
$lnil-chevron-right-circle: "\f0af";
$lnil-arrows-merge-alt-v: "\f0b0";
$lnil-shift-left: "\f0b1";
$lnil-arrows-expand-down-right: "\f0b2";
$lnil-arrow-long-left-r: "\f0b3";
$lnil-arrow-long-left-e: "\f0b4";
$lnil-turn: "\f0b5";
$lnil-compass2: "\f0b6";
$lnil-traffic-light: "\f0b7";
$lnil-select1: "\f0b8";
$lnil-direction-ltr: "\f0b9";
$lnil-exit: "\f0ba";
$lnil-push-down: "\f0bb";
$lnil-straight: "\f0bc";
$lnil-angle-double-up: "\f0bd";
$lnil-arrows-vertical: "\f0be";
$lnil-arrow-up: "\f0bf";
$lnil-arrow-expand: "\f0c0";
$lnil-down-right-arrow-box: "\f0c1";
$lnil-left-arrow-box: "\f0c2";
$lnil-arrow-left: "\f0c3";
$lnil-route: "\f0c4";
$lnil-magnifying-glass: "\f0c5";
$lnil-motion: "\f0c6";
$lnil-arrow-long-down-c: "\f0c7";
$lnil-corner-double-left-down: "\f0c8";
$lnil-move-left: "\f0c9";
$lnil-scroll-v: "\f0ca";
$lnil-angle-double-down: "\f0cb";
$lnil-arrows-break-v: "\f0cc";
$lnil-trending-down: "\f0cd";
$lnil-arrows-scroll-h: "\f0ce";
$lnil-arrow-align-h: "\f0cf";
$lnil-arrow-down-circle: "\f0d0";
$lnil-move-right: "\f0d1";
$lnil-enter: "\f0d2";
$lnil-chevron-down-circle: "\f0d3";
$lnil-placeholders: "\f0d4";
$lnil-arrow-expand-alt: "\f0d5";
$lnil-down-arrow-box: "\f0d6";
$lnil-top-right-arrow-box: "\f0d7";
$lnil-merge-horizontal: "\f0d8";
$lnil-arrow-double-down: "\f0d9";
$lnil-corner-down-left: "\f0da";
$lnil-arrows-shrink-v: "\f0db";
$lnil-walking: "\f0dc";
$lnil-chevron-left: "\f0dd";
$lnil-move-up: "\f0de";
$lnil-swap: "\f0df";
$lnil-arrow-long-down-e: "\f0e0";
$lnil-arrow-long-down-r: "\f0e1";
$lnil-corner-double-up-left: "\f0e2";
$lnil-trending-up: "\f0e3";
$lnil-arrow-minimize: "\f0e4";
$lnil-speech-bubble-34: "\f0e5";
$lnil-speech-bubble-20: "\f0e6";
$lnil-speech-bubble-8: "\f0e7";
$lnil-megaphone: "\f0e8";
$lnil-target-mail: "\f0e9";
$lnil-speech-bubble-9: "\f0ea";
$lnil-phone-caling: "\f0eb";
$lnil-mobile-alt: "\f0ec";
$lnil-speech-bubble-21: "\f0ed";
$lnil-speech-bubble-35: "\f0ee";
$lnil-postcard-alt: "\f0ef";
$lnil-speech-bubble-23: "\f0f0";
$lnil-speech-bubble-37: "\f0f1";
$lnil-reply: "\f0f2";
$lnil-plane-alt: "\f0f3";
$lnil-boarding-pass-alt: "\f0f4";
$lnil-speech-bubble-36: "\f0f5";
$lnil-kayak: "\f0f6";
$lnil-speech-bubble-22: "\f0f7";
$lnil-mailbox: "\f0f8";
$lnil-speech-bubble-26: "\f0f9";
$lnil-speech-bubble-32: "\f0fa";
$lnil-phone-misscall: "\f0fb";
$lnil-mobile-chat: "\f0fc";
$lnil-message-search: "\f0fd";
$lnil-yacht-3: "\f0fe";
$lnil-envelope-alt: "\f0ff";
$lnil-yacht-2: "\f100";
$lnil-speech-bubble-33: "\f101";
$lnil-speech-bubble-27: "\f102";
$lnil-speech-bubble-31: "\f103";
$lnil-speech-bubble-25: "\f104";
$lnil-speech-bubble-19: "\f105";
$lnil-inbox: "\f106";
$lnil-comments: "\f107";
$lnil-helicopter: "\f108";
$lnil-road: "\f109";
$lnil-yacht-1: "\f10a";
$lnil-bridge: "\f10b";
$lnil-speech-bubble-18: "\f10c";
$lnil-speech-bubble-24: "\f10d";
$lnil-message-reply: "\f10e";
$lnil-speech-bubble-30: "\f10f";
$lnil-bubble: "\f110";
$lnil-phone-forward: "\f111";
$lnil-speech-bubble-43: "\f112";
$lnil-postcard: "\f113";
$lnil-message-lock: "\f114";
$lnil-message-incoming: "\f115";
$lnil-plane: "\f116";
$lnil-message-attachement: "\f117";
$lnil-speech-bubble-42: "\f118";
$lnil-ship1: "\f119";
$lnil-speech-bubble-40: "\f11a";
$lnil-envelope: "\f11b";
$lnil-phone-outgoing: "\f11c";
$lnil-message-forward: "\f11d";
$lnil-delivery-truck-5: "\f11e";
$lnil-phone-cut: "\f11f";
$lnil-mobile-alt-1: "\f120";
$lnil-message-check: "\f121";
$lnil-speech-bubble-41: "\f122";
$lnil-speech-bubble-45: "\f123";
$lnil-taxi: "\f124";
$lnil-train1: "\f125";
$lnil-phone-redial: "\f126";
$lnil-phone-conference: "\f127";
$lnil-comments-reply: "\f128";
$lnil-speech-bubble-44: "\f129";
$lnil-support: "\f12a";
$lnil-speech-bubble-46: "\f12b";
$lnil-message-plus: "\f12c";
$lnil-phone-set: "\f12d";
$lnil-message-info: "\f12e";
$lnil-bullhorn: "\f12f";
$lnil-message-shield: "\f130";
$lnil-car-alt-4: "\f131";
$lnil-train-alt: "\f132";
$lnil-caravan: "\f133";
$lnil-bridge-alt: "\f134";
$lnil-scooter: "\f135";
$lnil-road-alt: "\f136";
$lnil-car-alt-2: "\f137";
$lnil-message-love: "\f138";
$lnil-bus: "\f139";
$lnil-message-people: "\f13a";
$lnil-yacht: "\f13b";
$lnil-car-alt-3: "\f13c";
$lnil-car-alt-1: "\f13d";
$lnil-page: "\f13e";
$lnil-anchor: "\f13f";
$lnil-phone-ring: "\f140";
$lnil-message-edit: "\f141";
$lnil-message-people-alt: "\f142";
$lnil-speech-bubble-29: "\f143";
$lnil-speech-bubble-15: "\f144";
$lnil-phone: "\f145";
$lnil-speech-bubble-1: "\f146";
$lnil-message-outgoing: "\f147";
$lnil-car: "\f148";
$lnil-speech-bubble-14: "\f149";
$lnil-bridge-alt-4: "\f14a";
$lnil-phone-pause: "\f14b";
$lnil-speech-bubble-28: "\f14c";
$lnil-comments-alt1: "\f14d";
$lnil-speech-bubble-16: "\f14e";
$lnil-speech-bubble-2: "\f14f";
$lnil-phone-incoming: "\f150";
$lnil-speech-bubble-3: "\f151";
$lnil-message-block: "\f152";
$lnil-phone-block: "\f153";
$lnil-message-minus: "\f154";
$lnil-speech-bubble-17: "\f155";
$lnil-bridge-alt-3: "\f156";
$lnil-speech-bubble-13: "\f157";
$lnil-message-share: "\f158";
$lnil-road-alt-1: "\f159";
$lnil-speech-bubble-6: "\f15a";
$lnil-car-alt: "\f15b";
$lnil-message-desktop: "\f15c";
$lnil-phone-calling-alt: "\f15d";
$lnil-speech-bubble-12: "\f15e";
$lnil-bridge-alt-2: "\f15f";
$lnil-boarding-pass: "\f160";
$lnil-speech-bubble-10: "\f161";
$lnil-popup: "\f162";
$lnil-speech-bubble-38: "\f163";
$lnil-message-cross: "\f164";
$lnil-speech-bubble-4: "\f165";
$lnil-bi-cycle: "\f166";
$lnil-chat-minus: "\f167";
$lnil-chat-add1: "\f168";
$lnil-speech-bubble-5: "\f169";
$lnil-message-laptop: "\f16a";
$lnil-speech-bubble-39: "\f16b";
$lnil-speech-bubble-11: "\f16c";
$lnil-bridge-alt-1: "\f16d";
$lnil-idea-chat: "\f16e";
$lnil-idea-share: "\f16f";
$lnil-mobile-rocket-1: "\f170";
$lnil-rocket-growth-1: "\f171";
$lnil-head-idea-setting: "\f172";
$lnil-mobile-rocket-3: "\f173";
$lnil-mobile-rocket-2: "\f174";
$lnil-rocket-growth-2: "\f175";
$lnil-mobile-rocket: "\f176";
$lnil-mobile-target-1: "\f177";
$lnil-puzzle: "\f178";
$lnil-arrow-target-1: "\f179";
$lnil-idea-shield: "\f17a";
$lnil-letter-idea: "\f17b";
$lnil-idea-head: "\f17c";
$lnil-rocket-launch-1: "\f17d";
$lnil-business-rocket: "\f17e";
$lnil-networking-startup: "\f17f";
$lnil-grow-help: "\f180";
$lnil-rocket-launch-2: "\f181";
$lnil-rocket-search: "\f182";
$lnil-rocket-target: "\f183";
$lnil-rocket-launch-3: "\f184";
$lnil-idea-presentation: "\f185";
$lnil-idea-search: "\f186";
$lnil-preview: "\f187";
$lnil-rocket-launch-6: "\f188";
$lnil-rocket-favourite: "\f189";
$lnil-rocket-verified: "\f18a";
$lnil-rocket-earth: "\f18b";
$lnil-rocket-launch-4: "\f18c";
$lnil-idea-envelope: "\f18d";
$lnil-grow-help-1: "\f18e";
$lnil-mission: "\f18f";
$lnil-rocket-launch-5: "\f190";
$lnil-rocket-growth: "\f191";
$lnil-arrow-target: "\f192";
$lnil-business-partnership: "\f193";
$lnil-graph-growth: "\f194";
$lnil-rocket-money: "\f195";
$lnil-bulb-idea: "\f196";
$lnil-idea-down: "\f197";
$lnil-idea-briefcase: "\f198";
$lnil-flash-idea: "\f199";
$lnil-ipo-flag: "\f19a";
$lnil-rocket-star: "\f19b";
$lnil-maze: "\f19c";
$lnil-idea-head-1: "\f19d";
$lnil-view: "\f19e";
$lnil-idea-setting: "\f19f";
$lnil-rocket-idea: "\f1a0";
$lnil-laptop-target: "\f1a1";
$lnil-rocket-launch: "\f1a2";
$lnil-rocket1: "\f1a3";
$lnil-idea-reload: "\f1a4";
$lnil-idea-rise: "\f1a5";
$lnil-go-frame-1: "\f1a6";
$lnil-idea-location: "\f1a7";
$lnil-mobile-target: "\f1a8";
$lnil-money-idea: "\f1a9";
$lnil-go-frame: "\f1aa";
$lnil-idea-label: "\f1ab";
$lnil-idea-agenda: "\f1ac";
$lnil-cloud-idea: "\f1ad";
$lnil-paper-idea: "\f1ae";
$lnil-law-book: "\f1af";
$lnil-jail: "\f1b0";
$lnil-police-badge: "\f1b1";
$lnil-jury: "\f1b2";
$lnil-underline: "\f1b3";
$lnil-undo-alt: "\f1b4";
$lnil-highlight-alt1: "\f1b5";
$lnil-text-format-remove: "\f1b6";
$lnil-text-align-center: "\f1b7";
$lnil-strikethrough: "\f1b8";
$lnil-pagination: "\f1b9";
$lnil-pilcrow: "\f1ba";
$lnil-line-dashed: "\f1bb";
$lnil-vertical-line: "\f1bc";
$lnil-text-align-justify: "\f1bd";
$lnil-sort-alpha-asc: "\f1be";
$lnil-bold: "\f1bf";
$lnil-line-dotted: "\f1c0";
$lnil-text-format: "\f1c1";
$lnil-grammer: "\f1c2";
$lnil-text: "\f1c3";
$lnil-page-break: "\f1c4";
$lnil-text-alt: "\f1c5";
$lnil-capital-letter: "\f1c6";
$lnil-redo: "\f1c7";
$lnil-clear-format: "\f1c8";
$lnil-spellcheck: "\f1c9";
$lnil-text-align-left: "\f1ca";
$lnil-reflect: "\f1cb";
$lnil-line-spacing: "\f1cc";
$lnil-page1: "\f1cd";
$lnil-idea-alt1: "\f1ce";
$lnil-sort-amount-asc: "\f1cf";
$lnil-symbol: "\f1d0";
$lnil-height: "\f1d1";
$lnil-font-alt: "\f1d2";
$lnil-quote-alt-4: "\f1d3";
$lnil-indent-increase: "\f1d4";
$lnil-quote-alt-5: "\f1d5";
$lnil-text-align-right: "\f1d6";
$lnil-text-underline-alt: "\f1d7";
$lnil-undo: "\f1d8";
$lnil-italic: "\f1d9";
$lnil-quote-alt-7: "\f1da";
$lnil-quote-alt-6: "\f1db";
$lnil-sort-amount-dsc: "\f1dc";
$lnil-indent-decrease: "\f1dd";
$lnil-quote-alt-2: "\f1de";
$lnil-quote-alt-3: "\f1df";
$lnil-quote-alt: "\f1e0";
$lnil-strikethrough-alt: "\f1e1";
$lnil-quote-alt-1: "\f1e2";
$lnil-line-double: "\f1e3";
$lnil-font: "\f1e4";
$lnil-wheelchair-alt: "\f1e5";
$lnil-capsule-alt: "\f1e6";
$lnil-capsule: "\f1e7";
$lnil-laptop-alt-medical: "\f1e8";
$lnil-virus: "\f1e9";
$lnil-laptop-report-medical: "\f1ea";
$lnil-smartwatch-alt-1: "\f1eb";
$lnil-signboard-hospital: "\f1ec";
$lnil-hospital-location: "\f1ed";
$lnil-symbols-female: "\f1ee";
$lnil-file-medical-1: "\f1ef";
$lnil-medicine: "\f1f0";
$lnil-dropper: "\f1f1";
$lnil-broken-bone: "\f1f2";
$lnil-chat-vet: "\f1f3";
$lnil-chest: "\f1f4";
$lnil-heart-plus1: "\f1f5";
$lnil-user-medical: "\f1f6";
$lnil-ambulance-2: "\f1f7";
$lnil-yoga-mat1: "\f1f8";
$lnil-healthcare: "\f1f9";
$lnil-dropper-alt-2: "\f1fa";
$lnil-file-alt-medical: "\f1fb";
$lnil-test-tube-alt: "\f1fc";
$lnil-hospital-bed-alt-1: "\f1fd";
$lnil-hospital-alt: "\f1fe";
$lnil-hospital-building: "\f1ff";
$lnil-medical-briefcase: "\f200";
$lnil-hospital-bed-patient: "\f201";
$lnil-liver: "\f202";
$lnil-brain-check: "\f203";
$lnil-dropper-alt-1: "\f204";
$lnil-smartphone-alt-medical: "\f205";
$lnil-tweezers: "\f206";
$lnil-diagnosis-alt: "\f207";
$lnil-walker: "\f208";
$lnil-nose: "\f209";
$lnil-brain: "\f20a";
$lnil-male: "\f20b";
$lnil-hospital-bed-alt-2: "\f20c";
$lnil-refresh-medical: "\f20d";
$lnil-mobile-phones-medical: "\f20e";
$lnil-cotton-buds: "\f20f";
$lnil-medical-smartphone-alt-1: "\f210";
$lnil-signal-hospital: "\f211";
$lnil-ambulance-alt: "\f212";
$lnil-scalpel: "\f213";
$lnil-eye-test: "\f214";
$lnil-diagnosis: "\f215";
$lnil-monitor-heart-alt: "\f216";
$lnil-ambulance: "\f217";
$lnil-report-laptop: "\f218";
$lnil-saline-alt-1: "\f219";
$lnil-heartrate-monitor: "\f21a";
$lnil-stethoscope-alt: "\f21b";
$lnil-dna: "\f21c";
$lnil-hat-medical: "\f21d";
$lnil-desktop-computer-alt: "\f21e";
$lnil-desktop-monitor-heart: "\f21f";
$lnil-thermometer: "\f220";
$lnil-medical-protection: "\f221";
$lnil-gluten-free: "\f222";
$lnil-kidney-alt: "\f223";
$lnil-patient-alt-1: "\f224";
$lnil-medical-sign: "\f225";
$lnil-capsule-alt-1: "\f226";
$lnil-temparature-measuring: "\f227";
$lnil-bed-patient: "\f228";
$lnil-share-alt1: "\f229";
$lnil-monitor-medical: "\f22a";
$lnil-heart-check: "\f22b";
$lnil-hospital: "\f22c";
$lnil-scale1: "\f22d";
$lnil-bed-alt-patient: "\f22e";
$lnil-vaccine: "\f22f";
$lnil-lungs-alt: "\f230";
$lnil-suitcase-medical: "\f231";
$lnil-skipping-rope: "\f232";
$lnil-saline: "\f233";
$lnil-lights-medical: "\f234";
$lnil-clipboard-alt: "\f235";
$lnil-injection: "\f236";
$lnil-smartphone-heart-beat: "\f237";
$lnil-bandage-alt: "\f238";
$lnil-monitor-alt-2: "\f239";
$lnil-syringe: "\f23a";
$lnil-inhaler: "\f23b";
$lnil-saline-alt: "\f23c";
$lnil-kettleball: "\f23d";
$lnil-injury: "\f23e";
$lnil-cardiology: "\f23f";
$lnil-brain-alt: "\f240";
$lnil-hospital-sign: "\f241";
$lnil-heart-monitor: "\f242";
$lnil-slim: "\f243";
$lnil-weight-machine1: "\f244";
$lnil-male-alt: "\f245";
$lnil-smartwatch-medical: "\f246";
$lnil-medical-call: "\f247";
$lnil-bone: "\f248";
$lnil-ambulance-alt-1: "\f249";
$lnil-wheelchair: "\f24a";
$lnil-clipboard-medical: "\f24b";
$lnil-cannula: "\f24c";
$lnil-medicine-alt: "\f24d";
$lnil-sharing: "\f24e";
$lnil-dropper-alt: "\f24f";
$lnil-suitcase-alt-medical: "\f250";
$lnil-hospital-alt-3: "\f251";
$lnil-basketball: "\f252";
$lnil-online-checkup: "\f253";
$lnil-lungs: "\f254";
$lnil-dumbbell: "\f255";
$lnil-fluid: "\f256";
$lnil-hospital-alt-2: "\f257";
$lnil-sthethoscope: "\f258";
$lnil-thermometer-alt: "\f259";
$lnil-uterus: "\f25a";
$lnil-microscope: "\f25b";
$lnil-test-tube: "\f25c";
$lnil-first-aid: "\f25d";
$lnil-bed-dentist: "\f25e";
$lnil-smartwatch-alt-heart-monitor: "\f25f";
$lnil-hospital-bed-alt: "\f260";
$lnil-reload-medical: "\f261";
$lnil-desktop-computer: "\f262";
$lnil-tooth: "\f263";
$lnil-hospital-alt-1: "\f264";
$lnil-medicine-bottole: "\f265";
$lnil-share-medical: "\f266";
$lnil-hospital-alt-5: "\f267";
$lnil-medical-mask: "\f268";
$lnil-blood-donation: "\f269";
$lnil-square-medical: "\f26a";
$lnil-medical-briefcase-1: "\f26b";
$lnil-hospital-alt-4: "\f26c";
$lnil-hospital-location-alt: "\f26d";
$lnil-virus-alt: "\f26e";
$lnil-life-guard: "\f26f";
$lnil-female: "\f270";
$lnil-hospital-alt-6: "\f271";
$lnil-hospital-location-alt-1: "\f272";
$lnil-pulse: "\f273";
$lnil-weight: "\f274";
$lnil-capsules: "\f275";
$lnil-brain-check-alt: "\f276";
$lnil-medical-sign-alt: "\f277";
$lnil-hospital-bed: "\f278";
$lnil-disabled: "\f279";
$lnil-dollar-value-up: "\f27a";
$lnil-rupee-up: "\f27b";
$lnil-atm-alt: "\f27c";
$lnil-yen-note: "\f27d";
$lnil-savings: "\f27e";
$lnil-bitcoin-laptop: "\f27f";
$lnil-atm: "\f280";
$lnil-bitcoin-website: "\f281";
$lnil-rupee: "\f282";
$lnil-pound-down: "\f283";
$lnil-dollar: "\f284";
$lnil-money-envelope: "\f285";
$lnil-check-alt-1: "\f286";
$lnil-vault: "\f287";
$lnil-mobile-coin: "\f288";
$lnil-briefcase-bitcoin: "\f289";
$lnil-taka-coin: "\f28a";
$lnil-nfc: "\f28b";
$lnil-safebox-1: "\f28c";
$lnil-dollar-up: "\f28d";
$lnil-taka-up: "\f28e";
$lnil-calculator-alt: "\f28f";
$lnil-bitcoin-cloud: "\f290";
$lnil-yen-card: "\f291";
$lnil-euro-coin1: "\f292";
$lnil-money-stash: "\f293";
$lnil-safebox-2: "\f294";
$lnil-ruble: "\f295";
$lnil-placeholder-dollar: "\f296";
$lnil-yen-note-1: "\f297";
$lnil-safe-deposit: "\f298";
$lnil-bank-alt-1: "\f299";
$lnil-euro: "\f29a";
$lnil-pound-card: "\f29b";
$lnil-head-idea: "\f29c";
$lnil-website-money: "\f29d";
$lnil-tree-money: "\f29e";
$lnil-wallet-alt-1: "\f29f";
$lnil-diamond-alt: "\f2a0";
$lnil-pound: "\f2a1";
$lnil-ruble-up: "\f2a2";
$lnil-money-protection: "\f2a3";
$lnil-wallet: "\f2a4";
$lnil-credit-cards1: "\f2a5";
$lnil-gold-bar: "\f2a6";
$lnil-dollar-bag-1: "\f2a7";
$lnil-taxes: "\f2a8";
$lnil-smartphone-stat: "\f2a9";
$lnil-euro-up: "\f2aa";
$lnil-rupee-coin: "\f2ab";
$lnil-yen-down: "\f2ac";
$lnil-dollar-down: "\f2ad";
$lnil-money-location: "\f2ae";
$lnil-euro-down: "\f2af";
$lnil-coins1: "\f2b0";
$lnil-dollar-euro-exchange: "\f2b1";
$lnil-pound-sterling-2: "\f2b2";
$lnil-taka-down: "\f2b3";
$lnil-coin: "\f2b4";
$lnil-rupee-card: "\f2b5";
$lnil-placeholder-ruble: "\f2b6";
$lnil-yen-up: "\f2b7";
$lnil-dollar-eye: "\f2b8";
$lnil-placeholder-euro: "\f2b9";
$lnil-pound-coin: "\f2ba";
$lnil-withdraw-money: "\f2bb";
$lnil-bank: "\f2bc";
$lnil-credit-card: "\f2bd";
$lnil-phone-call-dollar: "\f2be";
$lnil-atm-alt-1: "\f2bf";
$lnil-dollar-value-down: "\f2c0";
$lnil-pound-sterling: "\f2c1";
$lnil-shekel: "\f2c2";
$lnil-diamond1: "\f2c3";
$lnil-placeholder-pound: "\f2c4";
$lnil-taka-card: "\f2c5";
$lnil-dollar-connect: "\f2c6";
$lnil-ruble-down: "\f2c7";
$lnil-profit: "\f2c8";
$lnil-rupee-down: "\f2c9";
$lnil-yen-coin: "\f2ca";
$lnil-bank-alt: "\f2cb";
$lnil-dollar-card: "\f2cc";
$lnil-euro-card: "\f2cd";
$lnil-cent: "\f2ce";
$lnil-bitcoin-up: "\f2cf";
$lnil-yen: "\f2d0";
$lnil-manager: "\f2d1";
$lnil-hourglass-dollar: "\f2d2";
$lnil-payment-terminal: "\f2d3";
$lnil-pound-up: "\f2d4";
$lnil-property-balance: "\f2d5";
$lnil-calculator1: "\f2d6";
$lnil-statistics: "\f2d7";
$lnil-money-bag1: "\f2d8";
$lnil-taka: "\f2d9";
$lnil-money-protection-alt: "\f2da";
$lnil-check-alt: "\f2db";
$lnil-safe-box: "\f2dc";
$lnil-mobile-coin-taka: "\f2dd";
$lnil-bitcoin-chip: "\f2de";
$lnil-clock-dollar: "\f2df";
$lnil-suitcase-alt: "\f2e0";
$lnil-tent-alt: "\f2e1";
$lnil-taj-mahal: "\f2e2";
$lnil-tent-alt-1: "\f2e3";
$lnil-surfboard-alt-2: "\f2e4";
$lnil-surfboard-alt: "\f2e5";
$lnil-suitcase-alt-1: "\f2e6";
$lnil-surfboard-alt-1: "\f2e7";
$lnil-lighthouse: "\f2e8";
$lnil-cable-car: "\f2e9";
$lnil-travel: "\f2ea";
$lnil-beach-ball: "\f2eb";
$lnil-beach-chair: "\f2ec";
$lnil-tent: "\f2ed";
$lnil-eiffel-tower: "\f2ee";
$lnil-pyramids: "\f2ef";
$lnil-empire-state-building: "\f2f0";
$lnil-sydney-opera-house: "\f2f1";
$lnil-hot-air-baloon: "\f2f2";
$lnil-flight-search: "\f2f3";
$lnil-suitcase: "\f2f4";
$lnil-airport: "\f2f5";
$lnil-beach: "\f2f6";
$lnil-surf-board: "\f2f7";
$lnil-smart-home: "\f2f8";
$lnil-buy-house: "\f2f9";
$lnil-lift: "\f2fa";
$lnil-fountain: "\f2fb";
$lnil-house-cancelled: "\f2fc";
$lnil-home3: "\f2fd";
$lnil-house-auction-1: "\f2fe";
$lnil-placeholder-map: "\f2ff";
$lnil-condominium: "\f300";
$lnil-air-conditioner1: "\f301";
$lnil-shopping-mall: "\f302";
$lnil-blueprint: "\f303";
$lnil-house-rating: "\f304";
$lnil-house-add: "\f305";
$lnil-house-discount: "\f306";
$lnil-fence1: "\f307";
$lnil-hospital1: "\f308";
$lnil-house-auction: "\f309";
$lnil-apartments: "\f30a";
$lnil-smart-home-control: "\f30b";
$lnil-placeholder-house: "\f30c";
$lnil-website-house: "\f30d";
$lnil-sign-sale: "\f30e";
$lnil-condominium-1: "\f30f";
$lnil-billboard2: "\f310";
$lnil-home-documents: "\f311";
$lnil-warehouse: "\f312";
$lnil-home-11: "\f313";
$lnil-sign-rent: "\f314";
$lnil-flats: "\f315";
$lnil-search-home: "\f316";
$lnil-swimming-pool1: "\f317";
$lnil-house-chosen: "\f318";
$lnil-cottage: "\f319";
$lnil-apartments-alt-10: "\f31a";
$lnil-apartments-alt-1: "\f31b";
$lnil-factory-alt: "\f31c";
$lnil-apartments-alt-11: "\f31d";
$lnil-church-alt: "\f31e";
$lnil-apartments-alt-2: "\f31f";
$lnil-boiler: "\f320";
$lnil-cottage-alt: "\f321";
$lnil-hut-alt-4: "\f322";
$lnil-apartments-alt-3: "\f323";
$lnil-apartments-alt-12: "\f324";
$lnil-apartments-alt: "\f325";
$lnil-apartments-alt-7: "\f326";
$lnil-archway: "\f327";
$lnil-church1: "\f328";
$lnil-apartments-alt-6: "\f329";
$lnil-apartments-alt-4: "\f32a";
$lnil-hut-alt-3: "\f32b";
$lnil-hut-alt-2: "\f32c";
$lnil-flats-alt-2: "\f32d";
$lnil-court-house: "\f32e";
$lnil-apartments-alt-5: "\f32f";
$lnil-home-alt: "\f330";
$lnil-buildings-alt-2: "\f331";
$lnil-buildings: "\f332";
$lnil-castle-alt-7: "\f333";
$lnil-castle-alt-6: "\f334";
$lnil-buildings-alt-3: "\f335";
$lnil-burj-al-arab: "\f336";
$lnil-cottage-alt-1: "\f337";
$lnil-buildings-alt-1: "\f338";
$lnil-castle-alt-5: "\f339";
$lnil-castle: "\f33a";
$lnil-cottage-alt-2: "\f33b";
$lnil-buildings-alt-4: "\f33c";
$lnil-castle-alt-1: "\f33d";
$lnil-home-alt-13: "\f33e";
$lnil-court: "\f33f";
$lnil-home-alt-12: "\f340";
$lnil-buildings-alt-5: "\f341";
$lnil-factory: "\f342";
$lnil-castle-alt-2: "\f343";
$lnil-castle-alt-3: "\f344";
$lnil-boiler-alt: "\f345";
$lnil-housing-alt: "\f346";
$lnil-court-building: "\f347";
$lnil-apartments1: "\f348";
$lnil-fortress: "\f349";
$lnil-flats-alt: "\f34a";
$lnil-home-alt-6: "\f34b";
$lnil-factory-alt-1: "\f34c";
$lnil-house-alt-4: "\f34d";
$lnil-apartments-alt-8: "\f34e";
$lnil-house-alt: "\f34f";
$lnil-apartments-alt-9: "\f350";
$lnil-factory-alt-2: "\f351";
$lnil-house-alt-7: "\f352";
$lnil-house-alt-6: "\f353";
$lnil-factory-alt-3: "\f354";
$lnil-court-alt: "\f355";
$lnil-home-alt-4: "\f356";
$lnil-buildings-alt: "\f357";
$lnil-arch: "\f358";
$lnil-house-alt-3: "\f359";
$lnil-factory-alt-6: "\f35a";
$lnil-factory-alt-4: "\f35b";
$lnil-house-alt-11: "\f35c";
$lnil-castle-alt: "\f35d";
$lnil-flats1: "\f35e";
$lnil-factory-alt-5: "\f35f";
$lnil-cctv: "\f360";
$lnil-video-playback: "\f361";
$lnil-camera-3: "\f362";
$lnil-repeat-one: "\f363";
$lnil-backward-alt: "\f364";
$lnil-hd-video-alt: "\f365";
$lnil-webcam: "\f366";
$lnil-gallery: "\f367";
$lnil-expand-alt-1: "\f368";
$lnil-volume: "\f369";
$lnil-stop: "\f36a";
$lnil-video-camera-alt: "\f36b";
$lnil-volume-mute: "\f36c";
$lnil-previous: "\f36d";
$lnil-fast-forward: "\f36e";
$lnil-volume-alt: "\f36f";
$lnil-play: "\f370";
$lnil-backward: "\f371";
$lnil-volume-medium: "\f372";
$lnil-full-screen: "\f373";
$lnil-next: "\f374";
$lnil-volume-high: "\f375";
$lnil-video: "\f376";
$lnil-video-cut: "\f377";
$lnil-video-alt: "\f378";
$lnil-volume-low: "\f379";
$lnil-video-camera-alt-4: "\f37a";
$lnil-shuffle: "\f37b";
$lnil-pause: "\f37c";
$lnil-forward: "\f37d";
$lnil-slideshow: "\f37e";
$lnil-video-alt-1: "\f37f";
$lnil-video-camera-alt-1: "\f380";
$lnil-video-camera-alt-3: "\f381";
$lnil-film: "\f382";
$lnil-hd-video: "\f383";
$lnil-frame-expand: "\f384";
$lnil-video-camera-alt-2: "\f385";

