@import "./sass/main.scss";

*,
body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
p,
a {
  font-family: "Poppins", sans-serif !important;
}
