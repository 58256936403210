.hero-container {
  height: 82vh;
  position: relative;
  &-header {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(-10%, -50%);
    width: 45%;

    h3 {
      color: $color-primary-dark;
      animation-name: moveInLeft;
      animation-duration: 1s;
      animation-timing-function: ease-in;
    }
  }

  &-header-right {
    position: absolute;
    right: 10%;
    top: 50%;
    transform: translate(20%, -50%);
    width: 45%;

    h3 {
      color: $color-primary-dark;
      animation-name: moveInLeft;
      animation-duration: 1s;
      animation-timing-function: ease-in;
    }
  }

  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

.section-one {
  padding: 3rem 3rem 2rem;
  background-color: $color-tertiary-light-1;
  &-left {
    text-align: center;
    h4 {
      text-align: left;
    }
  }

  &-right {
    margin-top: 8rem;

    body {
      margin-top: 1.4rem;
      background: none;
    }
  }
  &-image {
    height: 60%;
    margin-top: 4rem;
  }
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

.section-two {
  padding: 7rem 3rem;

  &-card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2rem;
  }
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

.section-three {
  padding: 6rem 3rem;
  background: linear-gradient(
    12deg,
    rgba($color-tertiary-light-1, 1) 20%,
    rgba($color-secondary-light-1, 1) 50%
  );
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}
.section-about {
  background-color: #87ceeb61;
  padding: 15rem 0;
  // margin-top: -20vh;
  img {
    width: 100%;
    height: 100%;
  }
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

.section-protfolio {
  background-color: #87ceeb61;
  padding: 15rem 0 15rem 0;

  .cover-img {
    // background-image: url("../../assets/SVG/line-draw-1.svg");
    opacity: 0.1;
    height: 78vh;
    width: 50%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
  }
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

.section-features {
  background-color: #0c9c8d;
  padding: 15rem 0 15rem 0;

  .card-outline-none {
    text-align: center;
    padding-bottom: 3rem;
    color: $color-grey-dark-3;
  }
  &--icon {
    display: inline-block;

    i {
      font-size: 4rem;
    }
  }
  p {
    font-size: $default-font-size;
  }
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}

//////////////////////
/** services css */

.service-section-one {
  padding: 6rem 3rem 2rem;
  background-color: $color-grey-light-2;
  @include respond(phone) {
    font-size: 0.875rem;
  }

  @include respond(tab-land) {
    //width < 1200 ?
    font-size: 1rem; //1rem = 9px, 9/16 = 56.25%
  }

  @include respond(tab-port) {
    //width < 900 ?
    font-size: 0.875rem; //1rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 1.125rem; //1rem =12, 12/16
  }
}
