.notfound {
  padding: 5rem;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &-404 {
    & img {
      width: 60%;
    }
    font-size: 1em;
    font-weight: 700;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }
  p {
    font-size: 0.8rem;
  }
}
