@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }
  80% {
    transform: translateX(1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }
  80% {
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem);
  }
  80% {
    transform: translateY(-1rem);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@-webkit-keyframes fadein {
  0%{ opacity: 0; }
  20% { opacity: 1;}
  100% { opacity: 1; }
}

@keyframes fadein {
  0%{ opacity: 0; }
  20% { opacity: 1;}
  100% { opacity: 1; }
}

@-webkit-keyframes fadeinout {
  0%{ opacity: 1; }
  90% { opacity: 1;}
  100% { opacity: 0; }
}

@keyframes fadeinout {
  0%{ opacity: 1; }
  90% { opacity: 1;}
  100% { opacity: 0; }
}