@mixin clearfix {
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}

@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// MEDIA QUERY MANGER
/*
0-600px : phone
600-900px:  Tablet portrait
900-1200px:  Tablet landscape
[1200- 1800] is where our normal styles apply
1800px + : Big desktop
*/
@mixin respond-phone {
  @media (max-width: 600px) {
    @content;
  }
}

/*
#$breakpoint arugments choices:
-phone
-tab-port
-tab-land
-big-desktop

ORDER: Base + typography>general layout + grid > page layout > component

1em = 16px
*/
@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 37.5em) {
      //600px
      @content;
    }
  }
  @if $breakpoint == tab-port {
    @media (max-width: 56.25em) {
      //900px
      @content;
    }
  }
  @if $breakpoint == tab-land {
    @media (max-width: 75em) {
      //1200px
      @content;
    }
  }
  @if $breakpoint == big-desktop {
    @media (min-width: 112.5em) {
      //1800px
      @content;
    }
  }
}

// center vertically and/or horizontally an absolute positioned element

@mixin center($xy: xy) {
  @if $xy == xy {
    left: 50%;
    top: 50%;
    bottom: auto;
    right: auto;
    @include transform(translateX(-50%) translateY(-50%));
  } @else if $xy == x {
    left: 50%;
    right: auto;
    @include transform(translateX(-50%));
  } @else if $xy == y {
    top: 50%;
    bottom: auto;
    @include transform(translateY(-50%));
  }
}

// border radius

@mixin border-radius($radius: 0.25em) {
  border-radius: $radius;
}

// antialiasing mode font rendering

@mixin font-smoothing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
