@import "variables_font";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?egonig');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?egonig#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?egonig') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?egonig') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?egonig##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.lnil {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lnil-24-7 {
  &:before {
    content: $lnil-24-7; 
  }
}
.lnil-24-hours-truck {
  &:before {
    content: $lnil-24-hours-truck; 
  }
}
.lnil-3d {
  &:before {
    content: $lnil-3d; 
  }
}
.lnil-5g {
  &:before {
    content: $lnil-5g; 
  }
}
.lnil-5g-alt {
  &:before {
    content: $lnil-5g-alt; 
  }
}
.lnil-5g-alt-1 {
  &:before {
    content: $lnil-5g-alt-1; 
  }
}
.lnil-5g-sim {
  &:before {
    content: $lnil-5g-sim; 
  }
}
.lnil-360-degree {
  &:before {
    content: $lnil-360-degree; 
  }
}
.lnil-hen {
  &:before {
    content: $lnil-hen; 
  }
}
.lnil-scythe {
  &:before {
    content: $lnil-scythe; 
  }
}
.lnil-tomato {
  &:before {
    content: $lnil-tomato; 
  }
}
.lnil-cow {
  &:before {
    content: $lnil-cow; 
  }
}
.lnil-weathercock {
  &:before {
    content: $lnil-weathercock; 
  }
}
.lnil-pruning-shears {
  &:before {
    content: $lnil-pruning-shears; 
  }
}
.lnil-windmill {
  &:before {
    content: $lnil-windmill; 
  }
}
.lnil-chicken {
  &:before {
    content: $lnil-chicken; 
  }
}
.lnil-barn {
  &:before {
    content: $lnil-barn; 
  }
}
.lnil-watering-can {
  &:before {
    content: $lnil-watering-can; 
  }
}
.lnil-tob-plant {
  &:before {
    content: $lnil-tob-plant; 
  }
}
.lnil-seeds-1 {
  &:before {
    content: $lnil-seeds-1; 
  }
}
.lnil-sunflower {
  &:before {
    content: $lnil-sunflower; 
  }
}
.lnil-silo {
  &:before {
    content: $lnil-silo; 
  }
}
.lnil-reservoir-1 {
  &:before {
    content: $lnil-reservoir-1; 
  }
}
.lnil-scythe-1 {
  &:before {
    content: $lnil-scythe-1; 
  }
}
.lnil-seeds {
  &:before {
    content: $lnil-seeds; 
  }
}
.lnil-egg {
  &:before {
    content: $lnil-egg; 
  }
}
.lnil-flower {
  &:before {
    content: $lnil-flower; 
  }
}
.lnil-sprout {
  &:before {
    content: $lnil-sprout; 
  }
}
.lnil-reservoir {
  &:before {
    content: $lnil-reservoir; 
  }
}
.lnil-grass {
  &:before {
    content: $lnil-grass; 
  }
}
.lnil-well {
  &:before {
    content: $lnil-well; 
  }
}
.lnil-baloon-alt-3 {
  &:before {
    content: $lnil-baloon-alt-3; 
  }
}
.lnil-baloon-alt-2 {
  &:before {
    content: $lnil-baloon-alt-2; 
  }
}
.lnil-firework-alt-1 {
  &:before {
    content: $lnil-firework-alt-1; 
  }
}
.lnil-candy-corn {
  &:before {
    content: $lnil-candy-corn; 
  }
}
.lnil-candle {
  &:before {
    content: $lnil-candle; 
  }
}
.lnil-christmas-star {
  &:before {
    content: $lnil-christmas-star; 
  }
}
.lnil-candy {
  &:before {
    content: $lnil-candy; 
  }
}
.lnil-confetti {
  &:before {
    content: $lnil-confetti; 
  }
}
.lnil-ballon-alt-1 {
  &:before {
    content: $lnil-ballon-alt-1; 
  }
}
.lnil-candy-alt-1 {
  &:before {
    content: $lnil-candy-alt-1; 
  }
}
.lnil-cake {
  &:before {
    content: $lnil-cake; 
  }
}
.lnil-firecrackers-alt {
  &:before {
    content: $lnil-firecrackers-alt; 
  }
}
.lnil-fireworks {
  &:before {
    content: $lnil-fireworks; 
  }
}
.lnil-candy-alt-2 {
  &:before {
    content: $lnil-candy-alt-2; 
  }
}
.lnil-candy-alt-3 {
  &:before {
    content: $lnil-candy-alt-3; 
  }
}
.lnil-bowtie-alt {
  &:before {
    content: $lnil-bowtie-alt; 
  }
}
.lnil-petard {
  &:before {
    content: $lnil-petard; 
  }
}
.lnil-spray {
  &:before {
    content: $lnil-spray; 
  }
}
.lnil-firecrackers-alt-4 {
  &:before {
    content: $lnil-firecrackers-alt-4; 
  }
}
.lnil-lolipop-1 {
  &:before {
    content: $lnil-lolipop-1; 
  }
}
.lnil-firecracker {
  &:before {
    content: $lnil-firecracker; 
  }
}
.lnil-lolipop-3 {
  &:before {
    content: $lnil-lolipop-3; 
  }
}
.lnil-mask {
  &:before {
    content: $lnil-mask; 
  }
}
.lnil-pumpkin {
  &:before {
    content: $lnil-pumpkin; 
  }
}
.lnil-candy-cane {
  &:before {
    content: $lnil-candy-cane; 
  }
}
.lnil-lolipop-2 {
  &:before {
    content: $lnil-lolipop-2; 
  }
}
.lnil-heart-baloon {
  &:before {
    content: $lnil-heart-baloon; 
  }
}
.lnil-firecrackers-alt-3 {
  &:before {
    content: $lnil-firecrackers-alt-3; 
  }
}
.lnil-flags {
  &:before {
    content: $lnil-flags; 
  }
}
.lnil-firecrackers-alt-2 {
  &:before {
    content: $lnil-firecrackers-alt-2; 
  }
}
.lnil-ballon-alt {
  &:before {
    content: $lnil-ballon-alt; 
  }
}
.lnil-guitar {
  &:before {
    content: $lnil-guitar; 
  }
}
.lnil-candy-alt {
  &:before {
    content: $lnil-candy-alt; 
  }
}
.lnil-firecrackers-alt-1 {
  &:before {
    content: $lnil-firecrackers-alt-1; 
  }
}
.lnil-baloon {
  &:before {
    content: $lnil-baloon; 
  }
}
.lnil-candle-alt-1 {
  &:before {
    content: $lnil-candle-alt-1; 
  }
}
.lnil-wand {
  &:before {
    content: $lnil-wand; 
  }
}
.lnil-skull-mask {
  &:before {
    content: $lnil-skull-mask; 
  }
}
.lnil-birthday-hat {
  &:before {
    content: $lnil-birthday-hat; 
  }
}
.lnil-lolipop {
  &:before {
    content: $lnil-lolipop; 
  }
}
.lnil-candle-alt {
  &:before {
    content: $lnil-candle-alt; 
  }
}
.lnil-mustache {
  &:before {
    content: $lnil-mustache; 
  }
}
.lnil-bell-remove {
  &:before {
    content: $lnil-bell-remove; 
  }
}
.lnil-time-smartphone {
  &:before {
    content: $lnil-time-smartphone; 
  }
}
.lnil-clock-alt {
  &:before {
    content: $lnil-clock-alt; 
  }
}
.lnil-notification-alt-1 {
  &:before {
    content: $lnil-notification-alt-1; 
  }
}
.lnil-quarter-alt-1 {
  &:before {
    content: $lnil-quarter-alt-1; 
  }
}
.lnil-three-querters {
  &:before {
    content: $lnil-three-querters; 
  }
}
.lnil-hour {
  &:before {
    content: $lnil-hour; 
  }
}
.lnil-wristwatch {
  &:before {
    content: $lnil-wristwatch; 
  }
}
.lnil-hourglass-alt {
  &:before {
    content: $lnil-hourglass-alt; 
  }
}
.lnil-bell-alt {
  &:before {
    content: $lnil-bell-alt; 
  }
}
.lnil-calender-minus {
  &:before {
    content: $lnil-calender-minus; 
  }
}
.lnil-alarm-timer {
  &:before {
    content: $lnil-alarm-timer; 
  }
}
.lnil-add-notification {
  &:before {
    content: $lnil-add-notification; 
  }
}
.lnil-time {
  &:before {
    content: $lnil-time; 
  }
}
.lnil-timer-chat {
  &:before {
    content: $lnil-timer-chat; 
  }
}
.lnil-time-settings {
  &:before {
    content: $lnil-time-settings; 
  }
}
.lnil-time-folder {
  &:before {
    content: $lnil-time-folder; 
  }
}
.lnil-timer-desktop {
  &:before {
    content: $lnil-timer-desktop; 
  }
}
.lnil-wristwatch-alt {
  &:before {
    content: $lnil-wristwatch-alt; 
  }
}
.lnil-bell-mute {
  &:before {
    content: $lnil-bell-mute; 
  }
}
.lnil-notification {
  &:before {
    content: $lnil-notification; 
  }
}
.lnil-half-time {
  &:before {
    content: $lnil-half-time; 
  }
}
.lnil-mute {
  &:before {
    content: $lnil-mute; 
  }
}
.lnil-half-an-hour {
  &:before {
    content: $lnil-half-an-hour; 
  }
}
.lnil-timer-desktop-alt {
  &:before {
    content: $lnil-timer-desktop-alt; 
  }
}
.lnil-minus-notification {
  &:before {
    content: $lnil-minus-notification; 
  }
}
.lnil-quarter-alt {
  &:before {
    content: $lnil-quarter-alt; 
  }
}
.lnil-smartwatch-alt {
  &:before {
    content: $lnil-smartwatch-alt; 
  }
}
.lnil-browser-timer {
  &:before {
    content: $lnil-browser-timer; 
  }
}
.lnil-clock-alt-1 {
  &:before {
    content: $lnil-clock-alt-1; 
  }
}
.lnil-alarm-check {
  &:before {
    content: $lnil-alarm-check; 
  }
}
.lnil-secure-time {
  &:before {
    content: $lnil-secure-time; 
  }
}
.lnil-bell-add {
  &:before {
    content: $lnil-bell-add; 
  }
}
.lnil-time-share {
  &:before {
    content: $lnil-time-share; 
  }
}
.lnil-bell-alt-1 {
  &:before {
    content: $lnil-bell-alt-1; 
  }
}
.lnil-three-querters-alt {
  &:before {
    content: $lnil-three-querters-alt; 
  }
}
.lnil-alarm-clock-alt {
  &:before {
    content: $lnil-alarm-clock-alt; 
  }
}
.lnil-history {
  &:before {
    content: $lnil-history; 
  }
}
.lnil-smartphone-watch-alt {
  &:before {
    content: $lnil-smartphone-watch-alt; 
  }
}
.lnil-timer {
  &:before {
    content: $lnil-timer; 
  }
}
.lnil-laptop-timer {
  &:before {
    content: $lnil-laptop-timer; 
  }
}
.lnil-t-shirt {
  &:before {
    content: $lnil-t-shirt; 
  }
}
.lnil-crown-alt-1 {
  &:before {
    content: $lnil-crown-alt-1; 
  }
}
.lnil-old {
  &:before {
    content: $lnil-old; 
  }
}
.lnil-crown-alt-2 {
  &:before {
    content: $lnil-crown-alt-2; 
  }
}
.lnil-thought {
  &:before {
    content: $lnil-thought; 
  }
}
.lnil-home {
  &:before {
    content: $lnil-home; 
  }
}
.lnil-family {
  &:before {
    content: $lnil-family; 
  }
}
.lnil-t-shirt-alt {
  &:before {
    content: $lnil-t-shirt-alt; 
  }
}
.lnil-baby-kid {
  &:before {
    content: $lnil-baby-kid; 
  }
}
.lnil-glasses {
  &:before {
    content: $lnil-glasses; 
  }
}
.lnil-sunglass {
  &:before {
    content: $lnil-sunglass; 
  }
}
.lnil-crown {
  &:before {
    content: $lnil-crown; 
  }
}
.lnil-cup-alt {
  &:before {
    content: $lnil-cup-alt; 
  }
}
.lnil-old-man {
  &:before {
    content: $lnil-old-man; 
  }
}
.lnil-gift-bag {
  &:before {
    content: $lnil-gift-bag; 
  }
}
.lnil-crown-alt {
  &:before {
    content: $lnil-crown-alt; 
  }
}
.lnil-gift-1 {
  &:before {
    content: $lnil-gift-1; 
  }
}
.lnil-gift {
  &:before {
    content: $lnil-gift; 
  }
}
.lnil-cotton-bud {
  &:before {
    content: $lnil-cotton-bud; 
  }
}
.lnil-t-shirt-alt-1 {
  &:before {
    content: $lnil-t-shirt-alt-1; 
  }
}
.lnil-cup {
  &:before {
    content: $lnil-cup; 
  }
}
.lnil-ladies-t-shirt {
  &:before {
    content: $lnil-ladies-t-shirt; 
  }
}
.lnil-family-alt {
  &:before {
    content: $lnil-family-alt; 
  }
}
.lnil-columns {
  &:before {
    content: $lnil-columns; 
  }
}
.lnil-grid {
  &:before {
    content: $lnil-grid; 
  }
}
.lnil-grid-alt {
  &:before {
    content: $lnil-grid-alt; 
  }
}
.lnil-pallet {
  &:before {
    content: $lnil-pallet; 
  }
}
.lnil-highlight-alt {
  &:before {
    content: $lnil-highlight-alt; 
  }
}
.lnil-layout-2 {
  &:before {
    content: $lnil-layout-2; 
  }
}
.lnil-layout-3 {
  &:before {
    content: $lnil-layout-3; 
  }
}
.lnil-spiral-tool {
  &:before {
    content: $lnil-spiral-tool; 
  }
}
.lnil-shape-builder-tool {
  &:before {
    content: $lnil-shape-builder-tool; 
  }
}
.lnil-layout-1 {
  &:before {
    content: $lnil-layout-1; 
  }
}
.lnil-highlight {
  &:before {
    content: $lnil-highlight; 
  }
}
.lnil-layers-alt-1 {
  &:before {
    content: $lnil-layers-alt-1; 
  }
}
.lnil-free-transform {
  &:before {
    content: $lnil-free-transform; 
  }
}
.lnil-color-palette {
  &:before {
    content: $lnil-color-palette; 
  }
}
.lnil-align-left-2 {
  &:before {
    content: $lnil-align-left-2; 
  }
}
.lnil-delete-anchor-point {
  &:before {
    content: $lnil-delete-anchor-point; 
  }
}
.lnil-rotate {
  &:before {
    content: $lnil-rotate; 
  }
}
.lnil-align-left-3 {
  &:before {
    content: $lnil-align-left-3; 
  }
}
.lnil-bottom {
  &:before {
    content: $lnil-bottom; 
  }
}
.lnil-crop {
  &:before {
    content: $lnil-crop; 
  }
}
.lnil-right-align {
  &:before {
    content: $lnil-right-align; 
  }
}
.lnil-vector {
  &:before {
    content: $lnil-vector; 
  }
}
.lnil-up-align-1 {
  &:before {
    content: $lnil-up-align-1; 
  }
}
.lnil-up-align-3 {
  &:before {
    content: $lnil-up-align-3; 
  }
}
.lnil-vector-alt {
  &:before {
    content: $lnil-vector-alt; 
  }
}
.lnil-transform {
  &:before {
    content: $lnil-transform; 
  }
}
.lnil-align-left-1 {
  &:before {
    content: $lnil-align-left-1; 
  }
}
.lnil-image-crop {
  &:before {
    content: $lnil-image-crop; 
  }
}
.lnil-move {
  &:before {
    content: $lnil-move; 
  }
}
.lnil-up-align-2 {
  &:before {
    content: $lnil-up-align-2; 
  }
}
.lnil-layout-alt {
  &:before {
    content: $lnil-layout-alt; 
  }
}
.lnil-layout-alt-2 {
  &:before {
    content: $lnil-layout-alt-2; 
  }
}
.lnil-header-1 {
  &:before {
    content: $lnil-header-1; 
  }
}
.lnil-brush {
  &:before {
    content: $lnil-brush; 
  }
}
.lnil-rounded-rectangle {
  &:before {
    content: $lnil-rounded-rectangle; 
  }
}
.lnil-ellipse {
  &:before {
    content: $lnil-ellipse; 
  }
}
.lnil-outer-align {
  &:before {
    content: $lnil-outer-align; 
  }
}
.lnil-magic-wand {
  &:before {
    content: $lnil-magic-wand; 
  }
}
.lnil-size {
  &:before {
    content: $lnil-size; 
  }
}
.lnil-layout-alt-1 {
  &:before {
    content: $lnil-layout-alt-1; 
  }
}
.lnil-layers {
  &:before {
    content: $lnil-layers; 
  }
}
.lnil-grid-1 {
  &:before {
    content: $lnil-grid-1; 
  }
}
.lnil-inner-align {
  &:before {
    content: $lnil-inner-align; 
  }
}
.lnil-sidebar-1 {
  &:before {
    content: $lnil-sidebar-1; 
  }
}
.lnil-down-align-1 {
  &:before {
    content: $lnil-down-align-1; 
  }
}
.lnil-grid-alt-1 {
  &:before {
    content: $lnil-grid-alt-1; 
  }
}
.lnil-down-align {
  &:before {
    content: $lnil-down-align; 
  }
}
.lnil-minus-front {
  &:before {
    content: $lnil-minus-front; 
  }
}
.lnil-poligon {
  &:before {
    content: $lnil-poligon; 
  }
}
.lnil-down-align-3 {
  &:before {
    content: $lnil-down-align-3; 
  }
}
.lnil-down-align-2 {
  &:before {
    content: $lnil-down-align-2; 
  }
}
.lnil-panel-1 {
  &:before {
    content: $lnil-panel-1; 
  }
}
.lnil-add-anchor-point {
  &:before {
    content: $lnil-add-anchor-point; 
  }
}
.lnil-align-right {
  &:before {
    content: $lnil-align-right; 
  }
}
.lnil-ux {
  &:before {
    content: $lnil-ux; 
  }
}
.lnil-panel-2 {
  &:before {
    content: $lnil-panel-2; 
  }
}
.lnil-brush-alt {
  &:before {
    content: $lnil-brush-alt; 
  }
}
.lnil-sidebar {
  &:before {
    content: $lnil-sidebar; 
  }
}
.lnil-rotate-alt {
  &:before {
    content: $lnil-rotate-alt; 
  }
}
.lnil-table {
  &:before {
    content: $lnil-table; 
  }
}
.lnil-panel-3 {
  &:before {
    content: $lnil-panel-3; 
  }
}
.lnil-vector-pen {
  &:before {
    content: $lnil-vector-pen; 
  }
}
.lnil-corner {
  &:before {
    content: $lnil-corner; 
  }
}
.lnil-columns-1 {
  &:before {
    content: $lnil-columns-1; 
  }
}
.lnil-arc {
  &:before {
    content: $lnil-arc; 
  }
}
.lnil-align-right-2 {
  &:before {
    content: $lnil-align-right-2; 
  }
}
.lnil-corners {
  &:before {
    content: $lnil-corners; 
  }
}
.lnil-up-align {
  &:before {
    content: $lnil-up-align; 
  }
}
.lnil-panel-4 {
  &:before {
    content: $lnil-panel-4; 
  }
}
.lnil-intersection {
  &:before {
    content: $lnil-intersection; 
  }
}
.lnil-resize {
  &:before {
    content: $lnil-resize; 
  }
}
.lnil-margin {
  &:before {
    content: $lnil-margin; 
  }
}
.lnil-columns-2 {
  &:before {
    content: $lnil-columns-2; 
  }
}
.lnil-panel-5 {
  &:before {
    content: $lnil-panel-5; 
  }
}
.lnil-align-right-1 {
  &:before {
    content: $lnil-align-right-1; 
  }
}
.lnil-select {
  &:before {
    content: $lnil-select; 
  }
}
.lnil-layers-alt {
  &:before {
    content: $lnil-layers-alt; 
  }
}
.lnil-crop-alt {
  &:before {
    content: $lnil-crop-alt; 
  }
}
.lnil-rows {
  &:before {
    content: $lnil-rows; 
  }
}
.lnil-rows-1 {
  &:before {
    content: $lnil-rows-1; 
  }
}
.lnil-center-align-1 {
  &:before {
    content: $lnil-center-align-1; 
  }
}
.lnil-center-align-3 {
  &:before {
    content: $lnil-center-align-3; 
  }
}
.lnil-rows-2 {
  &:before {
    content: $lnil-rows-2; 
  }
}
.lnil-center-align-2 {
  &:before {
    content: $lnil-center-align-2; 
  }
}
.lnil-panel {
  &:before {
    content: $lnil-panel; 
  }
}
.lnil-center-align-6 {
  &:before {
    content: $lnil-center-align-6; 
  }
}
.lnil-no-border {
  &:before {
    content: $lnil-no-border; 
  }
}
.lnil-bottom-1 {
  &:before {
    content: $lnil-bottom-1; 
  }
}
.lnil-header {
  &:before {
    content: $lnil-header; 
  }
}
.lnil-center-align {
  &:before {
    content: $lnil-center-align; 
  }
}
.lnil-center-align-5 {
  &:before {
    content: $lnil-center-align-5; 
  }
}
.lnil-slice {
  &:before {
    content: $lnil-slice; 
  }
}
.lnil-center-align-4 {
  &:before {
    content: $lnil-center-align-4; 
  }
}
.lnil-scaling {
  &:before {
    content: $lnil-scaling; 
  }
}
.lnil-layout {
  &:before {
    content: $lnil-layout; 
  }
}
.lnil-align-left {
  &:before {
    content: $lnil-align-left; 
  }
}
.lnil-passport {
  &:before {
    content: $lnil-passport; 
  }
}
.lnil-hand {
  &:before {
    content: $lnil-hand; 
  }
}
.lnil-checkmark {
  &:before {
    content: $lnil-checkmark; 
  }
}
.lnil-list {
  &:before {
    content: $lnil-list; 
  }
}
.lnil-lock-alt-1 {
  &:before {
    content: $lnil-lock-alt-1; 
  }
}
.lnil-round-box-minus {
  &:before {
    content: $lnil-round-box-minus; 
  }
}
.lnil-funnel-alt {
  &:before {
    content: $lnil-funnel-alt; 
  }
}
.lnil-list-alt {
  &:before {
    content: $lnil-list-alt; 
  }
}
.lnil-information {
  &:before {
    content: $lnil-information; 
  }
}
.lnil-funnel-alt-1 {
  &:before {
    content: $lnil-funnel-alt-1; 
  }
}
.lnil-pointer {
  &:before {
    content: $lnil-pointer; 
  }
}
.lnil-lock-alt-2 {
  &:before {
    content: $lnil-lock-alt-2; 
  }
}
.lnil-lock-alt-3 {
  &:before {
    content: $lnil-lock-alt-3; 
  }
}
.lnil-heart-plus {
  &:before {
    content: $lnil-heart-plus; 
  }
}
.lnil-hotel-sign {
  &:before {
    content: $lnil-hotel-sign; 
  }
}
.lnil-thumbs-down-alt {
  &:before {
    content: $lnil-thumbs-down-alt; 
  }
}
.lnil-circle {
  &:before {
    content: $lnil-circle; 
  }
}
.lnil-radio-button {
  &:before {
    content: $lnil-radio-button; 
  }
}
.lnil-funnel-alt-2 {
  &:before {
    content: $lnil-funnel-alt-2; 
  }
}
.lnil-music {
  &:before {
    content: $lnil-music; 
  }
}
.lnil-round-box-cross {
  &:before {
    content: $lnil-round-box-cross; 
  }
}
.lnil-ticket {
  &:before {
    content: $lnil-ticket; 
  }
}
.lnil-round-box-plus {
  &:before {
    content: $lnil-round-box-plus; 
  }
}
.lnil-sign-language-alt {
  &:before {
    content: $lnil-sign-language-alt; 
  }
}
.lnil-ticket-alt {
  &:before {
    content: $lnil-ticket-alt; 
  }
}
.lnil-question-circle {
  &:before {
    content: $lnil-question-circle; 
  }
}
.lnil-thumbs-up-alt {
  &:before {
    content: $lnil-thumbs-up-alt; 
  }
}
.lnil-lock {
  &:before {
    content: $lnil-lock; 
  }
}
.lnil-key {
  &:before {
    content: $lnil-key; 
  }
}
.lnil-more-alt {
  &:before {
    content: $lnil-more-alt; 
  }
}
.lnil-thumbs-down {
  &:before {
    content: $lnil-thumbs-down; 
  }
}
.lnil-shield {
  &:before {
    content: $lnil-shield; 
  }
}
.lnil-lifering {
  &:before {
    content: $lnil-lifering; 
  }
}
.lnil-bolt {
  &:before {
    content: $lnil-bolt; 
  }
}
.lnil-flag-alt {
  &:before {
    content: $lnil-flag-alt; 
  }
}
.lnil-funnel {
  &:before {
    content: $lnil-funnel; 
  }
}
.lnil-playlist {
  &:before {
    content: $lnil-playlist; 
  }
}
.lnil-flag {
  &:before {
    content: $lnil-flag; 
  }
}
.lnil-record {
  &:before {
    content: $lnil-record; 
  }
}
.lnil-plus {
  &:before {
    content: $lnil-plus; 
  }
}
.lnil-shield-alt-1-check {
  &:before {
    content: $lnil-shield-alt-1-check; 
  }
}
.lnil-music-note {
  &:before {
    content: $lnil-music-note; 
  }
}
.lnil-warning {
  &:before {
    content: $lnil-warning; 
  }
}
.lnil-infinite {
  &:before {
    content: $lnil-infinite; 
  }
}
.lnil-barrier {
  &:before {
    content: $lnil-barrier; 
  }
}
.lnil-close {
  &:before {
    content: $lnil-close; 
  }
}
.lnil-check-circle {
  &:before {
    content: $lnil-check-circle; 
  }
}
.lnil-heart-filled {
  &:before {
    content: $lnil-heart-filled; 
  }
}
.lnil-round-box-check {
  &:before {
    content: $lnil-round-box-check; 
  }
}
.lnil-passport-4 {
  &:before {
    content: $lnil-passport-4; 
  }
}
.lnil-thumbs-up {
  &:before {
    content: $lnil-thumbs-up; 
  }
}
.lnil-notification1 {
  &:before {
    content: $lnil-notification1; 
  }
}
.lnil-playlist-alt {
  &:before {
    content: $lnil-playlist-alt; 
  }
}
.lnil-lock-alt {
  &:before {
    content: $lnil-lock-alt; 
  }
}
.lnil-more-alt-1 {
  &:before {
    content: $lnil-more-alt-1; 
  }
}
.lnil-key-alt-2 {
  &:before {
    content: $lnil-key-alt-2; 
  }
}
.lnil-more {
  &:before {
    content: $lnil-more; 
  }
}
.lnil-key-alt-3 {
  &:before {
    content: $lnil-key-alt-3; 
  }
}
.lnil-circle-plus {
  &:before {
    content: $lnil-circle-plus; 
  }
}
.lnil-passport-2 {
  &:before {
    content: $lnil-passport-2; 
  }
}
.lnil-idea-alt {
  &:before {
    content: $lnil-idea-alt; 
  }
}
.lnil-key-alt-1 {
  &:before {
    content: $lnil-key-alt-1; 
  }
}
.lnil-passport-alt {
  &:before {
    content: $lnil-passport-alt; 
  }
}
.lnil-checkmark-circle {
  &:before {
    content: $lnil-checkmark-circle; 
  }
}
.lnil-unlock {
  &:before {
    content: $lnil-unlock; 
  }
}
.lnil-shield-alt-2-check {
  &:before {
    content: $lnil-shield-alt-2-check; 
  }
}
.lnil-passport-3 {
  &:before {
    content: $lnil-passport-3; 
  }
}
.lnil-key-alt {
  &:before {
    content: $lnil-key-alt; 
  }
}
.lnil-check-box {
  &:before {
    content: $lnil-check-box; 
  }
}
.lnil-teabag {
  &:before {
    content: $lnil-teabag; 
  }
}
.lnil-ticket-alt-2 {
  &:before {
    content: $lnil-ticket-alt-2; 
  }
}
.lnil-shield-alt-3 {
  &:before {
    content: $lnil-shield-alt-3; 
  }
}
.lnil-protection {
  &:before {
    content: $lnil-protection; 
  }
}
.lnil-eye {
  &:before {
    content: $lnil-eye; 
  }
}
.lnil-electricity {
  &:before {
    content: $lnil-electricity; 
  }
}
.lnil-power-switch {
  &:before {
    content: $lnil-power-switch; 
  }
}
.lnil-shield-alt-2 {
  &:before {
    content: $lnil-shield-alt-2; 
  }
}
.lnil-ticket-alt-3 {
  &:before {
    content: $lnil-ticket-alt-3; 
  }
}
.lnil-identity {
  &:before {
    content: $lnil-identity; 
  }
}
.lnil-list-alt-1 {
  &:before {
    content: $lnil-list-alt-1; 
  }
}
.lnil-thunderbolt {
  &:before {
    content: $lnil-thunderbolt; 
  }
}
.lnil-sort {
  &:before {
    content: $lnil-sort; 
  }
}
.lnil-ticket-alt-1 {
  &:before {
    content: $lnil-ticket-alt-1; 
  }
}
.lnil-switch {
  &:before {
    content: $lnil-switch; 
  }
}
.lnil-sign-language {
  &:before {
    content: $lnil-sign-language; 
  }
}
.lnil-shield-alt-1 {
  &:before {
    content: $lnil-shield-alt-1; 
  }
}
.lnil-bolt-alt {
  &:before {
    content: $lnil-bolt-alt; 
  }
}
.lnil-circle-minus {
  &:before {
    content: $lnil-circle-minus; 
  }
}
.lnil-help {
  &:before {
    content: $lnil-help; 
  }
}
.lnil-invention {
  &:before {
    content: $lnil-invention; 
  }
}
.lnil-cross-circle {
  &:before {
    content: $lnil-cross-circle; 
  }
}
.lnil-find {
  &:before {
    content: $lnil-find; 
  }
}
.lnil-minus {
  &:before {
    content: $lnil-minus; 
  }
}
.lnil-eye-alt {
  &:before {
    content: $lnil-eye-alt; 
  }
}
.lnil-shield-alt-3-check {
  &:before {
    content: $lnil-shield-alt-3-check; 
  }
}
.lnil-heart {
  &:before {
    content: $lnil-heart; 
  }
}
.lnil-calculator-paper {
  &:before {
    content: $lnil-calculator-paper; 
  }
}
.lnil-shopping-bag-15 {
  &:before {
    content: $lnil-shopping-bag-15; 
  }
}
.lnil-online-shopping {
  &:before {
    content: $lnil-online-shopping; 
  }
}
.lnil-shopping-cart-4 {
  &:before {
    content: $lnil-shopping-cart-4; 
  }
}
.lnil-dollar-bubble {
  &:before {
    content: $lnil-dollar-bubble; 
  }
}
.lnil-like {
  &:before {
    content: $lnil-like; 
  }
}
.lnil-commercial-machine {
  &:before {
    content: $lnil-commercial-machine; 
  }
}
.lnil-truck-secure {
  &:before {
    content: $lnil-truck-secure; 
  }
}
.lnil-pen-alt {
  &:before {
    content: $lnil-pen-alt; 
  }
}
.lnil-discount-label {
  &:before {
    content: $lnil-discount-label; 
  }
}
.lnil-air-mail {
  &:before {
    content: $lnil-air-mail; 
  }
}
.lnil-shopping-cart-5 {
  &:before {
    content: $lnil-shopping-cart-5; 
  }
}
.lnil-shopping-bag-14 {
  &:before {
    content: $lnil-shopping-bag-14; 
  }
}
.lnil-dollars-gift {
  &:before {
    content: $lnil-dollars-gift; 
  }
}
.lnil-shopping-bag-16 {
  &:before {
    content: $lnil-shopping-bag-16; 
  }
}
.lnil-ship-delivery-box {
  &:before {
    content: $lnil-ship-delivery-box; 
  }
}
.lnil-ship-star {
  &:before {
    content: $lnil-ship-star; 
  }
}
.lnil-shopping-cart-7 {
  &:before {
    content: $lnil-shopping-cart-7; 
  }
}
.lnil-boxes {
  &:before {
    content: $lnil-boxes; 
  }
}
.lnil-truck-up-arrow {
  &:before {
    content: $lnil-truck-up-arrow; 
  }
}
.lnil-remove-basket {
  &:before {
    content: $lnil-remove-basket; 
  }
}
.lnil-shopping-cart-6 {
  &:before {
    content: $lnil-shopping-cart-6; 
  }
}
.lnil-coupon-scissors {
  &:before {
    content: $lnil-coupon-scissors; 
  }
}
.lnil-truck-feedback {
  &:before {
    content: $lnil-truck-feedback; 
  }
}
.lnil-shopping-bag-17 {
  &:before {
    content: $lnil-shopping-bag-17; 
  }
}
.lnil-truck-private {
  &:before {
    content: $lnil-truck-private; 
  }
}
.lnil-package-box {
  &:before {
    content: $lnil-package-box; 
  }
}
.lnil-shopping-bag-13 {
  &:before {
    content: $lnil-shopping-bag-13; 
  }
}
.lnil-fast-delivery {
  &:before {
    content: $lnil-fast-delivery; 
  }
}
.lnil-truck-search {
  &:before {
    content: $lnil-truck-search; 
  }
}
.lnil-bar-code-search {
  &:before {
    content: $lnil-bar-code-search; 
  }
}
.lnil-dollar-bundle {
  &:before {
    content: $lnil-dollar-bundle; 
  }
}
.lnil-shopping-cart-2 {
  &:before {
    content: $lnil-shopping-cart-2; 
  }
}
.lnil-cargo-mover {
  &:before {
    content: $lnil-cargo-mover; 
  }
}
.lnil-ship-favourite {
  &:before {
    content: $lnil-ship-favourite; 
  }
}
.lnil-piggy-bank-1 {
  &:before {
    content: $lnil-piggy-bank-1; 
  }
}
.lnil-saving-pig {
  &:before {
    content: $lnil-saving-pig; 
  }
}
.lnil-label-off {
  &:before {
    content: $lnil-label-off; 
  }
}
.lnil-shopping-cart-3 {
  &:before {
    content: $lnil-shopping-cart-3; 
  }
}
.lnil-shopping-bag-12 {
  &:before {
    content: $lnil-shopping-bag-12; 
  }
}
.lnil-dollar-coin-stack {
  &:before {
    content: $lnil-dollar-coin-stack; 
  }
}
.lnil-ship-down-arrow {
  &:before {
    content: $lnil-ship-down-arrow; 
  }
}
.lnil-ship-locked {
  &:before {
    content: $lnil-ship-locked; 
  }
}
.lnil-dollar-monitor {
  &:before {
    content: $lnil-dollar-monitor; 
  }
}
.lnil-shopping-bag-10 {
  &:before {
    content: $lnil-shopping-bag-10; 
  }
}
.lnil-telephone-3 {
  &:before {
    content: $lnil-telephone-3; 
  }
}
.lnil-shopping-cart-1 {
  &:before {
    content: $lnil-shopping-cart-1; 
  }
}
.lnil-fuel {
  &:before {
    content: $lnil-fuel; 
  }
}
.lnil-piggy-bank-2 {
  &:before {
    content: $lnil-piggy-bank-2; 
  }
}
.lnil-box-percentage {
  &:before {
    content: $lnil-box-percentage; 
  }
}
.lnil-int-ecommerce {
  &:before {
    content: $lnil-int-ecommerce; 
  }
}
.lnil-truck-down-arrow {
  &:before {
    content: $lnil-truck-down-arrow; 
  }
}
.lnil-truck-blocked {
  &:before {
    content: $lnil-truck-blocked; 
  }
}
.lnil-box-open {
  &:before {
    content: $lnil-box-open; 
  }
}
.lnil-telephone-2 {
  &:before {
    content: $lnil-telephone-2; 
  }
}
.lnil-add-basket {
  &:before {
    content: $lnil-add-basket; 
  }
}
.lnil-shopping-bag-11 {
  &:before {
    content: $lnil-shopping-bag-11; 
  }
}
.lnil-box-3 {
  &:before {
    content: $lnil-box-3; 
  }
}
.lnil-ship-reload {
  &:before {
    content: $lnil-ship-reload; 
  }
}
.lnil-box-remove {
  &:before {
    content: $lnil-box-remove; 
  }
}
.lnil-piggy-bank {
  &:before {
    content: $lnil-piggy-bank; 
  }
}
.lnil-basket-2 {
  &:before {
    content: $lnil-basket-2; 
  }
}
.lnil-ship-international {
  &:before {
    content: $lnil-ship-international; 
  }
}
.lnil-truck-location {
  &:before {
    content: $lnil-truck-location; 
  }
}
.lnil-dislike {
  &:before {
    content: $lnil-dislike; 
  }
}
.lnil-bills {
  &:before {
    content: $lnil-bills; 
  }
}
.lnil-box-2 {
  &:before {
    content: $lnil-box-2; 
  }
}
.lnil-truck-star {
  &:before {
    content: $lnil-truck-star; 
  }
}
.lnil-ship {
  &:before {
    content: $lnil-ship; 
  }
}
.lnil-calculator-card {
  &:before {
    content: $lnil-calculator-card; 
  }
}
.lnil-mathmetics {
  &:before {
    content: $lnil-mathmetics; 
  }
}
.lnil-basket-1 {
  &:before {
    content: $lnil-basket-1; 
  }
}
.lnil-truck-global {
  &:before {
    content: $lnil-truck-global; 
  }
}
.lnil-delivery-truck-4 {
  &:before {
    content: $lnil-delivery-truck-4; 
  }
}
.lnil-medal-first {
  &:before {
    content: $lnil-medal-first; 
  }
}
.lnil-box-1 {
  &:before {
    content: $lnil-box-1; 
  }
}
.lnil-monitor-dollar-lock {
  &:before {
    content: $lnil-monitor-dollar-lock; 
  }
}
.lnil-shopping-bag-9 {
  &:before {
    content: $lnil-shopping-bag-9; 
  }
}
.lnil-box-5 {
  &:before {
    content: $lnil-box-5; 
  }
}
.lnil-shopping-cart-12 {
  &:before {
    content: $lnil-shopping-cart-12; 
  }
}
.lnil-delivery-truck-1 {
  &:before {
    content: $lnil-delivery-truck-1; 
  }
}
.lnil-credit-cards {
  &:before {
    content: $lnil-credit-cards; 
  }
}
.lnil-close-sign {
  &:before {
    content: $lnil-close-sign; 
  }
}
.lnil-credit-card-lock {
  &:before {
    content: $lnil-credit-card-lock; 
  }
}
.lnil-shield-alt {
  &:before {
    content: $lnil-shield-alt; 
  }
}
.lnil-price-tag {
  &:before {
    content: $lnil-price-tag; 
  }
}
.lnil-aeroplane-delivery {
  &:before {
    content: $lnil-aeroplane-delivery; 
  }
}
.lnil-newspaper {
  &:before {
    content: $lnil-newspaper; 
  }
}
.lnil-cube {
  &:before {
    content: $lnil-cube; 
  }
}
.lnil-ship-container {
  &:before {
    content: $lnil-ship-container; 
  }
}
.lnil-box-4 {
  &:before {
    content: $lnil-box-4; 
  }
}
.lnil-shopping-bag-8 {
  &:before {
    content: $lnil-shopping-bag-8; 
  }
}
.lnil-paid-sign {
  &:before {
    content: $lnil-paid-sign; 
  }
}
.lnil-truck-box {
  &:before {
    content: $lnil-truck-box; 
  }
}
.lnil-ship-check {
  &:before {
    content: $lnil-ship-check; 
  }
}
.lnil-box-6 {
  &:before {
    content: $lnil-box-6; 
  }
}
.lnil-dump-truck {
  &:before {
    content: $lnil-dump-truck; 
  }
}
.lnil-shopping-cart-11 {
  &:before {
    content: $lnil-shopping-cart-11; 
  }
}
.lnil-oil-tanker {
  &:before {
    content: $lnil-oil-tanker; 
  }
}
.lnil-box-10 {
  &:before {
    content: $lnil-box-10; 
  }
}
.lnil-open-sign {
  &:before {
    content: $lnil-open-sign; 
  }
}
.lnil-delivery-truck-2 {
  &:before {
    content: $lnil-delivery-truck-2; 
  }
}
.lnil-delivery-truck-3 {
  &:before {
    content: $lnil-delivery-truck-3; 
  }
}
.lnil-shopping-cart-10 {
  &:before {
    content: $lnil-shopping-cart-10; 
  }
}
.lnil-euro-coin-stack {
  &:before {
    content: $lnil-euro-coin-stack; 
  }
}
.lnil-box-7 {
  &:before {
    content: $lnil-box-7; 
  }
}
.lnil-truck-box-1 {
  &:before {
    content: $lnil-truck-box-1; 
  }
}
.lnil-shopping-bag-6 {
  &:before {
    content: $lnil-shopping-bag-6; 
  }
}
.lnil-coins {
  &:before {
    content: $lnil-coins; 
  }
}
.lnil-price-tag-1 {
  &:before {
    content: $lnil-price-tag-1; 
  }
}
.lnil-like-3 {
  &:before {
    content: $lnil-like-3; 
  }
}
.lnil-add-cart {
  &:before {
    content: $lnil-add-cart; 
  }
}
.lnil-percentage-ticket {
  &:before {
    content: $lnil-percentage-ticket; 
  }
}
.lnil-padlock {
  &:before {
    content: $lnil-padlock; 
  }
}
.lnil-truck-warning {
  &:before {
    content: $lnil-truck-warning; 
  }
}
.lnil-box-add {
  &:before {
    content: $lnil-box-add; 
  }
}
.lnil-dollar-symbol {
  &:before {
    content: $lnil-dollar-symbol; 
  }
}
.lnil-like-2 {
  &:before {
    content: $lnil-like-2; 
  }
}
.lnil-shopping-bag-7 {
  &:before {
    content: $lnil-shopping-bag-7; 
  }
}
.lnil-badge {
  &:before {
    content: $lnil-badge; 
  }
}
.lnil-box-9 {
  &:before {
    content: $lnil-box-9; 
  }
}
.lnil-shopping-bag-5 {
  &:before {
    content: $lnil-shopping-bag-5; 
  }
}
.lnil-trailer {
  &:before {
    content: $lnil-trailer; 
  }
}
.lnil-ship-thumbs-up {
  &:before {
    content: $lnil-ship-thumbs-up; 
  }
}
.lnil-percentage-alt {
  &:before {
    content: $lnil-percentage-alt; 
  }
}
.lnil-shopping-bag-4 {
  &:before {
    content: $lnil-shopping-bag-4; 
  }
}
.lnil-box-8 {
  &:before {
    content: $lnil-box-8; 
  }
}
.lnil-ship-unknown {
  &:before {
    content: $lnil-ship-unknown; 
  }
}
.lnil-truck-reload {
  &:before {
    content: $lnil-truck-reload; 
  }
}
.lnil-parcel-air {
  &:before {
    content: $lnil-parcel-air; 
  }
}
.lnil-express-shopping {
  &:before {
    content: $lnil-express-shopping; 
  }
}
.lnil-check-sign {
  &:before {
    content: $lnil-check-sign; 
  }
}
.lnil-label-new {
  &:before {
    content: $lnil-label-new; 
  }
}
.lnil-shopping-bag-1 {
  &:before {
    content: $lnil-shopping-bag-1; 
  }
}
.lnil-faq {
  &:before {
    content: $lnil-faq; 
  }
}
.lnil-shopping-bag-3 {
  &:before {
    content: $lnil-shopping-bag-3; 
  }
}
.lnil-cash {
  &:before {
    content: $lnil-cash; 
  }
}
.lnil-credit-card-delete {
  &:before {
    content: $lnil-credit-card-delete; 
  }
}
.lnil-hours-truck {
  &:before {
    content: $lnil-hours-truck; 
  }
}
.lnil-shopping-bag-2 {
  &:before {
    content: $lnil-shopping-bag-2; 
  }
}
.lnil-7 {
  &:before {
    content: $lnil-7; 
  }
}
.lnil-search-store {
  &:before {
    content: $lnil-search-store; 
  }
}
.lnil-ship-search {
  &:before {
    content: $lnil-ship-search; 
  }
}
.lnil-locked-window {
  &:before {
    content: $lnil-locked-window; 
  }
}
.lnil-ship-delivery-box-alt-1 {
  &:before {
    content: $lnil-ship-delivery-box-alt-1; 
  }
}
.lnil-shopping-mobile {
  &:before {
    content: $lnil-shopping-mobile; 
  }
}
.lnil-sign-pole {
  &:before {
    content: $lnil-sign-pole; 
  }
}
.lnil-ship-stop {
  &:before {
    content: $lnil-ship-stop; 
  }
}
.lnil-id-card {
  &:before {
    content: $lnil-id-card; 
  }
}
.lnil-truck-unknown {
  &:before {
    content: $lnil-truck-unknown; 
  }
}
.lnil-truck-timer {
  &:before {
    content: $lnil-truck-timer; 
  }
}
.lnil-box-cart {
  &:before {
    content: $lnil-box-cart; 
  }
}
.lnil-ship-delivery-box-alt {
  &:before {
    content: $lnil-ship-delivery-box-alt; 
  }
}
.lnil-delivery-truck {
  &:before {
    content: $lnil-delivery-truck; 
  }
}
.lnil-headphones-alt {
  &:before {
    content: $lnil-headphones-alt; 
  }
}
.lnil-ship-cargo {
  &:before {
    content: $lnil-ship-cargo; 
  }
}
.lnil-invoice-1 {
  &:before {
    content: $lnil-invoice-1; 
  }
}
.lnil-profits {
  &:before {
    content: $lnil-profits; 
  }
}
.lnil-remove-cart {
  &:before {
    content: $lnil-remove-cart; 
  }
}
.lnil-star-dollar {
  &:before {
    content: $lnil-star-dollar; 
  }
}
.lnil-money-security {
  &:before {
    content: $lnil-money-security; 
  }
}
.lnil-ship-secure {
  &:before {
    content: $lnil-ship-secure; 
  }
}
.lnil-secure-shopping {
  &:before {
    content: $lnil-secure-shopping; 
  }
}
.lnil-finger-shopping {
  &:before {
    content: $lnil-finger-shopping; 
  }
}
.lnil-bills-1 {
  &:before {
    content: $lnil-bills-1; 
  }
}
.lnil-truck-checked {
  &:before {
    content: $lnil-truck-checked; 
  }
}
.lnil-add-cart-2 {
  &:before {
    content: $lnil-add-cart-2; 
  }
}
.lnil-calculator {
  &:before {
    content: $lnil-calculator; 
  }
}
.lnil-money-bag {
  &:before {
    content: $lnil-money-bag; 
  }
}
.lnil-ship-timer {
  &:before {
    content: $lnil-ship-timer; 
  }
}
.lnil-shopping-cart-8 {
  &:before {
    content: $lnil-shopping-cart-8; 
  }
}
.lnil-int-commercial-mail {
  &:before {
    content: $lnil-int-commercial-mail; 
  }
}
.lnil-sale-sign {
  &:before {
    content: $lnil-sale-sign; 
  }
}
.lnil-ship-up-arrow {
  &:before {
    content: $lnil-ship-up-arrow; 
  }
}
.lnil-parachute-parcel {
  &:before {
    content: $lnil-parachute-parcel; 
  }
}
.lnil-dollar-settings {
  &:before {
    content: $lnil-dollar-settings; 
  }
}
.lnil-shopping-cart-9 {
  &:before {
    content: $lnil-shopping-cart-9; 
  }
}
.lnil-ship-warning {
  &:before {
    content: $lnil-ship-warning; 
  }
}
.lnil-add-cart-1 {
  &:before {
    content: $lnil-add-cart-1; 
  }
}
.lnil-comments-alt-1 {
  &:before {
    content: $lnil-comments-alt-1; 
  }
}
.lnil-chat-alt-3 {
  &:before {
    content: $lnil-chat-alt-3; 
  }
}
.lnil-chat-alt-1 {
  &:before {
    content: $lnil-chat-alt-1; 
  }
}
.lnil-chat-alt {
  &:before {
    content: $lnil-chat-alt; 
  }
}
.lnil-chat-alt-4 {
  &:before {
    content: $lnil-chat-alt-4; 
  }
}
.lnil-chat-alt-5 {
  &:before {
    content: $lnil-chat-alt-5; 
  }
}
.lnil-chat-alt-7 {
  &:before {
    content: $lnil-chat-alt-7; 
  }
}
.lnil-chat-check {
  &:before {
    content: $lnil-chat-check; 
  }
}
.lnil-chat-alt-6 {
  &:before {
    content: $lnil-chat-alt-6; 
  }
}
.lnil-chat-alt-12 {
  &:before {
    content: $lnil-chat-alt-12; 
  }
}
.lnil-chat-alt-8 {
  &:before {
    content: $lnil-chat-alt-8; 
  }
}
.lnil-bubble-alt {
  &:before {
    content: $lnil-bubble-alt; 
  }
}
.lnil-chat-alt-10 {
  &:before {
    content: $lnil-chat-alt-10; 
  }
}
.lnil-chat-alt-11 {
  &:before {
    content: $lnil-chat-alt-11; 
  }
}
.lnil-chat-alt-9 {
  &:before {
    content: $lnil-chat-alt-9; 
  }
}
.lnil-chat-delete {
  &:before {
    content: $lnil-chat-delete; 
  }
}
.lnil-chat-check-alt {
  &:before {
    content: $lnil-chat-check-alt; 
  }
}
.lnil-comments-alt {
  &:before {
    content: $lnil-comments-alt; 
  }
}
.lnil-chat-add-alt {
  &:before {
    content: $lnil-chat-add-alt; 
  }
}
.lnil-chat-remove {
  &:before {
    content: $lnil-chat-remove; 
  }
}
.lnil-chat-add {
  &:before {
    content: $lnil-chat-add; 
  }
}
.lnil-squares-1 {
  &:before {
    content: $lnil-squares-1; 
  }
}
.lnil-triangle-6 {
  &:before {
    content: $lnil-triangle-6; 
  }
}
.lnil-triangle-7 {
  &:before {
    content: $lnil-triangle-7; 
  }
}
.lnil-dots {
  &:before {
    content: $lnil-dots; 
  }
}
.lnil-triangle-5 {
  &:before {
    content: $lnil-triangle-5; 
  }
}
.lnil-triangle-4 {
  &:before {
    content: $lnil-triangle-4; 
  }
}
.lnil-squares-3 {
  &:before {
    content: $lnil-squares-3; 
  }
}
.lnil-circle1 {
  &:before {
    content: $lnil-circle1; 
  }
}
.lnil-hexagons-1 {
  &:before {
    content: $lnil-hexagons-1; 
  }
}
.lnil-triangle-3 {
  &:before {
    content: $lnil-triangle-3; 
  }
}
.lnil-squares {
  &:before {
    content: $lnil-squares; 
  }
}
.lnil-hexagons {
  &:before {
    content: $lnil-hexagons; 
  }
}
.lnil-triangle-9 {
  &:before {
    content: $lnil-triangle-9; 
  }
}
.lnil-triangle-8 {
  &:before {
    content: $lnil-triangle-8; 
  }
}
.lnil-rhombus {
  &:before {
    content: $lnil-rhombus; 
  }
}
.lnil-triangles {
  &:before {
    content: $lnil-triangles; 
  }
}
.lnil-bootstrap {
  &:before {
    content: $lnil-bootstrap; 
  }
}
.lnil-connectdevelop {
  &:before {
    content: $lnil-connectdevelop; 
  }
}
.lnil-medium {
  &:before {
    content: $lnil-medium; 
  }
}
.lnil-soundcloud-original {
  &:before {
    content: $lnil-soundcloud-original; 
  }
}
.lnil-telegram-original {
  &:before {
    content: $lnil-telegram-original; 
  }
}
.lnil-figma {
  &:before {
    content: $lnil-figma; 
  }
}
.lnil-imdb {
  &:before {
    content: $lnil-imdb; 
  }
}
.lnil-steam {
  &:before {
    content: $lnil-steam; 
  }
}
.lnil-tumblr {
  &:before {
    content: $lnil-tumblr; 
  }
}
.lnil-firefox-original {
  &:before {
    content: $lnil-firefox-original; 
  }
}
.lnil-dropbox {
  &:before {
    content: $lnil-dropbox; 
  }
}
.lnil-microsoft {
  &:before {
    content: $lnil-microsoft; 
  }
}
.lnil-squarespace {
  &:before {
    content: $lnil-squarespace; 
  }
}
.lnil-javascript {
  &:before {
    content: $lnil-javascript; 
  }
}
.lnil-cloudflare {
  &:before {
    content: $lnil-cloudflare; 
  }
}
.lnil-airbnb {
  &:before {
    content: $lnil-airbnb; 
  }
}
.lnil-drupal {
  &:before {
    content: $lnil-drupal; 
  }
}
.lnil-dev {
  &:before {
    content: $lnil-dev; 
  }
}
.lnil-dropbox-original {
  &:before {
    content: $lnil-dropbox-original; 
  }
}
.lnil-android {
  &:before {
    content: $lnil-android; 
  }
}
.lnil-adobe {
  &:before {
    content: $lnil-adobe; 
  }
}
.lnil-visa {
  &:before {
    content: $lnil-visa; 
  }
}
.lnil-aws {
  &:before {
    content: $lnil-aws; 
  }
}
.lnil-java {
  &:before {
    content: $lnil-java; 
  }
}
.lnil-twitter-original {
  &:before {
    content: $lnil-twitter-original; 
  }
}
.lnil-bitbucket {
  &:before {
    content: $lnil-bitbucket; 
  }
}
.lnil-creative-commons {
  &:before {
    content: $lnil-creative-commons; 
  }
}
.lnil-github {
  &:before {
    content: $lnil-github; 
  }
}
.lnil-google-drive {
  &:before {
    content: $lnil-google-drive; 
  }
}
.lnil-px {
  &:before {
    content: $lnil-px; 
  }
}
.lnil-facebook-oval {
  &:before {
    content: $lnil-facebook-oval; 
  }
}
.lnil-netlify {
  &:before {
    content: $lnil-netlify; 
  }
}
.lnil-joomla {
  &:before {
    content: $lnil-joomla; 
  }
}
.lnil-npm {
  &:before {
    content: $lnil-npm; 
  }
}
.lnil-instagram-filled {
  &:before {
    content: $lnil-instagram-filled; 
  }
}
.lnil-gatsby {
  &:before {
    content: $lnil-gatsby; 
  }
}
.lnil-ycombinator {
  &:before {
    content: $lnil-ycombinator; 
  }
}
.lnil-angular {
  &:before {
    content: $lnil-angular; 
  }
}
.lnil-vk {
  &:before {
    content: $lnil-vk; 
  }
}
.lnil-vimeo {
  &:before {
    content: $lnil-vimeo; 
  }
}
.lnil-hacker-news {
  &:before {
    content: $lnil-hacker-news; 
  }
}
.lnil-apple-music {
  &:before {
    content: $lnil-apple-music; 
  }
}
.lnil-instagram {
  &:before {
    content: $lnil-instagram; 
  }
}
.lnil-shopify {
  &:before {
    content: $lnil-shopify; 
  }
}
.lnil-microsoft-edge {
  &:before {
    content: $lnil-microsoft-edge; 
  }
}
.lnil-lineicons {
  &:before {
    content: $lnil-lineicons; 
  }
}
.lnil-edge {
  &:before {
    content: $lnil-edge; 
  }
}
.lnil-python {
  &:before {
    content: $lnil-python; 
  }
}
.lnil-docker {
  &:before {
    content: $lnil-docker; 
  }
}
.lnil-git {
  &:before {
    content: $lnil-git; 
  }
}
.lnil-blogger {
  &:before {
    content: $lnil-blogger; 
  }
}
.lnil-discover {
  &:before {
    content: $lnil-discover; 
  }
}
.lnil-mailchimp {
  &:before {
    content: $lnil-mailchimp; 
  }
}
.lnil-facebook-original {
  &:before {
    content: $lnil-facebook-original; 
  }
}
.lnil-envato {
  &:before {
    content: $lnil-envato; 
  }
}
.lnil-bitcoin {
  &:before {
    content: $lnil-bitcoin; 
  }
}
.lnil-instagram-original {
  &:before {
    content: $lnil-instagram-original; 
  }
}
.lnil-telegram {
  &:before {
    content: $lnil-telegram; 
  }
}
.lnil-markdown {
  &:before {
    content: $lnil-markdown; 
  }
}
.lnil-goodreads {
  &:before {
    content: $lnil-goodreads; 
  }
}
.lnil-drupal-original {
  &:before {
    content: $lnil-drupal-original; 
  }
}
.lnil-magento {
  &:before {
    content: $lnil-magento; 
  }
}
.lnil-diners-club {
  &:before {
    content: $lnil-diners-club; 
  }
}
.lnil-nodejs-alt {
  &:before {
    content: $lnil-nodejs-alt; 
  }
}
.lnil-spotify {
  &:before {
    content: $lnil-spotify; 
  }
}
.lnil-google-wallet {
  &:before {
    content: $lnil-google-wallet; 
  }
}
.lnil-nodejs {
  &:before {
    content: $lnil-nodejs; 
  }
}
.lnil-slideshare {
  &:before {
    content: $lnil-slideshare; 
  }
}
.lnil-snapchat {
  &:before {
    content: $lnil-snapchat; 
  }
}
.lnil-html5 {
  &:before {
    content: $lnil-html5; 
  }
}
.lnil-quora {
  &:before {
    content: $lnil-quora; 
  }
}
.lnil-slack-line {
  &:before {
    content: $lnil-slack-line; 
  }
}
.lnil-laravel {
  &:before {
    content: $lnil-laravel; 
  }
}
.lnil-wordpress-filled {
  &:before {
    content: $lnil-wordpress-filled; 
  }
}
.lnil-bluetooth-original {
  &:before {
    content: $lnil-bluetooth-original; 
  }
}
.lnil-ubuntu {
  &:before {
    content: $lnil-ubuntu; 
  }
}
.lnil-paypal {
  &:before {
    content: $lnil-paypal; 
  }
}
.lnil-facebook-line {
  &:before {
    content: $lnil-facebook-line; 
  }
}
.lnil-play-store {
  &:before {
    content: $lnil-play-store; 
  }
}
.lnil-unsplash {
  &:before {
    content: $lnil-unsplash; 
  }
}
.lnil-playstation {
  &:before {
    content: $lnil-playstation; 
  }
}
.lnil-facebook {
  &:before {
    content: $lnil-facebook; 
  }
}
.lnil-amazon-pay {
  &:before {
    content: $lnil-amazon-pay; 
  }
}
.lnil-amazon {
  &:before {
    content: $lnil-amazon; 
  }
}
.lnil-css-3 {
  &:before {
    content: $lnil-css-3; 
  }
}
.lnil-pinterest {
  &:before {
    content: $lnil-pinterest; 
  }
}
.lnil-mastercard {
  &:before {
    content: $lnil-mastercard; 
  }
}
.lnil-wechat {
  &:before {
    content: $lnil-wechat; 
  }
}
.lnil-whatsapp {
  &:before {
    content: $lnil-whatsapp; 
  }
}
.lnil-google {
  &:before {
    content: $lnil-google; 
  }
}
.lnil-chromecast {
  &:before {
    content: $lnil-chromecast; 
  }
}
.lnil-android-original {
  &:before {
    content: $lnil-android-original; 
  }
}
.lnil-line {
  &:before {
    content: $lnil-line; 
  }
}
.lnil-atlassian {
  &:before {
    content: $lnil-atlassian; 
  }
}
.lnil-php {
  &:before {
    content: $lnil-php; 
  }
}
.lnil-stripe {
  &:before {
    content: $lnil-stripe; 
  }
}
.lnil-apple-pay {
  &:before {
    content: $lnil-apple-pay; 
  }
}
.lnil-stackoverflow {
  &:before {
    content: $lnil-stackoverflow; 
  }
}
.lnil-amex {
  &:before {
    content: $lnil-amex; 
  }
}
.lnil-joomla-original {
  &:before {
    content: $lnil-joomla-original; 
  }
}
.lnil-azure {
  &:before {
    content: $lnil-azure; 
  }
}
.lnil-paypal-original {
  &:before {
    content: $lnil-paypal-original; 
  }
}
.lnil-playstore {
  &:before {
    content: $lnil-playstore; 
  }
}
.lnil-google-pay {
  &:before {
    content: $lnil-google-pay; 
  }
}
.lnil-twitch {
  &:before {
    content: $lnil-twitch; 
  }
}
.lnil-windows {
  &:before {
    content: $lnil-windows; 
  }
}
.lnil-btc {
  &:before {
    content: $lnil-btc; 
  }
}
.lnil-linkedin-original {
  &:before {
    content: $lnil-linkedin-original; 
  }
}
.lnil-youtube {
  &:before {
    content: $lnil-youtube; 
  }
}
.lnil-app-store {
  &:before {
    content: $lnil-app-store; 
  }
}
.lnil-github-original {
  &:before {
    content: $lnil-github-original; 
  }
}
.lnil-stumbleupon {
  &:before {
    content: $lnil-stumbleupon; 
  }
}
.lnil-jcb {
  &:before {
    content: $lnil-jcb; 
  }
}
.lnil-jsfiddle {
  &:before {
    content: $lnil-jsfiddle; 
  }
}
.lnil-yahoo {
  &:before {
    content: $lnil-yahoo; 
  }
}
.lnil-producthunt {
  &:before {
    content: $lnil-producthunt; 
  }
}
.lnil-sketch {
  &:before {
    content: $lnil-sketch; 
  }
}
.lnil-reddit {
  &:before {
    content: $lnil-reddit; 
  }
}
.lnil-angellist {
  &:before {
    content: $lnil-angellist; 
  }
}
.lnil-codepen {
  &:before {
    content: $lnil-codepen; 
  }
}
.lnil-dribbble {
  &:before {
    content: $lnil-dribbble; 
  }
}
.lnil-xbox {
  &:before {
    content: $lnil-xbox; 
  }
}
.lnil-trello {
  &:before {
    content: $lnil-trello; 
  }
}
.lnil-linkedin {
  &:before {
    content: $lnil-linkedin; 
  }
}
.lnil-react {
  &:before {
    content: $lnil-react; 
  }
}
.lnil-twitter {
  &:before {
    content: $lnil-twitter; 
  }
}
.lnil-patreon {
  &:before {
    content: $lnil-patreon; 
  }
}
.lnil-swift {
  &:before {
    content: $lnil-swift; 
  }
}
.lnil-discord {
  &:before {
    content: $lnil-discord; 
  }
}
.lnil-opera {
  &:before {
    content: $lnil-opera; 
  }
}
.lnil-facebook-filled {
  &:before {
    content: $lnil-facebook-filled; 
  }
}
.lnil-css3 {
  &:before {
    content: $lnil-css3; 
  }
}
.lnil-slack {
  &:before {
    content: $lnil-slack; 
  }
}
.lnil-soundcloud {
  &:before {
    content: $lnil-soundcloud; 
  }
}
.lnil-skype {
  &:before {
    content: $lnil-skype; 
  }
}
.lnil-cpanel {
  &:before {
    content: $lnil-cpanel; 
  }
}
.lnil-wordpress {
  &:before {
    content: $lnil-wordpress; 
  }
}
.lnil-behance-original {
  &:before {
    content: $lnil-behance-original; 
  }
}
.lnil-spotify-original {
  &:before {
    content: $lnil-spotify-original; 
  }
}
.lnil-facebook-messenger {
  &:before {
    content: $lnil-facebook-messenger; 
  }
}
.lnil-chrome {
  &:before {
    content: $lnil-chrome; 
  }
}
.lnil-apple {
  &:before {
    content: $lnil-apple; 
  }
}
.lnil-lineicons-alt {
  &:before {
    content: $lnil-lineicons-alt; 
  }
}
.lnil-digitalocean {
  &:before {
    content: $lnil-digitalocean; 
  }
}
.lnil-amazon-original {
  &:before {
    content: $lnil-amazon-original; 
  }
}
.lnil-tiktok {
  &:before {
    content: $lnil-tiktok; 
  }
}
.lnil-behance {
  &:before {
    content: $lnil-behance; 
  }
}
.lnil-firefox {
  &:before {
    content: $lnil-firefox; 
  }
}
.lnil-flickr {
  &:before {
    content: $lnil-flickr; 
  }
}
.lnil-twitter-filled {
  &:before {
    content: $lnil-twitter-filled; 
  }
}
.lnil-luggage {
  &:before {
    content: $lnil-luggage; 
  }
}
.lnil-bowtie {
  &:before {
    content: $lnil-bowtie; 
  }
}
.lnil-high-heels {
  &:before {
    content: $lnil-high-heels; 
  }
}
.lnil-home1 {
  &:before {
    content: $lnil-home1; 
  }
}
.lnil-candelabra {
  &:before {
    content: $lnil-candelabra; 
  }
}
.lnil-wedding-arch {
  &:before {
    content: $lnil-wedding-arch; 
  }
}
.lnil-microphone {
  &:before {
    content: $lnil-microphone; 
  }
}
.lnil-love-potion {
  &:before {
    content: $lnil-love-potion; 
  }
}
.lnil-love-alt {
  &:before {
    content: $lnil-love-alt; 
  }
}
.lnil-shopping-wedding {
  &:before {
    content: $lnil-shopping-wedding; 
  }
}
.lnil-wedding-dress {
  &:before {
    content: $lnil-wedding-dress; 
  }
}
.lnil-love-letter {
  &:before {
    content: $lnil-love-letter; 
  }
}
.lnil-clipboard {
  &:before {
    content: $lnil-clipboard; 
  }
}
.lnil-car-wedding {
  &:before {
    content: $lnil-car-wedding; 
  }
}
.lnil-location-wedding {
  &:before {
    content: $lnil-location-wedding; 
  }
}
.lnil-bell-wedding {
  &:before {
    content: $lnil-bell-wedding; 
  }
}
.lnil-bed-wedding {
  &:before {
    content: $lnil-bed-wedding; 
  }
}
.lnil-padlock-love {
  &:before {
    content: $lnil-padlock-love; 
  }
}
.lnil-wedding-video-camera {
  &:before {
    content: $lnil-wedding-video-camera; 
  }
}
.lnil-wedding-song {
  &:before {
    content: $lnil-wedding-song; 
  }
}
.lnil-toast {
  &:before {
    content: $lnil-toast; 
  }
}
.lnil-cupcake {
  &:before {
    content: $lnil-cupcake; 
  }
}
.lnil-invitation {
  &:before {
    content: $lnil-invitation; 
  }
}
.lnil-champagne {
  &:before {
    content: $lnil-champagne; 
  }
}
.lnil-love {
  &:before {
    content: $lnil-love; 
  }
}
.lnil-plate {
  &:before {
    content: $lnil-plate; 
  }
}
.lnil-cupid-alt {
  &:before {
    content: $lnil-cupid-alt; 
  }
}
.lnil-wedding-cake {
  &:before {
    content: $lnil-wedding-cake; 
  }
}
.lnil-necklace {
  &:before {
    content: $lnil-necklace; 
  }
}
.lnil-door-hanger {
  &:before {
    content: $lnil-door-hanger; 
  }
}
.lnil-diamond {
  &:before {
    content: $lnil-diamond; 
  }
}
.lnil-calendar-wedding {
  &:before {
    content: $lnil-calendar-wedding; 
  }
}
.lnil-video-wedding {
  &:before {
    content: $lnil-video-wedding; 
  }
}
.lnil-flower1 {
  &:before {
    content: $lnil-flower1; 
  }
}
.lnil-dove {
  &:before {
    content: $lnil-dove; 
  }
}
.lnil-gift1 {
  &:before {
    content: $lnil-gift1; 
  }
}
.lnil-cupid {
  &:before {
    content: $lnil-cupid; 
  }
}
.lnil-bouquet {
  &:before {
    content: $lnil-bouquet; 
  }
}
.lnil-wedding-shoe {
  &:before {
    content: $lnil-wedding-shoe; 
  }
}
.lnil-hearts {
  &:before {
    content: $lnil-hearts; 
  }
}
.lnil-balloons-wedding {
  &:before {
    content: $lnil-balloons-wedding; 
  }
}
.lnil-camera-wedding {
  &:before {
    content: $lnil-camera-wedding; 
  }
}
.lnil-kiss {
  &:before {
    content: $lnil-kiss; 
  }
}
.lnil-key-wedding {
  &:before {
    content: $lnil-key-wedding; 
  }
}
.lnil-ring {
  &:before {
    content: $lnil-ring; 
  }
}
.lnil-bow-tie {
  &:before {
    content: $lnil-bow-tie; 
  }
}
.lnil-waiter {
  &:before {
    content: $lnil-waiter; 
  }
}
.lnil-eraser {
  &:before {
    content: $lnil-eraser; 
  }
}
.lnil-school-bench {
  &:before {
    content: $lnil-school-bench; 
  }
}
.lnil-flask-alt {
  &:before {
    content: $lnil-flask-alt; 
  }
}
.lnil-lockers {
  &:before {
    content: $lnil-lockers; 
  }
}
.lnil-book-alt {
  &:before {
    content: $lnil-book-alt; 
  }
}
.lnil-certificate-alt {
  &:before {
    content: $lnil-certificate-alt; 
  }
}
.lnil-sketchbook {
  &:before {
    content: $lnil-sketchbook; 
  }
}
.lnil-world-2 {
  &:before {
    content: $lnil-world-2; 
  }
}
.lnil-h2o {
  &:before {
    content: $lnil-h2o; 
  }
}
.lnil-library {
  &:before {
    content: $lnil-library; 
  }
}
.lnil-quill {
  &:before {
    content: $lnil-quill; 
  }
}
.lnil-backpack {
  &:before {
    content: $lnil-backpack; 
  }
}
.lnil-whiteboard {
  &:before {
    content: $lnil-whiteboard; 
  }
}
.lnil-brackets {
  &:before {
    content: $lnil-brackets; 
  }
}
.lnil-books-alt {
  &:before {
    content: $lnil-books-alt; 
  }
}
.lnil-books {
  &:before {
    content: $lnil-books; 
  }
}
.lnil-clipboard1 {
  &:before {
    content: $lnil-clipboard1; 
  }
}
.lnil-certificate {
  &:before {
    content: $lnil-certificate; 
  }
}
.lnil-graduate-alt {
  &:before {
    content: $lnil-graduate-alt; 
  }
}
.lnil-ebook {
  &:before {
    content: $lnil-ebook; 
  }
}
.lnil-cubes {
  &:before {
    content: $lnil-cubes; 
  }
}
.lnil-telescope {
  &:before {
    content: $lnil-telescope; 
  }
}
.lnil-flask-alt-1 {
  &:before {
    content: $lnil-flask-alt-1; 
  }
}
.lnil-certificate-alt-1 {
  &:before {
    content: $lnil-certificate-alt-1; 
  }
}
.lnil-knowledge {
  &:before {
    content: $lnil-knowledge; 
  }
}
.lnil-flask-alt-2 {
  &:before {
    content: $lnil-flask-alt-2; 
  }
}
.lnil-book {
  &:before {
    content: $lnil-book; 
  }
}
.lnil-medal-alt {
  &:before {
    content: $lnil-medal-alt; 
  }
}
.lnil-whiteboard-alt-2 {
  &:before {
    content: $lnil-whiteboard-alt-2; 
  }
}
.lnil-sinusoid {
  &:before {
    content: $lnil-sinusoid; 
  }
}
.lnil-book-2 {
  &:before {
    content: $lnil-book-2; 
  }
}
.lnil-molecule-alt {
  &:before {
    content: $lnil-molecule-alt; 
  }
}
.lnil-flask {
  &:before {
    content: $lnil-flask; 
  }
}
.lnil-whiteboard-alt-1 {
  &:before {
    content: $lnil-whiteboard-alt-1; 
  }
}
.lnil-backpack-alt {
  &:before {
    content: $lnil-backpack-alt; 
  }
}
.lnil-world {
  &:before {
    content: $lnil-world; 
  }
}
.lnil-school-bus {
  &:before {
    content: $lnil-school-bus; 
  }
}
.lnil-whiteboard-alt {
  &:before {
    content: $lnil-whiteboard-alt; 
  }
}
.lnil-letterblocks {
  &:before {
    content: $lnil-letterblocks; 
  }
}
.lnil-classroom {
  &:before {
    content: $lnil-classroom; 
  }
}
.lnil-pencil-alt {
  &:before {
    content: $lnil-pencil-alt; 
  }
}
.lnil-molecule {
  &:before {
    content: $lnil-molecule; 
  }
}
.lnil-blackboard {
  &:before {
    content: $lnil-blackboard; 
  }
}
.lnil-write {
  &:before {
    content: $lnil-write; 
  }
}
.lnil-classroom-alt {
  &:before {
    content: $lnil-classroom-alt; 
  }
}
.lnil-world-alt {
  &:before {
    content: $lnil-world-alt; 
  }
}
.lnil-geometry {
  &:before {
    content: $lnil-geometry; 
  }
}
.lnil-compass {
  &:before {
    content: $lnil-compass; 
  }
}
.lnil-beta {
  &:before {
    content: $lnil-beta; 
  }
}
.lnil-pencil {
  &:before {
    content: $lnil-pencil; 
  }
}
.lnil-graduation {
  &:before {
    content: $lnil-graduation; 
  }
}
.lnil-school-bench-alt {
  &:before {
    content: $lnil-school-bench-alt; 
  }
}
.lnil-medal {
  &:before {
    content: $lnil-medal; 
  }
}
.lnil-graduate {
  &:before {
    content: $lnil-graduate; 
  }
}
.lnil-atom {
  &:before {
    content: $lnil-atom; 
  }
}
.lnil-investment {
  &:before {
    content: $lnil-investment; 
  }
}
.lnil-briefcase-alt {
  &:before {
    content: $lnil-briefcase-alt; 
  }
}
.lnil-target {
  &:before {
    content: $lnil-target; 
  }
}
.lnil-analytics-alt-1 {
  &:before {
    content: $lnil-analytics-alt-1; 
  }
}
.lnil-lni-lni-scale-machine {
  &:before {
    content: $lnil-lni-lni-scale-machine; 
  }
}
.lnil-flow-chart-alt {
  &:before {
    content: $lnil-flow-chart-alt; 
  }
}
.lnil-digital-currency {
  &:before {
    content: $lnil-digital-currency; 
  }
}
.lnil-pie-chart-alt {
  &:before {
    content: $lnil-pie-chart-alt; 
  }
}
.lnil-tag {
  &:before {
    content: $lnil-tag; 
  }
}
.lnil-briefcase {
  &:before {
    content: $lnil-briefcase; 
  }
}
.lnil-coin-dollar {
  &:before {
    content: $lnil-coin-dollar; 
  }
}
.lnil-exchange-alt {
  &:before {
    content: $lnil-exchange-alt; 
  }
}
.lnil-euro-coin {
  &:before {
    content: $lnil-euro-coin; 
  }
}
.lnil-quotation {
  &:before {
    content: $lnil-quotation; 
  }
}
.lnil-handshake-alt-1 {
  &:before {
    content: $lnil-handshake-alt-1; 
  }
}
.lnil-pie-chart-dollar {
  &:before {
    content: $lnil-pie-chart-dollar; 
  }
}
.lnil-cart-empty {
  &:before {
    content: $lnil-cart-empty; 
  }
}
.lnil-delivery {
  &:before {
    content: $lnil-delivery; 
  }
}
.lnil-stats-down {
  &:before {
    content: $lnil-stats-down; 
  }
}
.lnil-apartment {
  &:before {
    content: $lnil-apartment; 
  }
}
.lnil-cart-down {
  &:before {
    content: $lnil-cart-down; 
  }
}
.lnil-office-plan {
  &:before {
    content: $lnil-office-plan; 
  }
}
.lnil-cart {
  &:before {
    content: $lnil-cart; 
  }
}
.lnil-pin {
  &:before {
    content: $lnil-pin; 
  }
}
.lnil-invest-monitor {
  &:before {
    content: $lnil-invest-monitor; 
  }
}
.lnil-offer {
  &:before {
    content: $lnil-offer; 
  }
}
.lnil-desk {
  &:before {
    content: $lnil-desk; 
  }
}
.lnil-tablet-alt {
  &:before {
    content: $lnil-tablet-alt; 
  }
}
.lnil-seo-consulting {
  &:before {
    content: $lnil-seo-consulting; 
  }
}
.lnil-notepad {
  &:before {
    content: $lnil-notepad; 
  }
}
.lnil-grow {
  &:before {
    content: $lnil-grow; 
  }
}
.lnil-global {
  &:before {
    content: $lnil-global; 
  }
}
.lnil-pie-chart {
  &:before {
    content: $lnil-pie-chart; 
  }
}
.lnil-apartment-alt {
  &:before {
    content: $lnil-apartment-alt; 
  }
}
.lnil-pin-alt {
  &:before {
    content: $lnil-pin-alt; 
  }
}
.lnil-finance {
  &:before {
    content: $lnil-finance; 
  }
}
.lnil-paperclip {
  &:before {
    content: $lnil-paperclip; 
  }
}
.lnil-target-alt {
  &:before {
    content: $lnil-target-alt; 
  }
}
.lnil-agenda {
  &:before {
    content: $lnil-agenda; 
  }
}
.lnil-table1 {
  &:before {
    content: $lnil-table1; 
  }
}
.lnil-megaphone-alt {
  &:before {
    content: $lnil-megaphone-alt; 
  }
}
.lnil-balance-1 {
  &:before {
    content: $lnil-balance-1; 
  }
}
.lnil-target-achievement {
  &:before {
    content: $lnil-target-achievement; 
  }
}
.lnil-cart-alt {
  &:before {
    content: $lnil-cart-alt; 
  }
}
.lnil-swatches {
  &:before {
    content: $lnil-swatches; 
  }
}
.lnil-target-remove {
  &:before {
    content: $lnil-target-remove; 
  }
}
.lnil-tie {
  &:before {
    content: $lnil-tie; 
  }
}
.lnil-pie-report {
  &:before {
    content: $lnil-pie-report; 
  }
}
.lnil-hierchy-alt {
  &:before {
    content: $lnil-hierchy-alt; 
  }
}
.lnil-sales-report {
  &:before {
    content: $lnil-sales-report; 
  }
}
.lnil-megaphone-2 {
  &:before {
    content: $lnil-megaphone-2; 
  }
}
.lnil-handshake {
  &:before {
    content: $lnil-handshake; 
  }
}
.lnil-megaphone-1 {
  &:before {
    content: $lnil-megaphone-1; 
  }
}
.lnil-license {
  &:before {
    content: $lnil-license; 
  }
}
.lnil-balance {
  &:before {
    content: $lnil-balance; 
  }
}
.lnil-achievement {
  &:before {
    content: $lnil-achievement; 
  }
}
.lnil-stats-up {
  &:before {
    content: $lnil-stats-up; 
  }
}
.lnil-handshake-alt {
  &:before {
    content: $lnil-handshake-alt; 
  }
}
.lnil-cart-full {
  &:before {
    content: $lnil-cart-full; 
  }
}
.lnil-hierchy {
  &:before {
    content: $lnil-hierchy; 
  }
}
.lnil-target-revenue {
  &:before {
    content: $lnil-target-revenue; 
  }
}
.lnil-revenue {
  &:before {
    content: $lnil-revenue; 
  }
}
.lnil-target-alt-1 {
  &:before {
    content: $lnil-target-alt-1; 
  }
}
.lnil-hierchy-alt-1 {
  &:before {
    content: $lnil-hierchy-alt-1; 
  }
}
.lnil-chinese-yuan {
  &:before {
    content: $lnil-chinese-yuan; 
  }
}
.lnil-customer {
  &:before {
    content: $lnil-customer; 
  }
}
.lnil-stamp {
  &:before {
    content: $lnil-stamp; 
  }
}
.lnil-cool-1 {
  &:before {
    content: $lnil-cool-1; 
  }
}
.lnil-nervous {
  &:before {
    content: $lnil-nervous; 
  }
}
.lnil-cool-3 {
  &:before {
    content: $lnil-cool-3; 
  }
}
.lnil-cool-6 {
  &:before {
    content: $lnil-cool-6; 
  }
}
.lnil-muted-1 {
  &:before {
    content: $lnil-muted-1; 
  }
}
.lnil-sick-1 {
  &:before {
    content: $lnil-sick-1; 
  }
}
.lnil-cool-7 {
  &:before {
    content: $lnil-cool-7; 
  }
}
.lnil-cool-5 {
  &:before {
    content: $lnil-cool-5; 
  }
}
.lnil-yawn {
  &:before {
    content: $lnil-yawn; 
  }
}
.lnil-muted-3 {
  &:before {
    content: $lnil-muted-3; 
  }
}
.lnil-muted-2 {
  &:before {
    content: $lnil-muted-2; 
  }
}
.lnil-tounge {
  &:before {
    content: $lnil-tounge; 
  }
}
.lnil-cool-4 {
  &:before {
    content: $lnil-cool-4; 
  }
}
.lnil-cool-14 {
  &:before {
    content: $lnil-cool-14; 
  }
}
.lnil-sleep {
  &:before {
    content: $lnil-sleep; 
  }
}
.lnil-piss {
  &:before {
    content: $lnil-piss; 
  }
}
.lnil-sad {
  &:before {
    content: $lnil-sad; 
  }
}
.lnil-cool-15 {
  &:before {
    content: $lnil-cool-15; 
  }
}
.lnil-kiss-2 {
  &:before {
    content: $lnil-kiss-2; 
  }
}
.lnil-cry {
  &:before {
    content: $lnil-cry; 
  }
}
.lnil-cool-17 {
  &:before {
    content: $lnil-cool-17; 
  }
}
.lnil-cool-16 {
  &:before {
    content: $lnil-cool-16; 
  }
}
.lnil-cool-12 {
  &:before {
    content: $lnil-cool-12; 
  }
}
.lnil-cool-13 {
  &:before {
    content: $lnil-cool-13; 
  }
}
.lnil-cool-11 {
  &:before {
    content: $lnil-cool-11; 
  }
}
.lnil-suspect {
  &:before {
    content: $lnil-suspect; 
  }
}
.lnil-happy {
  &:before {
    content: $lnil-happy; 
  }
}
.lnil-cool-10 {
  &:before {
    content: $lnil-cool-10; 
  }
}
.lnil-happy-3 {
  &:before {
    content: $lnil-happy-3; 
  }
}
.lnil-super-cool {
  &:before {
    content: $lnil-super-cool; 
  }
}
.lnil-speechless {
  &:before {
    content: $lnil-speechless; 
  }
}
.lnil-smile {
  &:before {
    content: $lnil-smile; 
  }
}
.lnil-cool-20 {
  &:before {
    content: $lnil-cool-20; 
  }
}
.lnil-happy-2 {
  &:before {
    content: $lnil-happy-2; 
  }
}
.lnil-cute {
  &:before {
    content: $lnil-cute; 
  }
}
.lnil-crying-4 {
  &:before {
    content: $lnil-crying-4; 
  }
}
.lnil-wow {
  &:before {
    content: $lnil-wow; 
  }
}
.lnil-happy-1 {
  &:before {
    content: $lnil-happy-1; 
  }
}
.lnil-love1 {
  &:before {
    content: $lnil-love1; 
  }
}
.lnil-mute1 {
  &:before {
    content: $lnil-mute1; 
  }
}
.lnil-crying-1 {
  &:before {
    content: $lnil-crying-1; 
  }
}
.lnil-sick {
  &:before {
    content: $lnil-sick; 
  }
}
.lnil-loved-surprise {
  &:before {
    content: $lnil-loved-surprise; 
  }
}
.lnil-happy-4 {
  &:before {
    content: $lnil-happy-4; 
  }
}
.lnil-cool-18 {
  &:before {
    content: $lnil-cool-18; 
  }
}
.lnil-crying-2 {
  &:before {
    content: $lnil-crying-2; 
  }
}
.lnil-crying-3 {
  &:before {
    content: $lnil-crying-3; 
  }
}
.lnil-cool-19 {
  &:before {
    content: $lnil-cool-19; 
  }
}
.lnil-cool-9 {
  &:before {
    content: $lnil-cool-9; 
  }
}
.lnil-sad-2 {
  &:before {
    content: $lnil-sad-2; 
  }
}
.lnil-dead-3 {
  &:before {
    content: $lnil-dead-3; 
  }
}
.lnil-smile-1 {
  &:before {
    content: $lnil-smile-1; 
  }
}
.lnil-bored {
  &:before {
    content: $lnil-bored; 
  }
}
.lnil-dead-2 {
  &:before {
    content: $lnil-dead-2; 
  }
}
.lnil-sad-3 {
  &:before {
    content: $lnil-sad-3; 
  }
}
.lnil-cool-8 {
  &:before {
    content: $lnil-cool-8; 
  }
}
.lnil-super-happy {
  &:before {
    content: $lnil-super-happy; 
  }
}
.lnil-sad-1 {
  &:before {
    content: $lnil-sad-1; 
  }
}
.lnil-cool {
  &:before {
    content: $lnil-cool; 
  }
}
.lnil-dead-1 {
  &:before {
    content: $lnil-dead-1; 
  }
}
.lnil-cool-kiss {
  &:before {
    content: $lnil-cool-kiss; 
  }
}
.lnil-dead {
  &:before {
    content: $lnil-dead; 
  }
}
.lnil-friendly {
  &:before {
    content: $lnil-friendly; 
  }
}
.lnil-sad-4 {
  &:before {
    content: $lnil-sad-4; 
  }
}
.lnil-surprised-1 {
  &:before {
    content: $lnil-surprised-1; 
  }
}
.lnil-dead-5 {
  &:before {
    content: $lnil-dead-5; 
  }
}
.lnil-kiss1 {
  &:before {
    content: $lnil-kiss1; 
  }
}
.lnil-dead-4 {
  &:before {
    content: $lnil-dead-4; 
  }
}
.lnil-wink {
  &:before {
    content: $lnil-wink; 
  }
}
.lnil-sad-5 {
  &:before {
    content: $lnil-sad-5; 
  }
}
.lnil-happy-smile {
  &:before {
    content: $lnil-happy-smile; 
  }
}
.lnil-angry {
  &:before {
    content: $lnil-angry; 
  }
}
.lnil-bored-1 {
  &:before {
    content: $lnil-bored-1; 
  }
}
.lnil-dead-6 {
  &:before {
    content: $lnil-dead-6; 
  }
}
.lnil-meat {
  &:before {
    content: $lnil-meat; 
  }
}
.lnil-dinner {
  &:before {
    content: $lnil-dinner; 
  }
}
.lnil-chef-hat-alt-1 {
  &:before {
    content: $lnil-chef-hat-alt-1; 
  }
}
.lnil-cutlery {
  &:before {
    content: $lnil-cutlery; 
  }
}
.lnil-chicken-roast {
  &:before {
    content: $lnil-chicken-roast; 
  }
}
.lnil-pizza-alt {
  &:before {
    content: $lnil-pizza-alt; 
  }
}
.lnil-silverware {
  &:before {
    content: $lnil-silverware; 
  }
}
.lnil-chef-hat-alt {
  &:before {
    content: $lnil-chef-hat-alt; 
  }
}
.lnil-chef-hat-alt-3 {
  &:before {
    content: $lnil-chef-hat-alt-3; 
  }
}
.lnil-chef-hat-alt-2 {
  &:before {
    content: $lnil-chef-hat-alt-2; 
  }
}
.lnil-restaurant {
  &:before {
    content: $lnil-restaurant; 
  }
}
.lnil-ice-cream-alt-1 {
  &:before {
    content: $lnil-ice-cream-alt-1; 
  }
}
.lnil-chocolate-bar {
  &:before {
    content: $lnil-chocolate-bar; 
  }
}
.lnil-steak {
  &:before {
    content: $lnil-steak; 
  }
}
.lnil-spatula {
  &:before {
    content: $lnil-spatula; 
  }
}
.lnil-coffee-cup {
  &:before {
    content: $lnil-coffee-cup; 
  }
}
.lnil-restaurant-menu {
  &:before {
    content: $lnil-restaurant-menu; 
  }
}
.lnil-cocktail-alt {
  &:before {
    content: $lnil-cocktail-alt; 
  }
}
.lnil-chicken1 {
  &:before {
    content: $lnil-chicken1; 
  }
}
.lnil-coffee-machine {
  &:before {
    content: $lnil-coffee-machine; 
  }
}
.lnil-chilli-sauce {
  &:before {
    content: $lnil-chilli-sauce; 
  }
}
.lnil-service {
  &:before {
    content: $lnil-service; 
  }
}
.lnil-burger-alt {
  &:before {
    content: $lnil-burger-alt; 
  }
}
.lnil-banana-alt {
  &:before {
    content: $lnil-banana-alt; 
  }
}
.lnil-bread {
  &:before {
    content: $lnil-bread; 
  }
}
.lnil-milk-bottole {
  &:before {
    content: $lnil-milk-bottole; 
  }
}
.lnil-satay {
  &:before {
    content: $lnil-satay; 
  }
}
.lnil-drumstick {
  &:before {
    content: $lnil-drumstick; 
  }
}
.lnil-cocktail {
  &:before {
    content: $lnil-cocktail; 
  }
}
.lnil-hotdog {
  &:before {
    content: $lnil-hotdog; 
  }
}
.lnil-bowl {
  &:before {
    content: $lnil-bowl; 
  }
}
.lnil-ice-cream-alt {
  &:before {
    content: $lnil-ice-cream-alt; 
  }
}
.lnil-cutlery-alt-1 {
  &:before {
    content: $lnil-cutlery-alt-1; 
  }
}
.lnil-popcorn {
  &:before {
    content: $lnil-popcorn; 
  }
}
.lnil-soup {
  &:before {
    content: $lnil-soup; 
  }
}
.lnil-burger {
  &:before {
    content: $lnil-burger; 
  }
}
.lnil-juice {
  &:before {
    content: $lnil-juice; 
  }
}
.lnil-taco {
  &:before {
    content: $lnil-taco; 
  }
}
.lnil-noodles {
  &:before {
    content: $lnil-noodles; 
  }
}
.lnil-lolypop {
  &:before {
    content: $lnil-lolypop; 
  }
}
.lnil-wine-glass-1 {
  &:before {
    content: $lnil-wine-glass-1; 
  }
}
.lnil-cupcake1 {
  &:before {
    content: $lnil-cupcake1; 
  }
}
.lnil-restaurant-table {
  &:before {
    content: $lnil-restaurant-table; 
  }
}
.lnil-donut {
  &:before {
    content: $lnil-donut; 
  }
}
.lnil-cupcake-alt {
  &:before {
    content: $lnil-cupcake-alt; 
  }
}
.lnil-apple-alt {
  &:before {
    content: $lnil-apple-alt; 
  }
}
.lnil-wine-glass {
  &:before {
    content: $lnil-wine-glass; 
  }
}
.lnil-cutlery-alt {
  &:before {
    content: $lnil-cutlery-alt; 
  }
}
.lnil-plate1 {
  &:before {
    content: $lnil-plate1; 
  }
}
.lnil-burger-alt-1 {
  &:before {
    content: $lnil-burger-alt-1; 
  }
}
.lnil-toilet {
  &:before {
    content: $lnil-toilet; 
  }
}
.lnil-chef-hat {
  &:before {
    content: $lnil-chef-hat; 
  }
}
.lnil-pizza {
  &:before {
    content: $lnil-pizza; 
  }
}
.lnil-banana {
  &:before {
    content: $lnil-banana; 
  }
}
.lnil-service-alt {
  &:before {
    content: $lnil-service-alt; 
  }
}
.lnil-cocktail-alt-1 {
  &:before {
    content: $lnil-cocktail-alt-1; 
  }
}
.lnil-burger-alt-2 {
  &:before {
    content: $lnil-burger-alt-2; 
  }
}
.lnil-bowl-alt-1 {
  &:before {
    content: $lnil-bowl-alt-1; 
  }
}
.lnil-french-fries {
  &:before {
    content: $lnil-french-fries; 
  }
}
.lnil-chicken-leg {
  &:before {
    content: $lnil-chicken-leg; 
  }
}
.lnil-brocolli {
  &:before {
    content: $lnil-brocolli; 
  }
}
.lnil-bowl-alt {
  &:before {
    content: $lnil-bowl-alt; 
  }
}
.lnil-dinner-table {
  &:before {
    content: $lnil-dinner-table; 
  }
}
.lnil-avocado {
  &:before {
    content: $lnil-avocado; 
  }
}
.lnil-baguette {
  &:before {
    content: $lnil-baguette; 
  }
}
.lnil-fresh-juice {
  &:before {
    content: $lnil-fresh-juice; 
  }
}
.lnil-coffee-cup-alt {
  &:before {
    content: $lnil-coffee-cup-alt; 
  }
}
.lnil-ice-cream {
  &:before {
    content: $lnil-ice-cream; 
  }
}
.lnil-pancake {
  &:before {
    content: $lnil-pancake; 
  }
}
.lnil-orange {
  &:before {
    content: $lnil-orange; 
  }
}
.lnil-user {
  &:before {
    content: $lnil-user; 
  }
}
.lnil-user-reload {
  &:before {
    content: $lnil-user-reload; 
  }
}
.lnil-user-identification {
  &:before {
    content: $lnil-user-identification; 
  }
}
.lnil-personal-data {
  &:before {
    content: $lnil-personal-data; 
  }
}
.lnil-user-money {
  &:before {
    content: $lnil-user-money; 
  }
}
.lnil-user-globe {
  &:before {
    content: $lnil-user-globe; 
  }
}
.lnil-user-key {
  &:before {
    content: $lnil-user-key; 
  }
}
.lnil-add-group-alt {
  &:before {
    content: $lnil-add-group-alt; 
  }
}
.lnil-user-delete {
  &:before {
    content: $lnil-user-delete; 
  }
}
.lnil-user-block {
  &:before {
    content: $lnil-user-block; 
  }
}
.lnil-user-timer {
  &:before {
    content: $lnil-user-timer; 
  }
}
.lnil-user-lock {
  &:before {
    content: $lnil-user-lock; 
  }
}
.lnil-user-remove {
  &:before {
    content: $lnil-user-remove; 
  }
}
.lnil-team {
  &:before {
    content: $lnil-team; 
  }
}
.lnil-user-top-arrow {
  &:before {
    content: $lnil-user-top-arrow; 
  }
}
.lnil-user-star {
  &:before {
    content: $lnil-user-star; 
  }
}
.lnil-user-love {
  &:before {
    content: $lnil-user-love; 
  }
}
.lnil-add-group {
  &:before {
    content: $lnil-add-group; 
  }
}
.lnil-user-idea {
  &:before {
    content: $lnil-user-idea; 
  }
}
.lnil-user-edit {
  &:before {
    content: $lnil-user-edit; 
  }
}
.lnil-user-search {
  &:before {
    content: $lnil-user-search; 
  }
}
.lnil-remove-user {
  &:before {
    content: $lnil-remove-user; 
  }
}
.lnil-user-information {
  &:before {
    content: $lnil-user-information; 
  }
}
.lnil-add-user-alt {
  &:before {
    content: $lnil-add-user-alt; 
  }
}
.lnil-personal-file {
  &:before {
    content: $lnil-personal-file; 
  }
}
.lnil-user-settings {
  &:before {
    content: $lnil-user-settings; 
  }
}
.lnil-user-download {
  &:before {
    content: $lnil-user-download; 
  }
}
.lnil-add-user {
  &:before {
    content: $lnil-add-user; 
  }
}
.lnil-user-question {
  &:before {
    content: $lnil-user-question; 
  }
}
.lnil-remove-group {
  &:before {
    content: $lnil-remove-group; 
  }
}
.lnil-user-check {
  &:before {
    content: $lnil-user-check; 
  }
}
.lnil-friends {
  &:before {
    content: $lnil-friends; 
  }
}
.lnil-user-shield {
  &:before {
    content: $lnil-user-shield; 
  }
}
.lnil-shoe {
  &:before {
    content: $lnil-shoe; 
  }
}
.lnil-briefcase-3 {
  &:before {
    content: $lnil-briefcase-3; 
  }
}
.lnil-briefcase-2 {
  &:before {
    content: $lnil-briefcase-2; 
  }
}
.lnil-glasses-1 {
  &:before {
    content: $lnil-glasses-1; 
  }
}
.lnil-mitten {
  &:before {
    content: $lnil-mitten; 
  }
}
.lnil-purse-1 {
  &:before {
    content: $lnil-purse-1; 
  }
}
.lnil-briefcase-1 {
  &:before {
    content: $lnil-briefcase-1; 
  }
}
.lnil-handbag {
  &:before {
    content: $lnil-handbag; 
  }
}
.lnil-briefcase-5 {
  &:before {
    content: $lnil-briefcase-5; 
  }
}
.lnil-shirt {
  &:before {
    content: $lnil-shirt; 
  }
}
.lnil-skirt-1 {
  &:before {
    content: $lnil-skirt-1; 
  }
}
.lnil-briefcase1 {
  &:before {
    content: $lnil-briefcase1; 
  }
}
.lnil-bracelet {
  &:before {
    content: $lnil-bracelet; 
  }
}
.lnil-perfume {
  &:before {
    content: $lnil-perfume; 
  }
}
.lnil-wristwatch1 {
  &:before {
    content: $lnil-wristwatch1; 
  }
}
.lnil-shopping-bag {
  &:before {
    content: $lnil-shopping-bag; 
  }
}
.lnil-perfume-1 {
  &:before {
    content: $lnil-perfume-1; 
  }
}
.lnil-ring-1 {
  &:before {
    content: $lnil-ring-1; 
  }
}
.lnil-handbag-3 {
  &:before {
    content: $lnil-handbag-3; 
  }
}
.lnil-giftbox {
  &:before {
    content: $lnil-giftbox; 
  }
}
.lnil-nail-polish {
  &:before {
    content: $lnil-nail-polish; 
  }
}
.lnil-bag-1 {
  &:before {
    content: $lnil-bag-1; 
  }
}
.lnil-perfume-2 {
  &:before {
    content: $lnil-perfume-2; 
  }
}
.lnil-hair-curler {
  &:before {
    content: $lnil-hair-curler; 
  }
}
.lnil-hat {
  &:before {
    content: $lnil-hat; 
  }
}
.lnil-hair {
  &:before {
    content: $lnil-hair; 
  }
}
.lnil-handbag-4 {
  &:before {
    content: $lnil-handbag-4; 
  }
}
.lnil-handbag-5 {
  &:before {
    content: $lnil-handbag-5; 
  }
}
.lnil-handbag-7 {
  &:before {
    content: $lnil-handbag-7; 
  }
}
.lnil-handbag-6 {
  &:before {
    content: $lnil-handbag-6; 
  }
}
.lnil-hat-alt {
  &:before {
    content: $lnil-hat-alt; 
  }
}
.lnil-winter-hat {
  &:before {
    content: $lnil-winter-hat; 
  }
}
.lnil-nail-alt {
  &:before {
    content: $lnil-nail-alt; 
  }
}
.lnil-shirt-3 {
  &:before {
    content: $lnil-shirt-3; 
  }
}
.lnil-bag {
  &:before {
    content: $lnil-bag; 
  }
}
.lnil-skirt {
  &:before {
    content: $lnil-skirt; 
  }
}
.lnil-glasses1 {
  &:before {
    content: $lnil-glasses1; 
  }
}
.lnil-shirt-2 {
  &:before {
    content: $lnil-shirt-2; 
  }
}
.lnil-sock {
  &:before {
    content: $lnil-sock; 
  }
}
.lnil-nail-polish-1 {
  &:before {
    content: $lnil-nail-polish-1; 
  }
}
.lnil-blade {
  &:before {
    content: $lnil-blade; 
  }
}
.lnil-high-heel {
  &:before {
    content: $lnil-high-heel; 
  }
}
.lnil-shirt-1 {
  &:before {
    content: $lnil-shirt-1; 
  }
}
.lnil-nail-polish-2 {
  &:before {
    content: $lnil-nail-polish-2; 
  }
}
.lnil-dress {
  &:before {
    content: $lnil-dress; 
  }
}
.lnil-cream {
  &:before {
    content: $lnil-cream; 
  }
}
.lnil-high-heel-2 {
  &:before {
    content: $lnil-high-heel-2; 
  }
}
.lnil-cream-1 {
  &:before {
    content: $lnil-cream-1; 
  }
}
.lnil-gel {
  &:before {
    content: $lnil-gel; 
  }
}
.lnil-shirt-4 {
  &:before {
    content: $lnil-shirt-4; 
  }
}
.lnil-high-heel-3 {
  &:before {
    content: $lnil-high-heel-3; 
  }
}
.lnil-cream-2 {
  &:before {
    content: $lnil-cream-2; 
  }
}
.lnil-mackup-3 {
  &:before {
    content: $lnil-mackup-3; 
  }
}
.lnil-mackup-2 {
  &:before {
    content: $lnil-mackup-2; 
  }
}
.lnil-diadem {
  &:before {
    content: $lnil-diadem; 
  }
}
.lnil-necklace1 {
  &:before {
    content: $lnil-necklace1; 
  }
}
.lnil-tie1 {
  &:before {
    content: $lnil-tie1; 
  }
}
.lnil-hat-2 {
  &:before {
    content: $lnil-hat-2; 
  }
}
.lnil-mackup {
  &:before {
    content: $lnil-mackup; 
  }
}
.lnil-hanger {
  &:before {
    content: $lnil-hanger; 
  }
}
.lnil-comb-3 {
  &:before {
    content: $lnil-comb-3; 
  }
}
.lnil-comb-2 {
  &:before {
    content: $lnil-comb-2; 
  }
}
.lnil-gift2 {
  &:before {
    content: $lnil-gift2; 
  }
}
.lnil-hat-3 {
  &:before {
    content: $lnil-hat-3; 
  }
}
.lnil-purse-alt {
  &:before {
    content: $lnil-purse-alt; 
  }
}
.lnil-hat-1 {
  &:before {
    content: $lnil-hat-1; 
  }
}
.lnil-comb-1 {
  &:before {
    content: $lnil-comb-1; 
  }
}
.lnil-shorts {
  &:before {
    content: $lnil-shorts; 
  }
}
.lnil-hat-4 {
  &:before {
    content: $lnil-hat-4; 
  }
}
.lnil-dress-2 {
  &:before {
    content: $lnil-dress-2; 
  }
}
.lnil-bow-1 {
  &:before {
    content: $lnil-bow-1; 
  }
}
.lnil-hairdryer {
  &:before {
    content: $lnil-hairdryer; 
  }
}
.lnil-dress-3 {
  &:before {
    content: $lnil-dress-3; 
  }
}
.lnil-ring1 {
  &:before {
    content: $lnil-ring1; 
  }
}
.lnil-hat-5 {
  &:before {
    content: $lnil-hat-5; 
  }
}
.lnil-bow-tie1 {
  &:before {
    content: $lnil-bow-tie1; 
  }
}
.lnil-mirror {
  &:before {
    content: $lnil-mirror; 
  }
}
.lnil-dress-1 {
  &:before {
    content: $lnil-dress-1; 
  }
}
.lnil-nail {
  &:before {
    content: $lnil-nail; 
  }
}
.lnil-flip-flop {
  &:before {
    content: $lnil-flip-flop; 
  }
}
.lnil-triangle {
  &:before {
    content: $lnil-triangle; 
  }
}
.lnil-brackets-alt-1 {
  &:before {
    content: $lnil-brackets-alt-1; 
  }
}
.lnil-division {
  &:before {
    content: $lnil-division; 
  }
}
.lnil-pi {
  &:before {
    content: $lnil-pi; 
  }
}
.lnil-plus-minus-symbol {
  &:before {
    content: $lnil-plus-minus-symbol; 
  }
}
.lnil-brackets-alt {
  &:before {
    content: $lnil-brackets-alt; 
  }
}
.lnil-brackets1 {
  &:before {
    content: $lnil-brackets1; 
  }
}
.lnil-root-sign-alt {
  &:before {
    content: $lnil-root-sign-alt; 
  }
}
.lnil-abstract {
  &:before {
    content: $lnil-abstract; 
  }
}
.lnil-at {
  &:before {
    content: $lnil-at; 
  }
}
.lnil-parentheses {
  &:before {
    content: $lnil-parentheses; 
  }
}
.lnil-plus-minus-symbol-1 {
  &:before {
    content: $lnil-plus-minus-symbol-1; 
  }
}
.lnil-root-sign {
  &:before {
    content: $lnil-root-sign; 
  }
}
.lnil-root-sign-alt-1 {
  &:before {
    content: $lnil-root-sign-alt-1; 
  }
}
.lnil-parentheses-alt-2 {
  &:before {
    content: $lnil-parentheses-alt-2; 
  }
}
.lnil-plus-minus-symbol-alt {
  &:before {
    content: $lnil-plus-minus-symbol-alt; 
  }
}
.lnil-pi-alt-1 {
  &:before {
    content: $lnil-pi-alt-1; 
  }
}
.lnil-parentheses-alt-1 {
  &:before {
    content: $lnil-parentheses-alt-1; 
  }
}
.lnil-division-alt {
  &:before {
    content: $lnil-division-alt; 
  }
}
.lnil-abstract-1 {
  &:before {
    content: $lnil-abstract-1; 
  }
}
.lnil-division-alt-1 {
  &:before {
    content: $lnil-division-alt-1; 
  }
}
.lnil-asterisk {
  &:before {
    content: $lnil-asterisk; 
  }
}
.lnil-pi-alt {
  &:before {
    content: $lnil-pi-alt; 
  }
}
.lnil-spinner-11 {
  &:before {
    content: $lnil-spinner-11; 
  }
}
.lnil-spinner-10 {
  &:before {
    content: $lnil-spinner-10; 
  }
}
.lnil-hourglass {
  &:before {
    content: $lnil-hourglass; 
  }
}
.lnil-spinner-9 {
  &:before {
    content: $lnil-spinner-9; 
  }
}
.lnil-spinner-8 {
  &:before {
    content: $lnil-spinner-8; 
  }
}
.lnil-spinner-3 {
  &:before {
    content: $lnil-spinner-3; 
  }
}
.lnil-spinner-2 {
  &:before {
    content: $lnil-spinner-2; 
  }
}
.lnil-spinner-arrow {
  &:before {
    content: $lnil-spinner-arrow; 
  }
}
.lnil-spinner-1 {
  &:before {
    content: $lnil-spinner-1; 
  }
}
.lnil-spinner-5 {
  &:before {
    content: $lnil-spinner-5; 
  }
}
.lnil-spinner-4 {
  &:before {
    content: $lnil-spinner-4; 
  }
}
.lnil-spinner-6 {
  &:before {
    content: $lnil-spinner-6; 
  }
}
.lnil-spinner {
  &:before {
    content: $lnil-spinner; 
  }
}
.lnil-spinner-7 {
  &:before {
    content: $lnil-spinner-7; 
  }
}
.lnil-spinner-solid {
  &:before {
    content: $lnil-spinner-solid; 
  }
}
.lnil-desert-1 {
  &:before {
    content: $lnil-desert-1; 
  }
}
.lnil-forrest-alt-1 {
  &:before {
    content: $lnil-forrest-alt-1; 
  }
}
.lnil-mushroom-alt-1 {
  &:before {
    content: $lnil-mushroom-alt-1; 
  }
}
.lnil-cactus-alt {
  &:before {
    content: $lnil-cactus-alt; 
  }
}
.lnil-desert-2 {
  &:before {
    content: $lnil-desert-2; 
  }
}
.lnil-forrest-alt-2 {
  &:before {
    content: $lnil-forrest-alt-2; 
  }
}
.lnil-mushroom-alt-2 {
  &:before {
    content: $lnil-mushroom-alt-2; 
  }
}
.lnil-forrest-alt-3 {
  &:before {
    content: $lnil-forrest-alt-3; 
  }
}
.lnil-leaf-alt-2 {
  &:before {
    content: $lnil-leaf-alt-2; 
  }
}
.lnil-trees-alt-9 {
  &:before {
    content: $lnil-trees-alt-9; 
  }
}
.lnil-cactus {
  &:before {
    content: $lnil-cactus; 
  }
}
.lnil-mushroom-alt {
  &:before {
    content: $lnil-mushroom-alt; 
  }
}
.lnil-trees-alt-8 {
  &:before {
    content: $lnil-trees-alt-8; 
  }
}
.lnil-mushrooms-alt {
  &:before {
    content: $lnil-mushrooms-alt; 
  }
}
.lnil-leaf-alt-1 {
  &:before {
    content: $lnil-leaf-alt-1; 
  }
}
.lnil-tornado {
  &:before {
    content: $lnil-tornado; 
  }
}
.lnil-mashroom {
  &:before {
    content: $lnil-mashroom; 
  }
}
.lnil-island-alt {
  &:before {
    content: $lnil-island-alt; 
  }
}
.lnil-forrest {
  &:before {
    content: $lnil-forrest; 
  }
}
.lnil-island {
  &:before {
    content: $lnil-island; 
  }
}
.lnil-cactus-3 {
  &:before {
    content: $lnil-cactus-3; 
  }
}
.lnil-island-alt-1 {
  &:before {
    content: $lnil-island-alt-1; 
  }
}
.lnil-mushrooms {
  &:before {
    content: $lnil-mushrooms; 
  }
}
.lnil-cactus-alt-2 {
  &:before {
    content: $lnil-cactus-alt-2; 
  }
}
.lnil-mushrooms-alt-1 {
  &:before {
    content: $lnil-mushrooms-alt-1; 
  }
}
.lnil-cactus-2 {
  &:before {
    content: $lnil-cactus-2; 
  }
}
.lnil-island-alt-2 {
  &:before {
    content: $lnil-island-alt-2; 
  }
}
.lnil-mountains-4 {
  &:before {
    content: $lnil-mountains-4; 
  }
}
.lnil-island-alt-3 {
  &:before {
    content: $lnil-island-alt-3; 
  }
}
.lnil-tree-alt {
  &:before {
    content: $lnil-tree-alt; 
  }
}
.lnil-cactus-1 {
  &:before {
    content: $lnil-cactus-1; 
  }
}
.lnil-plant-pot-alt-1 {
  &:before {
    content: $lnil-plant-pot-alt-1; 
  }
}
.lnil-forrest-1 {
  &:before {
    content: $lnil-forrest-1; 
  }
}
.lnil-plant {
  &:before {
    content: $lnil-plant; 
  }
}
.lnil-mountains-1 {
  &:before {
    content: $lnil-mountains-1; 
  }
}
.lnil-nature-shield {
  &:before {
    content: $lnil-nature-shield; 
  }
}
.lnil-sprout-alt {
  &:before {
    content: $lnil-sprout-alt; 
  }
}
.lnil-trees-alt {
  &:before {
    content: $lnil-trees-alt; 
  }
}
.lnil-island-alt-4 {
  &:before {
    content: $lnil-island-alt-4; 
  }
}
.lnil-mountains-3 {
  &:before {
    content: $lnil-mountains-3; 
  }
}
.lnil-mountains-2 {
  &:before {
    content: $lnil-mountains-2; 
  }
}
.lnil-desert {
  &:before {
    content: $lnil-desert; 
  }
}
.lnil-hop {
  &:before {
    content: $lnil-hop; 
  }
}
.lnil-bug-alt {
  &:before {
    content: $lnil-bug-alt; 
  }
}
.lnil-leaf-alt {
  &:before {
    content: $lnil-leaf-alt; 
  }
}
.lnil-trees {
  &:before {
    content: $lnil-trees; 
  }
}
.lnil-leaves {
  &:before {
    content: $lnil-leaves; 
  }
}
.lnil-trees-alt-10 {
  &:before {
    content: $lnil-trees-alt-10; 
  }
}
.lnil-love-nature {
  &:before {
    content: $lnil-love-nature; 
  }
}
.lnil-leaf {
  &:before {
    content: $lnil-leaf; 
  }
}
.lnil-bug {
  &:before {
    content: $lnil-bug; 
  }
}
.lnil-flower-alt {
  &:before {
    content: $lnil-flower-alt; 
  }
}
.lnil-plant-pot-alt {
  &:before {
    content: $lnil-plant-pot-alt; 
  }
}
.lnil-trees-alt-6 {
  &:before {
    content: $lnil-trees-alt-6; 
  }
}
.lnil-tree-alt-3 {
  &:before {
    content: $lnil-tree-alt-3; 
  }
}
.lnil-flower2 {
  &:before {
    content: $lnil-flower2; 
  }
}
.lnil-sprout1 {
  &:before {
    content: $lnil-sprout1; 
  }
}
.lnil-trees-alt-7 {
  &:before {
    content: $lnil-trees-alt-7; 
  }
}
.lnil-trees-alt-5 {
  &:before {
    content: $lnil-trees-alt-5; 
  }
}
.lnil-tree {
  &:before {
    content: $lnil-tree; 
  }
}
.lnil-tree-alt-1 {
  &:before {
    content: $lnil-tree-alt-1; 
  }
}
.lnil-plant-alt {
  &:before {
    content: $lnil-plant-alt; 
  }
}
.lnil-trees-alt-4 {
  &:before {
    content: $lnil-trees-alt-4; 
  }
}
.lnil-mountain {
  &:before {
    content: $lnil-mountain; 
  }
}
.lnil-mountains {
  &:before {
    content: $lnil-mountains; 
  }
}
.lnil-trees-alt-1 {
  &:before {
    content: $lnil-trees-alt-1; 
  }
}
.lnil-plant-pot {
  &:before {
    content: $lnil-plant-pot; 
  }
}
.lnil-closet {
  &:before {
    content: $lnil-closet; 
  }
}
.lnil-lamp-1 {
  &:before {
    content: $lnil-lamp-1; 
  }
}
.lnil-bookshelf-1 {
  &:before {
    content: $lnil-bookshelf-1; 
  }
}
.lnil-bed {
  &:before {
    content: $lnil-bed; 
  }
}
.lnil-microwave {
  &:before {
    content: $lnil-microwave; 
  }
}
.lnil-lamp-2 {
  &:before {
    content: $lnil-lamp-2; 
  }
}
.lnil-bookshelf-3 {
  &:before {
    content: $lnil-bookshelf-3; 
  }
}
.lnil-bookshelf-2 {
  &:before {
    content: $lnil-bookshelf-2; 
  }
}
.lnil-home2 {
  &:before {
    content: $lnil-home2; 
  }
}
.lnil-dressing-table {
  &:before {
    content: $lnil-dressing-table; 
  }
}
.lnil-cradle {
  &:before {
    content: $lnil-cradle; 
  }
}
.lnil-cupboard {
  &:before {
    content: $lnil-cupboard; 
  }
}
.lnil-couch {
  &:before {
    content: $lnil-couch; 
  }
}
.lnil-bath-tub-1 {
  &:before {
    content: $lnil-bath-tub-1; 
  }
}
.lnil-wall-drawer {
  &:before {
    content: $lnil-wall-drawer; 
  }
}
.lnil-cabinet {
  &:before {
    content: $lnil-cabinet; 
  }
}
.lnil-air-conditioner {
  &:before {
    content: $lnil-air-conditioner; 
  }
}
.lnil-desk-1 {
  &:before {
    content: $lnil-desk-1; 
  }
}
.lnil-bed-4 {
  &:before {
    content: $lnil-bed-4; 
  }
}
.lnil-window-1 {
  &:before {
    content: $lnil-window-1; 
  }
}
.lnil-light-umbrella {
  &:before {
    content: $lnil-light-umbrella; 
  }
}
.lnil-elegant-table-1 {
  &:before {
    content: $lnil-elegant-table-1; 
  }
}
.lnil-bed-5 {
  &:before {
    content: $lnil-bed-5; 
  }
}
.lnil-table-fan {
  &:before {
    content: $lnil-table-fan; 
  }
}
.lnil-bed-7 {
  &:before {
    content: $lnil-bed-7; 
  }
}
.lnil-elegant-table-3 {
  &:before {
    content: $lnil-elegant-table-3; 
  }
}
.lnil-window-2 {
  &:before {
    content: $lnil-window-2; 
  }
}
.lnil-window-3 {
  &:before {
    content: $lnil-window-3; 
  }
}
.lnil-elegant-table-2 {
  &:before {
    content: $lnil-elegant-table-2; 
  }
}
.lnil-desk1 {
  &:before {
    content: $lnil-desk1; 
  }
}
.lnil-bed-6 {
  &:before {
    content: $lnil-bed-6; 
  }
}
.lnil-bed-2 {
  &:before {
    content: $lnil-bed-2; 
  }
}
.lnil-sofa-9 {
  &:before {
    content: $lnil-sofa-9; 
  }
}
.lnil-sofa-8 {
  &:before {
    content: $lnil-sofa-8; 
  }
}
.lnil-mirror-1 {
  &:before {
    content: $lnil-mirror-1; 
  }
}
.lnil-bed-3 {
  &:before {
    content: $lnil-bed-3; 
  }
}
.lnil-bed-1 {
  &:before {
    content: $lnil-bed-1; 
  }
}
.lnil-chest-of-drawers {
  &:before {
    content: $lnil-chest-of-drawers; 
  }
}
.lnil-elegant-table-4 {
  &:before {
    content: $lnil-elegant-table-4; 
  }
}
.lnil-hanger-1 {
  &:before {
    content: $lnil-hanger-1; 
  }
}
.lnil-office-cabinet {
  &:before {
    content: $lnil-office-cabinet; 
  }
}
.lnil-closet-4 {
  &:before {
    content: $lnil-closet-4; 
  }
}
.lnil-bath-tub {
  &:before {
    content: $lnil-bath-tub; 
  }
}
.lnil-chest-of-drawers-1 {
  &:before {
    content: $lnil-chest-of-drawers-1; 
  }
}
.lnil-bookshelf {
  &:before {
    content: $lnil-bookshelf; 
  }
}
.lnil-sofa-6 {
  &:before {
    content: $lnil-sofa-6; 
  }
}
.lnil-sofa-7 {
  &:before {
    content: $lnil-sofa-7; 
  }
}
.lnil-lamp {
  &:before {
    content: $lnil-lamp; 
  }
}
.lnil-closet-5 {
  &:before {
    content: $lnil-closet-5; 
  }
}
.lnil-oven {
  &:before {
    content: $lnil-oven; 
  }
}
.lnil-table-5 {
  &:before {
    content: $lnil-table-5; 
  }
}
.lnil-light-stand {
  &:before {
    content: $lnil-light-stand; 
  }
}
.lnil-table-lamp {
  &:before {
    content: $lnil-table-lamp; 
  }
}
.lnil-chest-of-drawers-2 {
  &:before {
    content: $lnil-chest-of-drawers-2; 
  }
}
.lnil-sofa-5 {
  &:before {
    content: $lnil-sofa-5; 
  }
}
.lnil-sofa-4 {
  &:before {
    content: $lnil-sofa-4; 
  }
}
.lnil-picture {
  &:before {
    content: $lnil-picture; 
  }
}
.lnil-chest-of-drawers-3 {
  &:before {
    content: $lnil-chest-of-drawers-3; 
  }
}
.lnil-office-desk {
  &:before {
    content: $lnil-office-desk; 
  }
}
.lnil-table-4 {
  &:before {
    content: $lnil-table-4; 
  }
}
.lnil-closet-2 {
  &:before {
    content: $lnil-closet-2; 
  }
}
.lnil-chest-of-drawers-7 {
  &:before {
    content: $lnil-chest-of-drawers-7; 
  }
}
.lnil-weight-machine {
  &:before {
    content: $lnil-weight-machine; 
  }
}
.lnil-wardrobe {
  &:before {
    content: $lnil-wardrobe; 
  }
}
.lnil-sofa-1 {
  &:before {
    content: $lnil-sofa-1; 
  }
}
.lnil-chest-of-drawers-6 {
  &:before {
    content: $lnil-chest-of-drawers-6; 
  }
}
.lnil-stairs {
  &:before {
    content: $lnil-stairs; 
  }
}
.lnil-table-1 {
  &:before {
    content: $lnil-table-1; 
  }
}
.lnil-closet-3 {
  &:before {
    content: $lnil-closet-3; 
  }
}
.lnil-closet-1 {
  &:before {
    content: $lnil-closet-1; 
  }
}
.lnil-table-3 {
  &:before {
    content: $lnil-table-3; 
  }
}
.lnil-chest-of-drawers-4 {
  &:before {
    content: $lnil-chest-of-drawers-4; 
  }
}
.lnil-sofa-3 {
  &:before {
    content: $lnil-sofa-3; 
  }
}
.lnil-sofa-2 {
  &:before {
    content: $lnil-sofa-2; 
  }
}
.lnil-juicer {
  &:before {
    content: $lnil-juicer; 
  }
}
.lnil-chest-of-drawers-5 {
  &:before {
    content: $lnil-chest-of-drawers-5; 
  }
}
.lnil-desk-chair {
  &:before {
    content: $lnil-desk-chair; 
  }
}
.lnil-iron-table {
  &:before {
    content: $lnil-iron-table; 
  }
}
.lnil-table-2 {
  &:before {
    content: $lnil-table-2; 
  }
}
.lnil-home-1 {
  &:before {
    content: $lnil-home-1; 
  }
}
.lnil-table-lamp-1 {
  &:before {
    content: $lnil-table-lamp-1; 
  }
}
.lnil-hanger1 {
  &:before {
    content: $lnil-hanger1; 
  }
}
.lnil-chair-3 {
  &:before {
    content: $lnil-chair-3; 
  }
}
.lnil-light-bulb {
  &:before {
    content: $lnil-light-bulb; 
  }
}
.lnil-table-lamp-2 {
  &:before {
    content: $lnil-table-lamp-2; 
  }
}
.lnil-stove {
  &:before {
    content: $lnil-stove; 
  }
}
.lnil-refrigerator {
  &:before {
    content: $lnil-refrigerator; 
  }
}
.lnil-chair-1 {
  &:before {
    content: $lnil-chair-1; 
  }
}
.lnil-window {
  &:before {
    content: $lnil-window; 
  }
}
.lnil-washing-machine {
  &:before {
    content: $lnil-washing-machine; 
  }
}
.lnil-chair {
  &:before {
    content: $lnil-chair; 
  }
}
.lnil-cabinet-1 {
  &:before {
    content: $lnil-cabinet-1; 
  }
}
.lnil-chair-4 {
  &:before {
    content: $lnil-chair-4; 
  }
}
.lnil-clean {
  &:before {
    content: $lnil-clean; 
  }
}
.lnil-fridge {
  &:before {
    content: $lnil-fridge; 
  }
}
.lnil-mirror1 {
  &:before {
    content: $lnil-mirror1; 
  }
}
.lnil-locker {
  &:before {
    content: $lnil-locker; 
  }
}
.lnil-prayer-rug {
  &:before {
    content: $lnil-prayer-rug; 
  }
}
.lnil-star-of-david-1 {
  &:before {
    content: $lnil-star-of-david-1; 
  }
}
.lnil-goblet {
  &:before {
    content: $lnil-goblet; 
  }
}
.lnil-christian-candle {
  &:before {
    content: $lnil-christian-candle; 
  }
}
.lnil-moshjid-location {
  &:before {
    content: $lnil-moshjid-location; 
  }
}
.lnil-church {
  &:before {
    content: $lnil-church; 
  }
}
.lnil-islamic-book-alt {
  &:before {
    content: $lnil-islamic-book-alt; 
  }
}
.lnil-star-of-david {
  &:before {
    content: $lnil-star-of-david; 
  }
}
.lnil-muslim-symbol {
  &:before {
    content: $lnil-muslim-symbol; 
  }
}
.lnil-zen {
  &:before {
    content: $lnil-zen; 
  }
}
.lnil-islamic-flag {
  &:before {
    content: $lnil-islamic-flag; 
  }
}
.lnil-islamic-book {
  &:before {
    content: $lnil-islamic-book; 
  }
}
.lnil-bible {
  &:before {
    content: $lnil-bible; 
  }
}
.lnil-bible-alt {
  &:before {
    content: $lnil-bible-alt; 
  }
}
.lnil-tombstone-1 {
  &:before {
    content: $lnil-tombstone-1; 
  }
}
.lnil-tombstone {
  &:before {
    content: $lnil-tombstone; 
  }
}
.lnil-torii-gate {
  &:before {
    content: $lnil-torii-gate; 
  }
}
.lnil-cross {
  &:before {
    content: $lnil-cross; 
  }
}
.lnil-islam {
  &:before {
    content: $lnil-islam; 
  }
}
.lnil-yin-yang {
  &:before {
    content: $lnil-yin-yang; 
  }
}
.lnil-ladder {
  &:before {
    content: $lnil-ladder; 
  }
}
.lnil-derrick-alt-2 {
  &:before {
    content: $lnil-derrick-alt-2; 
  }
}
.lnil-house {
  &:before {
    content: $lnil-house; 
  }
}
.lnil-fuel-truck {
  &:before {
    content: $lnil-fuel-truck; 
  }
}
.lnil-derrick-alt-3 {
  &:before {
    content: $lnil-derrick-alt-3; 
  }
}
.lnil-tools {
  &:before {
    content: $lnil-tools; 
  }
}
.lnil-building-alt {
  &:before {
    content: $lnil-building-alt; 
  }
}
.lnil-derrick-alt-1 {
  &:before {
    content: $lnil-derrick-alt-1; 
  }
}
.lnil-toolbox {
  &:before {
    content: $lnil-toolbox; 
  }
}
.lnil-measuring-tape {
  &:before {
    content: $lnil-measuring-tape; 
  }
}
.lnil-cutter {
  &:before {
    content: $lnil-cutter; 
  }
}
.lnil-door-alt {
  &:before {
    content: $lnil-door-alt; 
  }
}
.lnil-truck-alt {
  &:before {
    content: $lnil-truck-alt; 
  }
}
.lnil-cone {
  &:before {
    content: $lnil-cone; 
  }
}
.lnil-drilling {
  &:before {
    content: $lnil-drilling; 
  }
}
.lnil-hammer-alt {
  &:before {
    content: $lnil-hammer-alt; 
  }
}
.lnil-backhoe {
  &:before {
    content: $lnil-backhoe; 
  }
}
.lnil-trowel {
  &:before {
    content: $lnil-trowel; 
  }
}
.lnil-boundary {
  &:before {
    content: $lnil-boundary; 
  }
}
.lnil-derrick {
  &:before {
    content: $lnil-derrick; 
  }
}
.lnil-screw {
  &:before {
    content: $lnil-screw; 
  }
}
.lnil-hook {
  &:before {
    content: $lnil-hook; 
  }
}
.lnil-cone-alt {
  &:before {
    content: $lnil-cone-alt; 
  }
}
.lnil-paint-roller {
  &:before {
    content: $lnil-paint-roller; 
  }
}
.lnil-paint-bucket-alt {
  &:before {
    content: $lnil-paint-bucket-alt; 
  }
}
.lnil-house-plan {
  &:before {
    content: $lnil-house-plan; 
  }
}
.lnil-triangle-ruler {
  &:before {
    content: $lnil-triangle-ruler; 
  }
}
.lnil-bricks {
  &:before {
    content: $lnil-bricks; 
  }
}
.lnil-house-heart {
  &:before {
    content: $lnil-house-heart; 
  }
}
.lnil-magnet {
  &:before {
    content: $lnil-magnet; 
  }
}
.lnil-demolition {
  &:before {
    content: $lnil-demolition; 
  }
}
.lnil-derrick-alt {
  &:before {
    content: $lnil-derrick-alt; 
  }
}
.lnil-construction-hammer {
  &:before {
    content: $lnil-construction-hammer; 
  }
}
.lnil-ruler-alt-1 {
  &:before {
    content: $lnil-ruler-alt-1; 
  }
}
.lnil-trowel-alt {
  &:before {
    content: $lnil-trowel-alt; 
  }
}
.lnil-paint-roller-alt {
  &:before {
    content: $lnil-paint-roller-alt; 
  }
}
.lnil-ruler {
  &:before {
    content: $lnil-ruler; 
  }
}
.lnil-fence {
  &:before {
    content: $lnil-fence; 
  }
}
.lnil-saw {
  &:before {
    content: $lnil-saw; 
  }
}
.lnil-garbage-truck {
  &:before {
    content: $lnil-garbage-truck; 
  }
}
.lnil-truck-alt-1 {
  &:before {
    content: $lnil-truck-alt-1; 
  }
}
.lnil-house-plan-alt {
  &:before {
    content: $lnil-house-plan-alt; 
  }
}
.lnil-doors {
  &:before {
    content: $lnil-doors; 
  }
}
.lnil-construction-rular {
  &:before {
    content: $lnil-construction-rular; 
  }
}
.lnil-triangle-ruler-1 {
  &:before {
    content: $lnil-triangle-ruler-1; 
  }
}
.lnil-trowel-alt-1 {
  &:before {
    content: $lnil-trowel-alt-1; 
  }
}
.lnil-ruler-pencil {
  &:before {
    content: $lnil-ruler-pencil; 
  }
}
.lnil-bulldozer {
  &:before {
    content: $lnil-bulldozer; 
  }
}
.lnil-truck-load {
  &:before {
    content: $lnil-truck-load; 
  }
}
.lnil-broom {
  &:before {
    content: $lnil-broom; 
  }
}
.lnil-screw-alt {
  &:before {
    content: $lnil-screw-alt; 
  }
}
.lnil-house-plan-alt-4 {
  &:before {
    content: $lnil-house-plan-alt-4; 
  }
}
.lnil-cement-truck {
  &:before {
    content: $lnil-cement-truck; 
  }
}
.lnil-flags1 {
  &:before {
    content: $lnil-flags1; 
  }
}
.lnil-stairs1 {
  &:before {
    content: $lnil-stairs1; 
  }
}
.lnil-paint-roller-alt-1 {
  &:before {
    content: $lnil-paint-roller-alt-1; 
  }
}
.lnil-construction {
  &:before {
    content: $lnil-construction; 
  }
}
.lnil-house-plan-alt-1 {
  &:before {
    content: $lnil-house-plan-alt-1; 
  }
}
.lnil-stopcock {
  &:before {
    content: $lnil-stopcock; 
  }
}
.lnil-derricks {
  &:before {
    content: $lnil-derricks; 
  }
}
.lnil-wrench {
  &:before {
    content: $lnil-wrench; 
  }
}
.lnil-repair-tools {
  &:before {
    content: $lnil-repair-tools; 
  }
}
.lnil-hammer {
  &:before {
    content: $lnil-hammer; 
  }
}
.lnil-crane {
  &:before {
    content: $lnil-crane; 
  }
}
.lnil-pipe {
  &:before {
    content: $lnil-pipe; 
  }
}
.lnil-helmet-alt {
  &:before {
    content: $lnil-helmet-alt; 
  }
}
.lnil-shovel {
  &:before {
    content: $lnil-shovel; 
  }
}
.lnil-compass1 {
  &:before {
    content: $lnil-compass1; 
  }
}
.lnil-paint-roller-alt-2 {
  &:before {
    content: $lnil-paint-roller-alt-2; 
  }
}
.lnil-house-plan-alt-2 {
  &:before {
    content: $lnil-house-plan-alt-2; 
  }
}
.lnil-helmet {
  &:before {
    content: $lnil-helmet; 
  }
}
.lnil-building-alt-1 {
  &:before {
    content: $lnil-building-alt-1; 
  }
}
.lnil-brick-wall-alt {
  &:before {
    content: $lnil-brick-wall-alt; 
  }
}
.lnil-ruler-alt {
  &:before {
    content: $lnil-ruler-alt; 
  }
}
.lnil-trolley-cart {
  &:before {
    content: $lnil-trolley-cart; 
  }
}
.lnil-brick-wall {
  &:before {
    content: $lnil-brick-wall; 
  }
}
.lnil-axe {
  &:before {
    content: $lnil-axe; 
  }
}
.lnil-pulley {
  &:before {
    content: $lnil-pulley; 
  }
}
.lnil-door {
  &:before {
    content: $lnil-door; 
  }
}
.lnil-bricks-alt {
  &:before {
    content: $lnil-bricks-alt; 
  }
}
.lnil-drill {
  &:before {
    content: $lnil-drill; 
  }
}
.lnil-house-alt-2 {
  &:before {
    content: $lnil-house-alt-2; 
  }
}
.lnil-wheelbarrow-alt {
  &:before {
    content: $lnil-wheelbarrow-alt; 
  }
}
.lnil-screwdriver {
  &:before {
    content: $lnil-screwdriver; 
  }
}
.lnil-house-alt-1 {
  &:before {
    content: $lnil-house-alt-1; 
  }
}
.lnil-barrier-alt {
  &:before {
    content: $lnil-barrier-alt; 
  }
}
.lnil-paint-bucket {
  &:before {
    content: $lnil-paint-bucket; 
  }
}
.lnil-saw-alt {
  &:before {
    content: $lnil-saw-alt; 
  }
}
.lnil-wheelbarrow {
  &:before {
    content: $lnil-wheelbarrow; 
  }
}
.lnil-butterfly {
  &:before {
    content: $lnil-butterfly; 
  }
}
.lnil-gorilla {
  &:before {
    content: $lnil-gorilla; 
  }
}
.lnil-shark {
  &:before {
    content: $lnil-shark; 
  }
}
.lnil-cow1 {
  &:before {
    content: $lnil-cow1; 
  }
}
.lnil-chicken2 {
  &:before {
    content: $lnil-chicken2; 
  }
}
.lnil-camel {
  &:before {
    content: $lnil-camel; 
  }
}
.lnil-dolphin {
  &:before {
    content: $lnil-dolphin; 
  }
}
.lnil-rabbit {
  &:before {
    content: $lnil-rabbit; 
  }
}
.lnil-whale {
  &:before {
    content: $lnil-whale; 
  }
}
.lnil-crocodile {
  &:before {
    content: $lnil-crocodile; 
  }
}
.lnil-walrus {
  &:before {
    content: $lnil-walrus; 
  }
}
.lnil-koala {
  &:before {
    content: $lnil-koala; 
  }
}
.lnil-seal {
  &:before {
    content: $lnil-seal; 
  }
}
.lnil-bat {
  &:before {
    content: $lnil-bat; 
  }
}
.lnil-jellyfish {
  &:before {
    content: $lnil-jellyfish; 
  }
}
.lnil-frog {
  &:before {
    content: $lnil-frog; 
  }
}
.lnil-cat {
  &:before {
    content: $lnil-cat; 
  }
}
.lnil-badge-1 {
  &:before {
    content: $lnil-badge-1; 
  }
}
.lnil-prize-1 {
  &:before {
    content: $lnil-prize-1; 
  }
}
.lnil-badge-2 {
  &:before {
    content: $lnil-badge-2; 
  }
}
.lnil-badge-3 {
  &:before {
    content: $lnil-badge-3; 
  }
}
.lnil-badge-7 {
  &:before {
    content: $lnil-badge-7; 
  }
}
.lnil-badge-6 {
  &:before {
    content: $lnil-badge-6; 
  }
}
.lnil-diploma-2 {
  &:before {
    content: $lnil-diploma-2; 
  }
}
.lnil-badge-4 {
  &:before {
    content: $lnil-badge-4; 
  }
}
.lnil-badge-5 {
  &:before {
    content: $lnil-badge-5; 
  }
}
.lnil-diploma-1 {
  &:before {
    content: $lnil-diploma-1; 
  }
}
.lnil-medal-8 {
  &:before {
    content: $lnil-medal-8; 
  }
}
.lnil-trophy-5 {
  &:before {
    content: $lnil-trophy-5; 
  }
}
.lnil-diploma {
  &:before {
    content: $lnil-diploma; 
  }
}
.lnil-trophy-4 {
  &:before {
    content: $lnil-trophy-4; 
  }
}
.lnil-trophy-1 {
  &:before {
    content: $lnil-trophy-1; 
  }
}
.lnil-trophy-3 {
  &:before {
    content: $lnil-trophy-3; 
  }
}
.lnil-trophy-2 {
  &:before {
    content: $lnil-trophy-2; 
  }
}
.lnil-nd-prize {
  &:before {
    content: $lnil-nd-prize; 
  }
}
.lnil-medal-1 {
  &:before {
    content: $lnil-medal-1; 
  }
}
.lnil-badge1 {
  &:before {
    content: $lnil-badge1; 
  }
}
.lnil-medal-2 {
  &:before {
    content: $lnil-medal-2; 
  }
}
.lnil-crown1 {
  &:before {
    content: $lnil-crown1; 
  }
}
.lnil-medal-3 {
  &:before {
    content: $lnil-medal-3; 
  }
}
.lnil-medal-7 {
  &:before {
    content: $lnil-medal-7; 
  }
}
.lnil-medal-6 {
  &:before {
    content: $lnil-medal-6; 
  }
}
.lnil-medal-4 {
  &:before {
    content: $lnil-medal-4; 
  }
}
.lnil-badge-10 {
  &:before {
    content: $lnil-badge-10; 
  }
}
.lnil-medal-5 {
  &:before {
    content: $lnil-medal-5; 
  }
}
.lnil-badge-8 {
  &:before {
    content: $lnil-badge-8; 
  }
}
.lnil-badge-9 {
  &:before {
    content: $lnil-badge-9; 
  }
}
.lnil-patent {
  &:before {
    content: $lnil-patent; 
  }
}
.lnil-st-prize {
  &:before {
    content: $lnil-st-prize; 
  }
}
.lnil-rook {
  &:before {
    content: $lnil-rook; 
  }
}
.lnil-seo {
  &:before {
    content: $lnil-seo; 
  }
}
.lnil-bar-check {
  &:before {
    content: $lnil-bar-check; 
  }
}
.lnil-pie-chart-alt1 {
  &:before {
    content: $lnil-pie-chart-alt1; 
  }
}
.lnil-target-audience-alt {
  &:before {
    content: $lnil-target-audience-alt; 
  }
}
.lnil-strategy {
  &:before {
    content: $lnil-strategy; 
  }
}
.lnil-bar-chart-1 {
  &:before {
    content: $lnil-bar-chart-1; 
  }
}
.lnil-bar-chart {
  &:before {
    content: $lnil-bar-chart; 
  }
}
.lnil-graph-alt {
  &:before {
    content: $lnil-graph-alt; 
  }
}
.lnil-target-customer {
  &:before {
    content: $lnil-target-customer; 
  }
}
.lnil-graph-alt-4 {
  &:before {
    content: $lnil-graph-alt-4; 
  }
}
.lnil-keyword-research {
  &:before {
    content: $lnil-keyword-research; 
  }
}
.lnil-website-seo {
  &:before {
    content: $lnil-website-seo; 
  }
}
.lnil-graph-alt-3 {
  &:before {
    content: $lnil-graph-alt-3; 
  }
}
.lnil-billboard {
  &:before {
    content: $lnil-billboard; 
  }
}
.lnil-network {
  &:before {
    content: $lnil-network; 
  }
}
.lnil-website-rank {
  &:before {
    content: $lnil-website-rank; 
  }
}
.lnil-analytics-chat {
  &:before {
    content: $lnil-analytics-chat; 
  }
}
.lnil-graph-alt-2 {
  &:before {
    content: $lnil-graph-alt-2; 
  }
}
.lnil-pyramid {
  &:before {
    content: $lnil-pyramid; 
  }
}
.lnil-network-alt {
  &:before {
    content: $lnil-network-alt; 
  }
}
.lnil-billboard-alt {
  &:before {
    content: $lnil-billboard-alt; 
  }
}
.lnil-graph-alt-1 {
  &:before {
    content: $lnil-graph-alt-1; 
  }
}
.lnil-analytics {
  &:before {
    content: $lnil-analytics; 
  }
}
.lnil-graph-increase {
  &:before {
    content: $lnil-graph-increase; 
  }
}
.lnil-pyramid-decrease {
  &:before {
    content: $lnil-pyramid-decrease; 
  }
}
.lnil-hand-chart {
  &:before {
    content: $lnil-hand-chart; 
  }
}
.lnil-graph {
  &:before {
    content: $lnil-graph; 
  }
}
.lnil-pyramid-increase {
  &:before {
    content: $lnil-pyramid-increase; 
  }
}
.lnil-seo-alt {
  &:before {
    content: $lnil-seo-alt; 
  }
}
.lnil-graph-decrease {
  &:before {
    content: $lnil-graph-decrease; 
  }
}
.lnil-search {
  &:before {
    content: $lnil-search; 
  }
}
.lnil-alarm-2 {
  &:before {
    content: $lnil-alarm-2; 
  }
}
.lnil-trash-can-alt {
  &:before {
    content: $lnil-trash-can-alt; 
  }
}
.lnil-full-battery {
  &:before {
    content: $lnil-full-battery; 
  }
}
.lnil-vr-alt {
  &:before {
    content: $lnil-vr-alt; 
  }
}
.lnil-bulb {
  &:before {
    content: $lnil-bulb; 
  }
}
.lnil-display-alt {
  &:before {
    content: $lnil-display-alt; 
  }
}
.lnil-alarm-3 {
  &:before {
    content: $lnil-alarm-3; 
  }
}
.lnil-trash-can {
  &:before {
    content: $lnil-trash-can; 
  }
}
.lnil-cog {
  &:before {
    content: $lnil-cog; 
  }
}
.lnil-flash-drive-alt {
  &:before {
    content: $lnil-flash-drive-alt; 
  }
}
.lnil-laptop-thumbs-down {
  &:before {
    content: $lnil-laptop-thumbs-down; 
  }
}
.lnil-cloud-sync-alt {
  &:before {
    content: $lnil-cloud-sync-alt; 
  }
}
.lnil-file-name {
  &:before {
    content: $lnil-file-name; 
  }
}
.lnil-cloud-server-1 {
  &:before {
    content: $lnil-cloud-server-1; 
  }
}
.lnil-ai-alt {
  &:before {
    content: $lnil-ai-alt; 
  }
}
.lnil-cloud-download-1 {
  &:before {
    content: $lnil-cloud-download-1; 
  }
}
.lnil-user1 {
  &:before {
    content: $lnil-user1; 
  }
}
.lnil-tab {
  &:before {
    content: $lnil-tab; 
  }
}
.lnil-camera-alt-1 {
  &:before {
    content: $lnil-camera-alt-1; 
  }
}
.lnil-cloud-computing {
  &:before {
    content: $lnil-cloud-computing; 
  }
}
.lnil-recycle {
  &:before {
    content: $lnil-recycle; 
  }
}
.lnil-cloud-network-2 {
  &:before {
    content: $lnil-cloud-network-2; 
  }
}
.lnil-bulb-alt {
  &:before {
    content: $lnil-bulb-alt; 
  }
}
.lnil-alarm-4 {
  &:before {
    content: $lnil-alarm-4; 
  }
}
.lnil-cloud-check {
  &:before {
    content: $lnil-cloud-check; 
  }
}
.lnil-database-11 {
  &:before {
    content: $lnil-database-11; 
  }
}
.lnil-database-10 {
  &:before {
    content: $lnil-database-10; 
  }
}
.lnil-reload-browser {
  &:before {
    content: $lnil-reload-browser; 
  }
}
.lnil-code-alt {
  &:before {
    content: $lnil-code-alt; 
  }
}
.lnil-wifi-alt-1 {
  &:before {
    content: $lnil-wifi-alt-1; 
  }
}
.lnil-tap {
  &:before {
    content: $lnil-tap; 
  }
}
.lnil-laptop-alt-switch {
  &:before {
    content: $lnil-laptop-alt-switch; 
  }
}
.lnil-programmer {
  &:before {
    content: $lnil-programmer; 
  }
}
.lnil-presentation {
  &:before {
    content: $lnil-presentation; 
  }
}
.lnil-vector-node-1 {
  &:before {
    content: $lnil-vector-node-1; 
  }
}
.lnil-folder-alt-1 {
  &:before {
    content: $lnil-folder-alt-1; 
  }
}
.lnil-map {
  &:before {
    content: $lnil-map; 
  }
}
.lnil-microphone1 {
  &:before {
    content: $lnil-microphone1; 
  }
}
.lnil-laptop-alt {
  &:before {
    content: $lnil-laptop-alt; 
  }
}
.lnil-database {
  &:before {
    content: $lnil-database; 
  }
}
.lnil-change {
  &:before {
    content: $lnil-change; 
  }
}
.lnil-cellphone-vr {
  &:before {
    content: $lnil-cellphone-vr; 
  }
}
.lnil-cloud-upload-alt {
  &:before {
    content: $lnil-cloud-upload-alt; 
  }
}
.lnil-signal {
  &:before {
    content: $lnil-signal; 
  }
}
.lnil-virtual-reality-alt {
  &:before {
    content: $lnil-virtual-reality-alt; 
  }
}
.lnil-degree {
  &:before {
    content: $lnil-degree; 
  }
}
.lnil-files {
  &:before {
    content: $lnil-files; 
  }
}
.lnil-wifi-alt {
  &:before {
    content: $lnil-wifi-alt; 
  }
}
.lnil-wifi-alt-2 {
  &:before {
    content: $lnil-wifi-alt-2; 
  }
}
.lnil-internet-alt {
  &:before {
    content: $lnil-internet-alt; 
  }
}
.lnil-vr-monitor {
  &:before {
    content: $lnil-vr-monitor; 
  }
}
.lnil-website-browser {
  &:before {
    content: $lnil-website-browser; 
  }
}
.lnil-keyboard {
  &:before {
    content: $lnil-keyboard; 
  }
}
.lnil-empty-battery {
  &:before {
    content: $lnil-empty-battery; 
  }
}
.lnil-vr-monitor-1 {
  &:before {
    content: $lnil-vr-monitor-1; 
  }
}
.lnil-map-marker {
  &:before {
    content: $lnil-map-marker; 
  }
}
.lnil-zip {
  &:before {
    content: $lnil-zip; 
  }
}
.lnil-bookmark-alt {
  &:before {
    content: $lnil-bookmark-alt; 
  }
}
.lnil-cloud-upload {
  &:before {
    content: $lnil-cloud-upload; 
  }
}
.lnil-cloud-1 {
  &:before {
    content: $lnil-cloud-1; 
  }
}
.lnil-ban {
  &:before {
    content: $lnil-ban; 
  }
}
.lnil-live-stream {
  &:before {
    content: $lnil-live-stream; 
  }
}
.lnil-menu-alt {
  &:before {
    content: $lnil-menu-alt; 
  }
}
.lnil-networking {
  &:before {
    content: $lnil-networking; 
  }
}
.lnil-appointment {
  &:before {
    content: $lnil-appointment; 
  }
}
.lnil-website-alt {
  &:before {
    content: $lnil-website-alt; 
  }
}
.lnil-g-sim {
  &:before {
    content: $lnil-g-sim; 
  }
}
.lnil-star-fill {
  &:before {
    content: $lnil-star-fill; 
  }
}
.lnil-package {
  &:before {
    content: $lnil-package; 
  }
}
.lnil-vr-glasses {
  &:before {
    content: $lnil-vr-glasses; 
  }
}
.lnil-link {
  &:before {
    content: $lnil-link; 
  }
}
.lnil-database-4 {
  &:before {
    content: $lnil-database-4; 
  }
}
.lnil-mobile-alt-button {
  &:before {
    content: $lnil-mobile-alt-button; 
  }
}
.lnil-save-alt {
  &:before {
    content: $lnil-save-alt; 
  }
}
.lnil-database-down {
  &:before {
    content: $lnil-database-down; 
  }
}
.lnil-vr-monitor-2 {
  &:before {
    content: $lnil-vr-monitor-2; 
  }
}
.lnil-network-cloud {
  &:before {
    content: $lnil-network-cloud; 
  }
}
.lnil-website {
  &:before {
    content: $lnil-website; 
  }
}
.lnil-5g-alt-11 {
  &:before {
    content: $lnil-5g-alt-11; 
  }
}
.lnil-vector-node {
  &:before {
    content: $lnil-vector-node; 
  }
}
.lnil-vr-laptop {
  &:before {
    content: $lnil-vr-laptop; 
  }
}
.lnil-vr-motion {
  &:before {
    content: $lnil-vr-motion; 
  }
}
.lnil-database-6 {
  &:before {
    content: $lnil-database-6; 
  }
}
.lnil-shortcode {
  &:before {
    content: $lnil-shortcode; 
  }
}
.lnil-menu-alt-4 {
  &:before {
    content: $lnil-menu-alt-4; 
  }
}
.lnil-game {
  &:before {
    content: $lnil-game; 
  }
}
.lnil-archive {
  &:before {
    content: $lnil-archive; 
  }
}
.lnil-mobile-application-alt {
  &:before {
    content: $lnil-mobile-application-alt; 
  }
}
.lnil-magnet1 {
  &:before {
    content: $lnil-magnet1; 
  }
}
.lnil-recycle-alt {
  &:before {
    content: $lnil-recycle-alt; 
  }
}
.lnil-headphone-alt {
  &:before {
    content: $lnil-headphone-alt; 
  }
}
.lnil-download {
  &:before {
    content: $lnil-download; 
  }
}
.lnil-rotation {
  &:before {
    content: $lnil-rotation; 
  }
}
.lnil-menu-alt-5 {
  &:before {
    content: $lnil-menu-alt-5; 
  }
}
.lnil-cloud-3 {
  &:before {
    content: $lnil-cloud-3; 
  }
}
.lnil-database-7 {
  &:before {
    content: $lnil-database-7; 
  }
}
.lnil-link-alt-1 {
  &:before {
    content: $lnil-link-alt-1; 
  }
}
.lnil-calender-alt {
  &:before {
    content: $lnil-calender-alt; 
  }
}
.lnil-server {
  &:before {
    content: $lnil-server; 
  }
}
.lnil-tv {
  &:before {
    content: $lnil-tv; 
  }
}
.lnil-scroll-down {
  &:before {
    content: $lnil-scroll-down; 
  }
}
.lnil-folder-networking-1 {
  &:before {
    content: $lnil-folder-networking-1; 
  }
}
.lnil-server-8 {
  &:before {
    content: $lnil-server-8; 
  }
}
.lnil-database-3 {
  &:before {
    content: $lnil-database-3; 
  }
}
.lnil-bot {
  &:before {
    content: $lnil-bot; 
  }
}
.lnil-plug {
  &:before {
    content: $lnil-plug; 
  }
}
.lnil-menu-alt-1 {
  &:before {
    content: $lnil-menu-alt-1; 
  }
}
.lnil-cloud-network {
  &:before {
    content: $lnil-cloud-network; 
  }
}
.lnil-dashboard {
  &:before {
    content: $lnil-dashboard; 
  }
}
.lnil-code {
  &:before {
    content: $lnil-code; 
  }
}
.lnil-reload-alt-box {
  &:before {
    content: $lnil-reload-alt-box; 
  }
}
.lnil-database-2 {
  &:before {
    content: $lnil-database-2; 
  }
}
.lnil-battery {
  &:before {
    content: $lnil-battery; 
  }
}
.lnil-files-alt {
  &:before {
    content: $lnil-files-alt; 
  }
}
.lnil-server-9 {
  &:before {
    content: $lnil-server-9; 
  }
}
.lnil-mobile-alt-rounded-bottom {
  &:before {
    content: $lnil-mobile-alt-rounded-bottom; 
  }
}
.lnil-star-half {
  &:before {
    content: $lnil-star-half; 
  }
}
.lnil-vr-bug {
  &:before {
    content: $lnil-vr-bug; 
  }
}
.lnil-folder-alt {
  &:before {
    content: $lnil-folder-alt; 
  }
}
.lnil-cpu {
  &:before {
    content: $lnil-cpu; 
  }
}
.lnil-harddrive {
  &:before {
    content: $lnil-harddrive; 
  }
}
.lnil-laptop-alt-keyboard {
  &:before {
    content: $lnil-laptop-alt-keyboard; 
  }
}
.lnil-folder-networking-2 {
  &:before {
    content: $lnil-folder-networking-2; 
  }
}
.lnil-server-19 {
  &:before {
    content: $lnil-server-19; 
  }
}
.lnil-share-alt {
  &:before {
    content: $lnil-share-alt; 
  }
}
.lnil-block-user {
  &:before {
    content: $lnil-block-user; 
  }
}
.lnil-menu-alt-2 {
  &:before {
    content: $lnil-menu-alt-2; 
  }
}
.lnil-bluetooth {
  &:before {
    content: $lnil-bluetooth; 
  }
}
.lnil-file-protection {
  &:before {
    content: $lnil-file-protection; 
  }
}
.lnil-live-stream-alt {
  &:before {
    content: $lnil-live-stream-alt; 
  }
}
.lnil-cloud-check-alt {
  &:before {
    content: $lnil-cloud-check-alt; 
  }
}
.lnil-wifi {
  &:before {
    content: $lnil-wifi; 
  }
}
.lnil-menu-alt-3 {
  &:before {
    content: $lnil-menu-alt-3; 
  }
}
.lnil-database-1 {
  &:before {
    content: $lnil-database-1; 
  }
}
.lnil-server-18 {
  &:before {
    content: $lnil-server-18; 
  }
}
.lnil-domain {
  &:before {
    content: $lnil-domain; 
  }
}
.lnil-cellphone-vr-1 {
  &:before {
    content: $lnil-cellphone-vr-1; 
  }
}
.lnil-vr-browser {
  &:before {
    content: $lnil-vr-browser; 
  }
}
.lnil-search-alt {
  &:before {
    content: $lnil-search-alt; 
  }
}
.lnil-server-7 {
  &:before {
    content: $lnil-server-7; 
  }
}
.lnil-mic {
  &:before {
    content: $lnil-mic; 
  }
}
.lnil-server-15 {
  &:before {
    content: $lnil-server-15; 
  }
}
.lnil-cloud-sync {
  &:before {
    content: $lnil-cloud-sync; 
  }
}
.lnil-copy {
  &:before {
    content: $lnil-copy; 
  }
}
.lnil-zoom-in {
  &:before {
    content: $lnil-zoom-in; 
  }
}
.lnil-empty-file {
  &:before {
    content: $lnil-empty-file; 
  }
}
.lnil-reload {
  &:before {
    content: $lnil-reload; 
  }
}
.lnil-media-server {
  &:before {
    content: $lnil-media-server; 
  }
}
.lnil-billboard-alt-1 {
  &:before {
    content: $lnil-billboard-alt-1; 
  }
}
.lnil-remove-file {
  &:before {
    content: $lnil-remove-file; 
  }
}
.lnil-coding {
  &:before {
    content: $lnil-coding; 
  }
}
.lnil-low-battery {
  &:before {
    content: $lnil-low-battery; 
  }
}
.lnil-file-download {
  &:before {
    content: $lnil-file-download; 
  }
}
.lnil-alart-database {
  &:before {
    content: $lnil-alart-database; 
  }
}
.lnil-image {
  &:before {
    content: $lnil-image; 
  }
}
.lnil-cloud-search-2 {
  &:before {
    content: $lnil-cloud-search-2; 
  }
}
.lnil-mobile-alt-rounded {
  &:before {
    content: $lnil-mobile-alt-rounded; 
  }
}
.lnil-save {
  &:before {
    content: $lnil-save; 
  }
}
.lnil-server-14 {
  &:before {
    content: $lnil-server-14; 
  }
}
.lnil-scan {
  &:before {
    content: $lnil-scan; 
  }
}
.lnil-server-6 {
  &:before {
    content: $lnil-server-6; 
  }
}
.lnil-g {
  &:before {
    content: $lnil-g; 
  }
}
.lnil-coding-alt-1 {
  &:before {
    content: $lnil-coding-alt-1; 
  }
}
.lnil-server-4 {
  &:before {
    content: $lnil-server-4; 
  }
}
.lnil-zoom-out {
  &:before {
    content: $lnil-zoom-out; 
  }
}
.lnil-g-alt {
  &:before {
    content: $lnil-g-alt; 
  }
}
.lnil-add-server {
  &:before {
    content: $lnil-add-server; 
  }
}
.lnil-cogs {
  &:before {
    content: $lnil-cogs; 
  }
}
.lnil-software-deployment {
  &:before {
    content: $lnil-software-deployment; 
  }
}
.lnil-bluetooth-alt {
  &:before {
    content: $lnil-bluetooth-alt; 
  }
}
.lnil-cloud-search-1 {
  &:before {
    content: $lnil-cloud-search-1; 
  }
}
.lnil-cloud-computing-1 {
  &:before {
    content: $lnil-cloud-computing-1; 
  }
}
.lnil-server-5 {
  &:before {
    content: $lnil-server-5; 
  }
}
.lnil-camera {
  &:before {
    content: $lnil-camera; 
  }
}
.lnil-printer {
  &:before {
    content: $lnil-printer; 
  }
}
.lnil-ai {
  &:before {
    content: $lnil-ai; 
  }
}
.lnil-sim-card {
  &:before {
    content: $lnil-sim-card; 
  }
}
.lnil-rss-feed {
  &:before {
    content: $lnil-rss-feed; 
  }
}
.lnil-server-1 {
  &:before {
    content: $lnil-server-1; 
  }
}
.lnil-bot-1 {
  &:before {
    content: $lnil-bot-1; 
  }
}
.lnil-camera-alt {
  &:before {
    content: $lnil-camera-alt; 
  }
}
.lnil-cloud-search {
  &:before {
    content: $lnil-cloud-search; 
  }
}
.lnil-laptop-thumbs-up {
  &:before {
    content: $lnil-laptop-thumbs-up; 
  }
}
.lnil-no-charge-battery {
  &:before {
    content: $lnil-no-charge-battery; 
  }
}
.lnil-billboard1 {
  &:before {
    content: $lnil-billboard1; 
  }
}
.lnil-alarmclock {
  &:before {
    content: $lnil-alarmclock; 
  }
}
.lnil-network1 {
  &:before {
    content: $lnil-network1; 
  }
}
.lnil-ci-cd-alt {
  &:before {
    content: $lnil-ci-cd-alt; 
  }
}
.lnil-vr {
  &:before {
    content: $lnil-vr; 
  }
}
.lnil-tv-1 {
  &:before {
    content: $lnil-tv-1; 
  }
}
.lnil-star {
  &:before {
    content: $lnil-star; 
  }
}
.lnil-code-browser {
  &:before {
    content: $lnil-code-browser; 
  }
}
.lnil-unlink {
  &:before {
    content: $lnil-unlink; 
  }
}
.lnil-server-2 {
  &:before {
    content: $lnil-server-2; 
  }
}
.lnil-devops {
  &:before {
    content: $lnil-devops; 
  }
}
.lnil-database-9 {
  &:before {
    content: $lnil-database-9; 
  }
}
.lnil-coding-alt {
  &:before {
    content: $lnil-coding-alt; 
  }
}
.lnil-billboard-alt1 {
  &:before {
    content: $lnil-billboard-alt1; 
  }
}
.lnil-wire {
  &:before {
    content: $lnil-wire; 
  }
}
.lnil-automation {
  &:before {
    content: $lnil-automation; 
  }
}
.lnil-database-8 {
  &:before {
    content: $lnil-database-8; 
  }
}
.lnil-half-battery {
  &:before {
    content: $lnil-half-battery; 
  }
}
.lnil-webcam-alt {
  &:before {
    content: $lnil-webcam-alt; 
  }
}
.lnil-vr-alt-1 {
  &:before {
    content: $lnil-vr-alt-1; 
  }
}
.lnil-server-3 {
  &:before {
    content: $lnil-server-3; 
  }
}
.lnil-network-1 {
  &:before {
    content: $lnil-network-1; 
  }
}
.lnil-vr-tech {
  &:before {
    content: $lnil-vr-tech; 
  }
}
.lnil-infrared {
  &:before {
    content: $lnil-infrared; 
  }
}
.lnil-link-alt {
  &:before {
    content: $lnil-link-alt; 
  }
}
.lnil-mobile {
  &:before {
    content: $lnil-mobile; 
  }
}
.lnil-trash-can-alt-2 {
  &:before {
    content: $lnil-trash-can-alt-2; 
  }
}
.lnil-headphone {
  &:before {
    content: $lnil-headphone; 
  }
}
.lnil-incognito-browser {
  &:before {
    content: $lnil-incognito-browser; 
  }
}
.lnil-user-alt-1 {
  &:before {
    content: $lnil-user-alt-1; 
  }
}
.lnil-laptop-phone {
  &:before {
    content: $lnil-laptop-phone; 
  }
}
.lnil-processor-alt {
  &:before {
    content: $lnil-processor-alt; 
  }
}
.lnil-share {
  &:before {
    content: $lnil-share; 
  }
}
.lnil-check-server {
  &:before {
    content: $lnil-check-server; 
  }
}
.lnil-thumbnail {
  &:before {
    content: $lnil-thumbnail; 
  }
}
.lnil-d {
  &:before {
    content: $lnil-d; 
  }
}
.lnil-microphone-alt {
  &:before {
    content: $lnil-microphone-alt; 
  }
}
.lnil-cms {
  &:before {
    content: $lnil-cms; 
  }
}
.lnil-magnifier {
  &:before {
    content: $lnil-magnifier; 
  }
}
.lnil-charging {
  &:before {
    content: $lnil-charging; 
  }
}
.lnil-users-2 {
  &:before {
    content: $lnil-users-2; 
  }
}
.lnil-calendar {
  &:before {
    content: $lnil-calendar; 
  }
}
.lnil-trash-can-alt-1 {
  &:before {
    content: $lnil-trash-can-alt-1; 
  }
}
.lnil-laptop-star {
  &:before {
    content: $lnil-laptop-star; 
  }
}
.lnil-user-alt-2 {
  &:before {
    content: $lnil-user-alt-2; 
  }
}
.lnil-laptop {
  &:before {
    content: $lnil-laptop; 
  }
}
.lnil-display {
  &:before {
    content: $lnil-display; 
  }
}
.lnil-calender-alt-4 {
  &:before {
    content: $lnil-calender-alt-4; 
  }
}
.lnil-vr-chat {
  &:before {
    content: $lnil-vr-chat; 
  }
}
.lnil-check-database {
  &:before {
    content: $lnil-check-database; 
  }
}
.lnil-cut {
  &:before {
    content: $lnil-cut; 
  }
}
.lnil-user-alt {
  &:before {
    content: $lnil-user-alt; 
  }
}
.lnil-add-alt {
  &:before {
    content: $lnil-add-alt; 
  }
}
.lnil-reload-alt {
  &:before {
    content: $lnil-reload-alt; 
  }
}
.lnil-upload {
  &:before {
    content: $lnil-upload; 
  }
}
.lnil-mail-server {
  &:before {
    content: $lnil-mail-server; 
  }
}
.lnil-mobile-application {
  &:before {
    content: $lnil-mobile-application; 
  }
}
.lnil-pendrive {
  &:before {
    content: $lnil-pendrive; 
  }
}
.lnil-down-server {
  &:before {
    content: $lnil-down-server; 
  }
}
.lnil-bookmark {
  &:before {
    content: $lnil-bookmark; 
  }
}
.lnil-file-upload {
  &:before {
    content: $lnil-file-upload; 
  }
}
.lnil-rocket {
  &:before {
    content: $lnil-rocket; 
  }
}
.lnil-up-server {
  &:before {
    content: $lnil-up-server; 
  }
}
.lnil-microphone-alt-1 {
  &:before {
    content: $lnil-microphone-alt-1; 
  }
}
.lnil-star-empty {
  &:before {
    content: $lnil-star-empty; 
  }
}
.lnil-IOT {
  &:before {
    content: $lnil-IOT; 
  }
}
.lnil-cloud-download {
  &:before {
    content: $lnil-cloud-download; 
  }
}
.lnil-add-files {
  &:before {
    content: $lnil-add-files; 
  }
}
.lnil-vector-node-alt {
  &:before {
    content: $lnil-vector-node-alt; 
  }
}
.lnil-calender-alt-1 {
  &:before {
    content: $lnil-calender-alt-1; 
  }
}
.lnil-menu {
  &:before {
    content: $lnil-menu; 
  }
}
.lnil-brightness {
  &:before {
    content: $lnil-brightness; 
  }
}
.lnil-menu-circle {
  &:before {
    content: $lnil-menu-circle; 
  }
}
.lnil-backspace {
  &:before {
    content: $lnil-backspace; 
  }
}
.lnil-mouse {
  &:before {
    content: $lnil-mouse; 
  }
}
.lnil-control-panel {
  &:before {
    content: $lnil-control-panel; 
  }
}
.lnil-alert-server {
  &:before {
    content: $lnil-alert-server; 
  }
}
.lnil-laptop-star-1 {
  &:before {
    content: $lnil-laptop-star-1; 
  }
}
.lnil-shopping-basket {
  &:before {
    content: $lnil-shopping-basket; 
  }
}
.lnil-ps {
  &:before {
    content: $lnil-ps; 
  }
}
.lnil-alarm-clock-alt1 {
  &:before {
    content: $lnil-alarm-clock-alt1; 
  }
}
.lnil-calender-alt-3 {
  &:before {
    content: $lnil-calender-alt-3; 
  }
}
.lnil-folder {
  &:before {
    content: $lnil-folder; 
  }
}
.lnil-alarm {
  &:before {
    content: $lnil-alarm; 
  }
}
.lnil-file-lock {
  &:before {
    content: $lnil-file-lock; 
  }
}
.lnil-users {
  &:before {
    content: $lnil-users; 
  }
}
.lnil-ci-cd {
  &:before {
    content: $lnil-ci-cd; 
  }
}
.lnil-timer1 {
  &:before {
    content: $lnil-timer1; 
  }
}
.lnil-walkie-talkie {
  &:before {
    content: $lnil-walkie-talkie; 
  }
}
.lnil-cloud-server {
  &:before {
    content: $lnil-cloud-server; 
  }
}
.lnil-calender-alt-2 {
  &:before {
    content: $lnil-calender-alt-2; 
  }
}
.lnil-vr-design {
  &:before {
    content: $lnil-vr-design; 
  }
}
.lnil-sleeveless {
  &:before {
    content: $lnil-sleeveless; 
  }
}
.lnil-lifting {
  &:before {
    content: $lnil-lifting; 
  }
}
.lnil-gym-bag {
  &:before {
    content: $lnil-gym-bag; 
  }
}
.lnil-measure-tape {
  &:before {
    content: $lnil-measure-tape; 
  }
}
.lnil-jump-rope {
  &:before {
    content: $lnil-jump-rope; 
  }
}
.lnil-gym {
  &:before {
    content: $lnil-gym; 
  }
}
.lnil-yoga-mat {
  &:before {
    content: $lnil-yoga-mat; 
  }
}
.lnil-warming-up {
  &:before {
    content: $lnil-warming-up; 
  }
}
.lnil-dumbell-alt {
  &:before {
    content: $lnil-dumbell-alt; 
  }
}
.lnil-shirt1 {
  &:before {
    content: $lnil-shirt1; 
  }
}
.lnil-shower {
  &:before {
    content: $lnil-shower; 
  }
}
.lnil-towel {
  &:before {
    content: $lnil-towel; 
  }
}
.lnil-medicine-ball {
  &:before {
    content: $lnil-medicine-ball; 
  }
}
.lnil-bench {
  &:before {
    content: $lnil-bench; 
  }
}
.lnil-supplement {
  &:before {
    content: $lnil-supplement; 
  }
}
.lnil-whistle {
  &:before {
    content: $lnil-whistle; 
  }
}
.lnil-stopwatch {
  &:before {
    content: $lnil-stopwatch; 
  }
}
.lnil-no-smoking {
  &:before {
    content: $lnil-no-smoking; 
  }
}
.lnil-muscles {
  &:before {
    content: $lnil-muscles; 
  }
}
.lnil-punching-bag {
  &:before {
    content: $lnil-punching-bag; 
  }
}
.lnil-beverage {
  &:before {
    content: $lnil-beverage; 
  }
}
.lnil-chest-press {
  &:before {
    content: $lnil-chest-press; 
  }
}
.lnil-scale {
  &:before {
    content: $lnil-scale; 
  }
}
.lnil-stationary-bike {
  &:before {
    content: $lnil-stationary-bike; 
  }
}
.lnil-trampoline {
  &:before {
    content: $lnil-trampoline; 
  }
}
.lnil-tshirt {
  &:before {
    content: $lnil-tshirt; 
  }
}
.lnil-contest {
  &:before {
    content: $lnil-contest; 
  }
}
.lnil-uniform {
  &:before {
    content: $lnil-uniform; 
  }
}
.lnil-smartwatch {
  &:before {
    content: $lnil-smartwatch; 
  }
}
.lnil-pull-up {
  &:before {
    content: $lnil-pull-up; 
  }
}
.lnil-kettlebell {
  &:before {
    content: $lnil-kettlebell; 
  }
}
.lnil-water-bottle {
  &:before {
    content: $lnil-water-bottle; 
  }
}
.lnil-treadmill {
  &:before {
    content: $lnil-treadmill; 
  }
}
.lnil-underwear {
  &:before {
    content: $lnil-underwear; 
  }
}
.lnil-boxing-glove {
  &:before {
    content: $lnil-boxing-glove; 
  }
}
.lnil-resistance-band {
  &:before {
    content: $lnil-resistance-band; 
  }
}
.lnil-tank-top {
  &:before {
    content: $lnil-tank-top; 
  }
}
.lnil-schedule {
  &:before {
    content: $lnil-schedule; 
  }
}
.lnil-yoga-ball {
  &:before {
    content: $lnil-yoga-ball; 
  }
}
.lnil-rings {
  &:before {
    content: $lnil-rings; 
  }
}
.lnil-medal1 {
  &:before {
    content: $lnil-medal1; 
  }
}
.lnil-dumbell {
  &:before {
    content: $lnil-dumbell; 
  }
}
.lnil-trainers {
  &:before {
    content: $lnil-trainers; 
  }
}
.lnil-hand-grip {
  &:before {
    content: $lnil-hand-grip; 
  }
}
.lnil-apple1 {
  &:before {
    content: $lnil-apple1; 
  }
}
.lnil-monitor {
  &:before {
    content: $lnil-monitor; 
  }
}
.lnil-swimming-pool {
  &:before {
    content: $lnil-swimming-pool; 
  }
}
.lnil-sunrise-alt-1 {
  &:before {
    content: $lnil-sunrise-alt-1; 
  }
}
.lnil-crescent {
  &:before {
    content: $lnil-crescent; 
  }
}
.lnil-cloud-alt-1 {
  &:before {
    content: $lnil-cloud-alt-1; 
  }
}
.lnil-celsius-alt {
  &:before {
    content: $lnil-celsius-alt; 
  }
}
.lnil-humidity-alt {
  &:before {
    content: $lnil-humidity-alt; 
  }
}
.lnil-sunrise-alt-2 {
  &:before {
    content: $lnil-sunrise-alt-2; 
  }
}
.lnil-umbrella {
  &:before {
    content: $lnil-umbrella; 
  }
}
.lnil-cloud-alt-2 {
  &:before {
    content: $lnil-cloud-alt-2; 
  }
}
.lnil-umbrella-rain {
  &:before {
    content: $lnil-umbrella-rain; 
  }
}
.lnil-night-wind {
  &:before {
    content: $lnil-night-wind; 
  }
}
.lnil-lnio-lni-cloudy {
  &:before {
    content: $lnil-lnio-lni-cloudy; 
  }
}
.lnil-sunrise-alt {
  &:before {
    content: $lnil-sunrise-alt; 
  }
}
.lnil-wind-sun {
  &:before {
    content: $lnil-wind-sun; 
  }
}
.lnil-clouds {
  &:before {
    content: $lnil-clouds; 
  }
}
.lnil-night-thunder {
  &:before {
    content: $lnil-night-thunder; 
  }
}
.lnil-tornado1 {
  &:before {
    content: $lnil-tornado1; 
  }
}
.lnil-cloud-alt {
  &:before {
    content: $lnil-cloud-alt; 
  }
}
.lnil-sunset-alt {
  &:before {
    content: $lnil-sunset-alt; 
  }
}
.lnil-thunder {
  &:before {
    content: $lnil-thunder; 
  }
}
.lnil-cloud-sun {
  &:before {
    content: $lnil-cloud-sun; 
  }
}
.lnil-umbrella-alt {
  &:before {
    content: $lnil-umbrella-alt; 
  }
}
.lnil-sun-rain {
  &:before {
    content: $lnil-sun-rain; 
  }
}
.lnil-air {
  &:before {
    content: $lnil-air; 
  }
}
.lnil-kelvin-alt {
  &:before {
    content: $lnil-kelvin-alt; 
  }
}
.lnil-snowfall {
  &:before {
    content: $lnil-snowfall; 
  }
}
.lnil-farenheit {
  &:before {
    content: $lnil-farenheit; 
  }
}
.lnil-new-moon {
  &:before {
    content: $lnil-new-moon; 
  }
}
.lnil-wind-rose {
  &:before {
    content: $lnil-wind-rose; 
  }
}
.lnil-rainbow {
  &:before {
    content: $lnil-rainbow; 
  }
}
.lnil-rain {
  &:before {
    content: $lnil-rain; 
  }
}
.lnil-tide-alt {
  &:before {
    content: $lnil-tide-alt; 
  }
}
.lnil-night {
  &:before {
    content: $lnil-night; 
  }
}
.lnil-wind-cloud {
  &:before {
    content: $lnil-wind-cloud; 
  }
}
.lnil-mist {
  &:before {
    content: $lnil-mist; 
  }
}
.lnil-kelvin {
  &:before {
    content: $lnil-kelvin; 
  }
}
.lnil-cloudy-sun {
  &:before {
    content: $lnil-cloudy-sun; 
  }
}
.lnil-sun {
  &:before {
    content: $lnil-sun; 
  }
}
.lnil-moon-alt {
  &:before {
    content: $lnil-moon-alt; 
  }
}
.lnil-drop-alt {
  &:before {
    content: $lnil-drop-alt; 
  }
}
.lnil-cloud-moon {
  &:before {
    content: $lnil-cloud-moon; 
  }
}
.lnil-air-flow {
  &:before {
    content: $lnil-air-flow; 
  }
}
.lnil-moon-star {
  &:before {
    content: $lnil-moon-star; 
  }
}
.lnil-thunder-alt {
  &:before {
    content: $lnil-thunder-alt; 
  }
}
.lnil-quarter-moon-1 {
  &:before {
    content: $lnil-quarter-moon-1; 
  }
}
.lnil-water-drops {
  &:before {
    content: $lnil-water-drops; 
  }
}
.lnil-cloud {
  &:before {
    content: $lnil-cloud; 
  }
}
.lnil-icicles {
  &:before {
    content: $lnil-icicles; 
  }
}
.lnil-umbrella-rain-alt {
  &:before {
    content: $lnil-umbrella-rain-alt; 
  }
}
.lnil-tide {
  &:before {
    content: $lnil-tide; 
  }
}
.lnil-drop {
  &:before {
    content: $lnil-drop; 
  }
}
.lnil-moon {
  &:before {
    content: $lnil-moon; 
  }
}
.lnil-quarter-moon {
  &:before {
    content: $lnil-quarter-moon; 
  }
}
.lnil-farenheit-alt {
  &:before {
    content: $lnil-farenheit-alt; 
  }
}
.lnil-angle-double-left {
  &:before {
    content: $lnil-angle-double-left; 
  }
}
.lnil-move-down {
  &:before {
    content: $lnil-move-down; 
  }
}
.lnil-arrow-up-circle {
  &:before {
    content: $lnil-arrow-up-circle; 
  }
}
.lnil-arrow-down {
  &:before {
    content: $lnil-arrow-down; 
  }
}
.lnil-corner-right-up {
  &:before {
    content: $lnil-corner-right-up; 
  }
}
.lnil-arrow-double-right {
  &:before {
    content: $lnil-arrow-double-right; 
  }
}
.lnil-corner-up-right {
  &:before {
    content: $lnil-corner-up-right; 
  }
}
.lnil-push-right {
  &:before {
    content: $lnil-push-right; 
  }
}
.lnil-arrows-exchange-alt-v-1 {
  &:before {
    content: $lnil-arrows-exchange-alt-v-1; 
  }
}
.lnil-arrow-long-right-c {
  &:before {
    content: $lnil-arrow-long-right-c; 
  }
}
.lnil-corner-double-up-right {
  &:before {
    content: $lnil-corner-double-up-right; 
  }
}
.lnil-pointer1 {
  &:before {
    content: $lnil-pointer1; 
  }
}
.lnil-arrow-top-right {
  &:before {
    content: $lnil-arrow-top-right; 
  }
}
.lnil-corner-double-right-up {
  &:before {
    content: $lnil-corner-double-right-up; 
  }
}
.lnil-push-left {
  &:before {
    content: $lnil-push-left; 
  }
}
.lnil-scroll-h {
  &:before {
    content: $lnil-scroll-h; 
  }
}
.lnil-corner-left-up {
  &:before {
    content: $lnil-corner-left-up; 
  }
}
.lnil-arrows-break-h {
  &:before {
    content: $lnil-arrows-break-h; 
  }
}
.lnil-arrow-align-v {
  &:before {
    content: $lnil-arrow-align-v; 
  }
}
.lnil-arrows-scroll-v {
  &:before {
    content: $lnil-arrows-scroll-v; 
  }
}
.lnil-chevron-down {
  &:before {
    content: $lnil-chevron-down; 
  }
}
.lnil-arrows-expand-left-alt {
  &:before {
    content: $lnil-arrows-expand-left-alt; 
  }
}
.lnil-arrow-double-left {
  &:before {
    content: $lnil-arrow-double-left; 
  }
}
.lnil-exit-up {
  &:before {
    content: $lnil-exit-up; 
  }
}
.lnil-move-task {
  &:before {
    content: $lnil-move-task; 
  }
}
.lnil-pointer-right {
  &:before {
    content: $lnil-pointer-right; 
  }
}
.lnil-arrow-double-top {
  &:before {
    content: $lnil-arrow-double-top; 
  }
}
.lnil-arrows-shrink-h {
  &:before {
    content: $lnil-arrows-shrink-h; 
  }
}
.lnil-arrow-long-down-l {
  &:before {
    content: $lnil-arrow-long-down-l; 
  }
}
.lnil-chevron-up {
  &:before {
    content: $lnil-chevron-up; 
  }
}
.lnil-arrows-horizontal {
  &:before {
    content: $lnil-arrows-horizontal; 
  }
}
.lnil-chevron-left-circle {
  &:before {
    content: $lnil-chevron-left-circle; 
  }
}
.lnil-arrow-long-left-i {
  &:before {
    content: $lnil-arrow-long-left-i; 
  }
}
.lnil-north {
  &:before {
    content: $lnil-north; 
  }
}
.lnil-chevron-right {
  &:before {
    content: $lnil-chevron-right; 
  }
}
.lnil-swap-vertical {
  &:before {
    content: $lnil-swap-vertical; 
  }
}
.lnil-corner-down-right {
  &:before {
    content: $lnil-corner-down-right; 
  }
}
.lnil-arrow-right {
  &:before {
    content: $lnil-arrow-right; 
  }
}
.lnil-arrow-left-circle {
  &:before {
    content: $lnil-arrow-left-circle; 
  }
}
.lnil-arrows-exchange-alt {
  &:before {
    content: $lnil-arrows-exchange-alt; 
  }
}
.lnil-select-r {
  &:before {
    content: $lnil-select-r; 
  }
}
.lnil-shift-right {
  &:before {
    content: $lnil-shift-right; 
  }
}
.lnil-compress {
  &:before {
    content: $lnil-compress; 
  }
}
.lnil-signaling {
  &:before {
    content: $lnil-signaling; 
  }
}
.lnil-corner-right-down {
  &:before {
    content: $lnil-corner-right-down; 
  }
}
.lnil-arrows-expand-up-right {
  &:before {
    content: $lnil-arrows-expand-up-right; 
  }
}
.lnil-arrows-expand-down-left {
  &:before {
    content: $lnil-arrows-expand-down-left; 
  }
}
.lnil-arrow-top-left {
  &:before {
    content: $lnil-arrow-top-left; 
  }
}
.lnil-arrow-minimize-alt {
  &:before {
    content: $lnil-arrow-minimize-alt; 
  }
}
.lnil-angle-double-right {
  &:before {
    content: $lnil-angle-double-right; 
  }
}
.lnil-top-arrow-box {
  &:before {
    content: $lnil-top-arrow-box; 
  }
}
.lnil-right-alt {
  &:before {
    content: $lnil-right-alt; 
  }
}
.lnil-train {
  &:before {
    content: $lnil-train; 
  }
}
.lnil-top-left-arrow-box {
  &:before {
    content: $lnil-top-left-arrow-box; 
  }
}
.lnil-direction-alt {
  &:before {
    content: $lnil-direction-alt; 
  }
}
.lnil-corner-double-down-right {
  &:before {
    content: $lnil-corner-double-down-right; 
  }
}
.lnil-flag1 {
  &:before {
    content: $lnil-flag1; 
  }
}
.lnil-right-arrow-box {
  &:before {
    content: $lnil-right-arrow-box; 
  }
}
.lnil-direction-rtl {
  &:before {
    content: $lnil-direction-rtl; 
  }
}
.lnil-merge-vertical {
  &:before {
    content: $lnil-merge-vertical; 
  }
}
.lnil-arrows-merge-alt-h {
  &:before {
    content: $lnil-arrows-merge-alt-h; 
  }
}
.lnil-direction {
  &:before {
    content: $lnil-direction; 
  }
}
.lnil-pointer-top {
  &:before {
    content: $lnil-pointer-top; 
  }
}
.lnil-corner-up-left {
  &:before {
    content: $lnil-corner-up-left; 
  }
}
.lnil-pointer-down {
  &:before {
    content: $lnil-pointer-down; 
  }
}
.lnil-corner-double-right-down {
  &:before {
    content: $lnil-corner-double-right-down; 
  }
}
.lnil-corner-double-down-left {
  &:before {
    content: $lnil-corner-double-down-left; 
  }
}
.lnil-axis {
  &:before {
    content: $lnil-axis; 
  }
}
.lnil-select-o {
  &:before {
    content: $lnil-select-o; 
  }
}
.lnil-arrow-right-circle {
  &:before {
    content: $lnil-arrow-right-circle; 
  }
}
.lnil-push-up {
  &:before {
    content: $lnil-push-up; 
  }
}
.lnil-navigation {
  &:before {
    content: $lnil-navigation; 
  }
}
.lnil-right {
  &:before {
    content: $lnil-right; 
  }
}
.lnil-arrows-exchange {
  &:before {
    content: $lnil-arrows-exchange; 
  }
}
.lnil-arrows-expand-up-left {
  &:before {
    content: $lnil-arrows-expand-up-left; 
  }
}
.lnil-chevron-up-circle {
  &:before {
    content: $lnil-chevron-up-circle; 
  }
}
.lnil-arrow-long-left-c {
  &:before {
    content: $lnil-arrow-long-left-c; 
  }
}
.lnil-route-alt {
  &:before {
    content: $lnil-route-alt; 
  }
}
.lnil-corner-double-left-up {
  &:before {
    content: $lnil-corner-double-left-up; 
  }
}
.lnil-arrows-exchange-alt-v {
  &:before {
    content: $lnil-arrows-exchange-alt-v; 
  }
}
.lnil-down-left-arrow-box {
  &:before {
    content: $lnil-down-left-arrow-box; 
  }
}
.lnil-arrows-expand-right-alt {
  &:before {
    content: $lnil-arrows-expand-right-alt; 
  }
}
.lnil-flags2 {
  &:before {
    content: $lnil-flags2; 
  }
}
.lnil-pointer-left {
  &:before {
    content: $lnil-pointer-left; 
  }
}
.lnil-exit-down {
  &:before {
    content: $lnil-exit-down; 
  }
}
.lnil-corner-left-down {
  &:before {
    content: $lnil-corner-left-down; 
  }
}
.lnil-chevron-right-circle {
  &:before {
    content: $lnil-chevron-right-circle; 
  }
}
.lnil-arrows-merge-alt-v {
  &:before {
    content: $lnil-arrows-merge-alt-v; 
  }
}
.lnil-shift-left {
  &:before {
    content: $lnil-shift-left; 
  }
}
.lnil-arrows-expand-down-right {
  &:before {
    content: $lnil-arrows-expand-down-right; 
  }
}
.lnil-arrow-long-left-r {
  &:before {
    content: $lnil-arrow-long-left-r; 
  }
}
.lnil-arrow-long-left-e {
  &:before {
    content: $lnil-arrow-long-left-e; 
  }
}
.lnil-turn {
  &:before {
    content: $lnil-turn; 
  }
}
.lnil-compass2 {
  &:before {
    content: $lnil-compass2; 
  }
}
.lnil-traffic-light {
  &:before {
    content: $lnil-traffic-light; 
  }
}
.lnil-select1 {
  &:before {
    content: $lnil-select1; 
  }
}
.lnil-direction-ltr {
  &:before {
    content: $lnil-direction-ltr; 
  }
}
.lnil-exit {
  &:before {
    content: $lnil-exit; 
  }
}
.lnil-push-down {
  &:before {
    content: $lnil-push-down; 
  }
}
.lnil-straight {
  &:before {
    content: $lnil-straight; 
  }
}
.lnil-angle-double-up {
  &:before {
    content: $lnil-angle-double-up; 
  }
}
.lnil-arrows-vertical {
  &:before {
    content: $lnil-arrows-vertical; 
  }
}
.lnil-arrow-up {
  &:before {
    content: $lnil-arrow-up; 
  }
}
.lnil-arrow-expand {
  &:before {
    content: $lnil-arrow-expand; 
  }
}
.lnil-down-right-arrow-box {
  &:before {
    content: $lnil-down-right-arrow-box; 
  }
}
.lnil-left-arrow-box {
  &:before {
    content: $lnil-left-arrow-box; 
  }
}
.lnil-arrow-left {
  &:before {
    content: $lnil-arrow-left; 
  }
}
.lnil-route {
  &:before {
    content: $lnil-route; 
  }
}
.lnil-magnifying-glass {
  &:before {
    content: $lnil-magnifying-glass; 
  }
}
.lnil-motion {
  &:before {
    content: $lnil-motion; 
  }
}
.lnil-arrow-long-down-c {
  &:before {
    content: $lnil-arrow-long-down-c; 
  }
}
.lnil-corner-double-left-down {
  &:before {
    content: $lnil-corner-double-left-down; 
  }
}
.lnil-move-left {
  &:before {
    content: $lnil-move-left; 
  }
}
.lnil-scroll-v {
  &:before {
    content: $lnil-scroll-v; 
  }
}
.lnil-angle-double-down {
  &:before {
    content: $lnil-angle-double-down; 
  }
}
.lnil-arrows-break-v {
  &:before {
    content: $lnil-arrows-break-v; 
  }
}
.lnil-trending-down {
  &:before {
    content: $lnil-trending-down; 
  }
}
.lnil-arrows-scroll-h {
  &:before {
    content: $lnil-arrows-scroll-h; 
  }
}
.lnil-arrow-align-h {
  &:before {
    content: $lnil-arrow-align-h; 
  }
}
.lnil-arrow-down-circle {
  &:before {
    content: $lnil-arrow-down-circle; 
  }
}
.lnil-move-right {
  &:before {
    content: $lnil-move-right; 
  }
}
.lnil-enter {
  &:before {
    content: $lnil-enter; 
  }
}
.lnil-chevron-down-circle {
  &:before {
    content: $lnil-chevron-down-circle; 
  }
}
.lnil-placeholders {
  &:before {
    content: $lnil-placeholders; 
  }
}
.lnil-arrow-expand-alt {
  &:before {
    content: $lnil-arrow-expand-alt; 
  }
}
.lnil-down-arrow-box {
  &:before {
    content: $lnil-down-arrow-box; 
  }
}
.lnil-top-right-arrow-box {
  &:before {
    content: $lnil-top-right-arrow-box; 
  }
}
.lnil-merge-horizontal {
  &:before {
    content: $lnil-merge-horizontal; 
  }
}
.lnil-arrow-double-down {
  &:before {
    content: $lnil-arrow-double-down; 
  }
}
.lnil-corner-down-left {
  &:before {
    content: $lnil-corner-down-left; 
  }
}
.lnil-arrows-shrink-v {
  &:before {
    content: $lnil-arrows-shrink-v; 
  }
}
.lnil-walking {
  &:before {
    content: $lnil-walking; 
  }
}
.lnil-chevron-left {
  &:before {
    content: $lnil-chevron-left; 
  }
}
.lnil-move-up {
  &:before {
    content: $lnil-move-up; 
  }
}
.lnil-swap {
  &:before {
    content: $lnil-swap; 
  }
}
.lnil-arrow-long-down-e {
  &:before {
    content: $lnil-arrow-long-down-e; 
  }
}
.lnil-arrow-long-down-r {
  &:before {
    content: $lnil-arrow-long-down-r; 
  }
}
.lnil-corner-double-up-left {
  &:before {
    content: $lnil-corner-double-up-left; 
  }
}
.lnil-trending-up {
  &:before {
    content: $lnil-trending-up; 
  }
}
.lnil-arrow-minimize {
  &:before {
    content: $lnil-arrow-minimize; 
  }
}
.lnil-speech-bubble-34 {
  &:before {
    content: $lnil-speech-bubble-34; 
  }
}
.lnil-speech-bubble-20 {
  &:before {
    content: $lnil-speech-bubble-20; 
  }
}
.lnil-speech-bubble-8 {
  &:before {
    content: $lnil-speech-bubble-8; 
  }
}
.lnil-megaphone {
  &:before {
    content: $lnil-megaphone; 
  }
}
.lnil-target-mail {
  &:before {
    content: $lnil-target-mail; 
  }
}
.lnil-speech-bubble-9 {
  &:before {
    content: $lnil-speech-bubble-9; 
  }
}
.lnil-phone-caling {
  &:before {
    content: $lnil-phone-caling; 
  }
}
.lnil-mobile-alt {
  &:before {
    content: $lnil-mobile-alt; 
  }
}
.lnil-speech-bubble-21 {
  &:before {
    content: $lnil-speech-bubble-21; 
  }
}
.lnil-speech-bubble-35 {
  &:before {
    content: $lnil-speech-bubble-35; 
  }
}
.lnil-postcard-alt {
  &:before {
    content: $lnil-postcard-alt; 
  }
}
.lnil-speech-bubble-23 {
  &:before {
    content: $lnil-speech-bubble-23; 
  }
}
.lnil-speech-bubble-37 {
  &:before {
    content: $lnil-speech-bubble-37; 
  }
}
.lnil-reply {
  &:before {
    content: $lnil-reply; 
  }
}
.lnil-plane-alt {
  &:before {
    content: $lnil-plane-alt; 
  }
}
.lnil-boarding-pass-alt {
  &:before {
    content: $lnil-boarding-pass-alt; 
  }
}
.lnil-speech-bubble-36 {
  &:before {
    content: $lnil-speech-bubble-36; 
  }
}
.lnil-kayak {
  &:before {
    content: $lnil-kayak; 
  }
}
.lnil-speech-bubble-22 {
  &:before {
    content: $lnil-speech-bubble-22; 
  }
}
.lnil-mailbox {
  &:before {
    content: $lnil-mailbox; 
  }
}
.lnil-speech-bubble-26 {
  &:before {
    content: $lnil-speech-bubble-26; 
  }
}
.lnil-speech-bubble-32 {
  &:before {
    content: $lnil-speech-bubble-32; 
  }
}
.lnil-phone-misscall {
  &:before {
    content: $lnil-phone-misscall; 
  }
}
.lnil-mobile-chat {
  &:before {
    content: $lnil-mobile-chat; 
  }
}
.lnil-message-search {
  &:before {
    content: $lnil-message-search; 
  }
}
.lnil-yacht-3 {
  &:before {
    content: $lnil-yacht-3; 
  }
}
.lnil-envelope-alt {
  &:before {
    content: $lnil-envelope-alt; 
  }
}
.lnil-yacht-2 {
  &:before {
    content: $lnil-yacht-2; 
  }
}
.lnil-speech-bubble-33 {
  &:before {
    content: $lnil-speech-bubble-33; 
  }
}
.lnil-speech-bubble-27 {
  &:before {
    content: $lnil-speech-bubble-27; 
  }
}
.lnil-speech-bubble-31 {
  &:before {
    content: $lnil-speech-bubble-31; 
  }
}
.lnil-speech-bubble-25 {
  &:before {
    content: $lnil-speech-bubble-25; 
  }
}
.lnil-speech-bubble-19 {
  &:before {
    content: $lnil-speech-bubble-19; 
  }
}
.lnil-inbox {
  &:before {
    content: $lnil-inbox; 
  }
}
.lnil-comments {
  &:before {
    content: $lnil-comments; 
  }
}
.lnil-helicopter {
  &:before {
    content: $lnil-helicopter; 
  }
}
.lnil-road {
  &:before {
    content: $lnil-road; 
  }
}
.lnil-yacht-1 {
  &:before {
    content: $lnil-yacht-1; 
  }
}
.lnil-bridge {
  &:before {
    content: $lnil-bridge; 
  }
}
.lnil-speech-bubble-18 {
  &:before {
    content: $lnil-speech-bubble-18; 
  }
}
.lnil-speech-bubble-24 {
  &:before {
    content: $lnil-speech-bubble-24; 
  }
}
.lnil-message-reply {
  &:before {
    content: $lnil-message-reply; 
  }
}
.lnil-speech-bubble-30 {
  &:before {
    content: $lnil-speech-bubble-30; 
  }
}
.lnil-bubble {
  &:before {
    content: $lnil-bubble; 
  }
}
.lnil-phone-forward {
  &:before {
    content: $lnil-phone-forward; 
  }
}
.lnil-speech-bubble-43 {
  &:before {
    content: $lnil-speech-bubble-43; 
  }
}
.lnil-postcard {
  &:before {
    content: $lnil-postcard; 
  }
}
.lnil-message-lock {
  &:before {
    content: $lnil-message-lock; 
  }
}
.lnil-message-incoming {
  &:before {
    content: $lnil-message-incoming; 
  }
}
.lnil-plane {
  &:before {
    content: $lnil-plane; 
  }
}
.lnil-message-attachement {
  &:before {
    content: $lnil-message-attachement; 
  }
}
.lnil-speech-bubble-42 {
  &:before {
    content: $lnil-speech-bubble-42; 
  }
}
.lnil-ship1 {
  &:before {
    content: $lnil-ship1; 
  }
}
.lnil-speech-bubble-40 {
  &:before {
    content: $lnil-speech-bubble-40; 
  }
}
.lnil-envelope {
  &:before {
    content: $lnil-envelope; 
  }
}
.lnil-phone-outgoing {
  &:before {
    content: $lnil-phone-outgoing; 
  }
}
.lnil-message-forward {
  &:before {
    content: $lnil-message-forward; 
  }
}
.lnil-delivery-truck-5 {
  &:before {
    content: $lnil-delivery-truck-5; 
  }
}
.lnil-phone-cut {
  &:before {
    content: $lnil-phone-cut; 
  }
}
.lnil-mobile-alt-1 {
  &:before {
    content: $lnil-mobile-alt-1; 
  }
}
.lnil-message-check {
  &:before {
    content: $lnil-message-check; 
  }
}
.lnil-speech-bubble-41 {
  &:before {
    content: $lnil-speech-bubble-41; 
  }
}
.lnil-speech-bubble-45 {
  &:before {
    content: $lnil-speech-bubble-45; 
  }
}
.lnil-taxi {
  &:before {
    content: $lnil-taxi; 
  }
}
.lnil-train1 {
  &:before {
    content: $lnil-train1; 
  }
}
.lnil-phone-redial {
  &:before {
    content: $lnil-phone-redial; 
  }
}
.lnil-phone-conference {
  &:before {
    content: $lnil-phone-conference; 
  }
}
.lnil-comments-reply {
  &:before {
    content: $lnil-comments-reply; 
  }
}
.lnil-speech-bubble-44 {
  &:before {
    content: $lnil-speech-bubble-44; 
  }
}
.lnil-support {
  &:before {
    content: $lnil-support; 
  }
}
.lnil-speech-bubble-46 {
  &:before {
    content: $lnil-speech-bubble-46; 
  }
}
.lnil-message-plus {
  &:before {
    content: $lnil-message-plus; 
  }
}
.lnil-phone-set {
  &:before {
    content: $lnil-phone-set; 
  }
}
.lnil-message-info {
  &:before {
    content: $lnil-message-info; 
  }
}
.lnil-bullhorn {
  &:before {
    content: $lnil-bullhorn; 
  }
}
.lnil-message-shield {
  &:before {
    content: $lnil-message-shield; 
  }
}
.lnil-car-alt-4 {
  &:before {
    content: $lnil-car-alt-4; 
  }
}
.lnil-train-alt {
  &:before {
    content: $lnil-train-alt; 
  }
}
.lnil-caravan {
  &:before {
    content: $lnil-caravan; 
  }
}
.lnil-bridge-alt {
  &:before {
    content: $lnil-bridge-alt; 
  }
}
.lnil-scooter {
  &:before {
    content: $lnil-scooter; 
  }
}
.lnil-road-alt {
  &:before {
    content: $lnil-road-alt; 
  }
}
.lnil-car-alt-2 {
  &:before {
    content: $lnil-car-alt-2; 
  }
}
.lnil-message-love {
  &:before {
    content: $lnil-message-love; 
  }
}
.lnil-bus {
  &:before {
    content: $lnil-bus; 
  }
}
.lnil-message-people {
  &:before {
    content: $lnil-message-people; 
  }
}
.lnil-yacht {
  &:before {
    content: $lnil-yacht; 
  }
}
.lnil-car-alt-3 {
  &:before {
    content: $lnil-car-alt-3; 
  }
}
.lnil-car-alt-1 {
  &:before {
    content: $lnil-car-alt-1; 
  }
}
.lnil-page {
  &:before {
    content: $lnil-page; 
  }
}
.lnil-anchor {
  &:before {
    content: $lnil-anchor; 
  }
}
.lnil-phone-ring {
  &:before {
    content: $lnil-phone-ring; 
  }
}
.lnil-message-edit {
  &:before {
    content: $lnil-message-edit; 
  }
}
.lnil-message-people-alt {
  &:before {
    content: $lnil-message-people-alt; 
  }
}
.lnil-speech-bubble-29 {
  &:before {
    content: $lnil-speech-bubble-29; 
  }
}
.lnil-speech-bubble-15 {
  &:before {
    content: $lnil-speech-bubble-15; 
  }
}
.lnil-phone {
  &:before {
    content: $lnil-phone; 
  }
}
.lnil-speech-bubble-1 {
  &:before {
    content: $lnil-speech-bubble-1; 
  }
}
.lnil-message-outgoing {
  &:before {
    content: $lnil-message-outgoing; 
  }
}
.lnil-car {
  &:before {
    content: $lnil-car; 
  }
}
.lnil-speech-bubble-14 {
  &:before {
    content: $lnil-speech-bubble-14; 
  }
}
.lnil-bridge-alt-4 {
  &:before {
    content: $lnil-bridge-alt-4; 
  }
}
.lnil-phone-pause {
  &:before {
    content: $lnil-phone-pause; 
  }
}
.lnil-speech-bubble-28 {
  &:before {
    content: $lnil-speech-bubble-28; 
  }
}
.lnil-comments-alt1 {
  &:before {
    content: $lnil-comments-alt1; 
  }
}
.lnil-speech-bubble-16 {
  &:before {
    content: $lnil-speech-bubble-16; 
  }
}
.lnil-speech-bubble-2 {
  &:before {
    content: $lnil-speech-bubble-2; 
  }
}
.lnil-phone-incoming {
  &:before {
    content: $lnil-phone-incoming; 
  }
}
.lnil-speech-bubble-3 {
  &:before {
    content: $lnil-speech-bubble-3; 
  }
}
.lnil-message-block {
  &:before {
    content: $lnil-message-block; 
  }
}
.lnil-phone-block {
  &:before {
    content: $lnil-phone-block; 
  }
}
.lnil-message-minus {
  &:before {
    content: $lnil-message-minus; 
  }
}
.lnil-speech-bubble-17 {
  &:before {
    content: $lnil-speech-bubble-17; 
  }
}
.lnil-bridge-alt-3 {
  &:before {
    content: $lnil-bridge-alt-3; 
  }
}
.lnil-speech-bubble-13 {
  &:before {
    content: $lnil-speech-bubble-13; 
  }
}
.lnil-message-share {
  &:before {
    content: $lnil-message-share; 
  }
}
.lnil-road-alt-1 {
  &:before {
    content: $lnil-road-alt-1; 
  }
}
.lnil-speech-bubble-6 {
  &:before {
    content: $lnil-speech-bubble-6; 
  }
}
.lnil-car-alt {
  &:before {
    content: $lnil-car-alt; 
  }
}
.lnil-message-desktop {
  &:before {
    content: $lnil-message-desktop; 
  }
}
.lnil-phone-calling-alt {
  &:before {
    content: $lnil-phone-calling-alt; 
  }
}
.lnil-speech-bubble-12 {
  &:before {
    content: $lnil-speech-bubble-12; 
  }
}
.lnil-bridge-alt-2 {
  &:before {
    content: $lnil-bridge-alt-2; 
  }
}
.lnil-boarding-pass {
  &:before {
    content: $lnil-boarding-pass; 
  }
}
.lnil-speech-bubble-10 {
  &:before {
    content: $lnil-speech-bubble-10; 
  }
}
.lnil-popup {
  &:before {
    content: $lnil-popup; 
  }
}
.lnil-speech-bubble-38 {
  &:before {
    content: $lnil-speech-bubble-38; 
  }
}
.lnil-message-cross {
  &:before {
    content: $lnil-message-cross; 
  }
}
.lnil-speech-bubble-4 {
  &:before {
    content: $lnil-speech-bubble-4; 
  }
}
.lnil-bi-cycle {
  &:before {
    content: $lnil-bi-cycle; 
  }
}
.lnil-chat-minus {
  &:before {
    content: $lnil-chat-minus; 
  }
}
.lnil-chat-add1 {
  &:before {
    content: $lnil-chat-add1; 
  }
}
.lnil-speech-bubble-5 {
  &:before {
    content: $lnil-speech-bubble-5; 
  }
}
.lnil-message-laptop {
  &:before {
    content: $lnil-message-laptop; 
  }
}
.lnil-speech-bubble-39 {
  &:before {
    content: $lnil-speech-bubble-39; 
  }
}
.lnil-speech-bubble-11 {
  &:before {
    content: $lnil-speech-bubble-11; 
  }
}
.lnil-bridge-alt-1 {
  &:before {
    content: $lnil-bridge-alt-1; 
  }
}
.lnil-idea-chat {
  &:before {
    content: $lnil-idea-chat; 
  }
}
.lnil-idea-share {
  &:before {
    content: $lnil-idea-share; 
  }
}
.lnil-mobile-rocket-1 {
  &:before {
    content: $lnil-mobile-rocket-1; 
  }
}
.lnil-rocket-growth-1 {
  &:before {
    content: $lnil-rocket-growth-1; 
  }
}
.lnil-head-idea-setting {
  &:before {
    content: $lnil-head-idea-setting; 
  }
}
.lnil-mobile-rocket-3 {
  &:before {
    content: $lnil-mobile-rocket-3; 
  }
}
.lnil-mobile-rocket-2 {
  &:before {
    content: $lnil-mobile-rocket-2; 
  }
}
.lnil-rocket-growth-2 {
  &:before {
    content: $lnil-rocket-growth-2; 
  }
}
.lnil-mobile-rocket {
  &:before {
    content: $lnil-mobile-rocket; 
  }
}
.lnil-mobile-target-1 {
  &:before {
    content: $lnil-mobile-target-1; 
  }
}
.lnil-puzzle {
  &:before {
    content: $lnil-puzzle; 
  }
}
.lnil-arrow-target-1 {
  &:before {
    content: $lnil-arrow-target-1; 
  }
}
.lnil-idea-shield {
  &:before {
    content: $lnil-idea-shield; 
  }
}
.lnil-letter-idea {
  &:before {
    content: $lnil-letter-idea; 
  }
}
.lnil-idea-head {
  &:before {
    content: $lnil-idea-head; 
  }
}
.lnil-rocket-launch-1 {
  &:before {
    content: $lnil-rocket-launch-1; 
  }
}
.lnil-business-rocket {
  &:before {
    content: $lnil-business-rocket; 
  }
}
.lnil-networking-startup {
  &:before {
    content: $lnil-networking-startup; 
  }
}
.lnil-grow-help {
  &:before {
    content: $lnil-grow-help; 
  }
}
.lnil-rocket-launch-2 {
  &:before {
    content: $lnil-rocket-launch-2; 
  }
}
.lnil-rocket-search {
  &:before {
    content: $lnil-rocket-search; 
  }
}
.lnil-rocket-target {
  &:before {
    content: $lnil-rocket-target; 
  }
}
.lnil-rocket-launch-3 {
  &:before {
    content: $lnil-rocket-launch-3; 
  }
}
.lnil-idea-presentation {
  &:before {
    content: $lnil-idea-presentation; 
  }
}
.lnil-idea-search {
  &:before {
    content: $lnil-idea-search; 
  }
}
.lnil-preview {
  &:before {
    content: $lnil-preview; 
  }
}
.lnil-rocket-launch-6 {
  &:before {
    content: $lnil-rocket-launch-6; 
  }
}
.lnil-rocket-favourite {
  &:before {
    content: $lnil-rocket-favourite; 
  }
}
.lnil-rocket-verified {
  &:before {
    content: $lnil-rocket-verified; 
  }
}
.lnil-rocket-earth {
  &:before {
    content: $lnil-rocket-earth; 
  }
}
.lnil-rocket-launch-4 {
  &:before {
    content: $lnil-rocket-launch-4; 
  }
}
.lnil-idea-envelope {
  &:before {
    content: $lnil-idea-envelope; 
  }
}
.lnil-grow-help-1 {
  &:before {
    content: $lnil-grow-help-1; 
  }
}
.lnil-mission {
  &:before {
    content: $lnil-mission; 
  }
}
.lnil-rocket-launch-5 {
  &:before {
    content: $lnil-rocket-launch-5; 
  }
}
.lnil-rocket-growth {
  &:before {
    content: $lnil-rocket-growth; 
  }
}
.lnil-arrow-target {
  &:before {
    content: $lnil-arrow-target; 
  }
}
.lnil-business-partnership {
  &:before {
    content: $lnil-business-partnership; 
  }
}
.lnil-graph-growth {
  &:before {
    content: $lnil-graph-growth; 
  }
}
.lnil-rocket-money {
  &:before {
    content: $lnil-rocket-money; 
  }
}
.lnil-bulb-idea {
  &:before {
    content: $lnil-bulb-idea; 
  }
}
.lnil-idea-down {
  &:before {
    content: $lnil-idea-down; 
  }
}
.lnil-idea-briefcase {
  &:before {
    content: $lnil-idea-briefcase; 
  }
}
.lnil-flash-idea {
  &:before {
    content: $lnil-flash-idea; 
  }
}
.lnil-ipo-flag {
  &:before {
    content: $lnil-ipo-flag; 
  }
}
.lnil-rocket-star {
  &:before {
    content: $lnil-rocket-star; 
  }
}
.lnil-maze {
  &:before {
    content: $lnil-maze; 
  }
}
.lnil-idea-head-1 {
  &:before {
    content: $lnil-idea-head-1; 
  }
}
.lnil-view {
  &:before {
    content: $lnil-view; 
  }
}
.lnil-idea-setting {
  &:before {
    content: $lnil-idea-setting; 
  }
}
.lnil-rocket-idea {
  &:before {
    content: $lnil-rocket-idea; 
  }
}
.lnil-laptop-target {
  &:before {
    content: $lnil-laptop-target; 
  }
}
.lnil-rocket-launch {
  &:before {
    content: $lnil-rocket-launch; 
  }
}
.lnil-rocket1 {
  &:before {
    content: $lnil-rocket1; 
  }
}
.lnil-idea-reload {
  &:before {
    content: $lnil-idea-reload; 
  }
}
.lnil-idea-rise {
  &:before {
    content: $lnil-idea-rise; 
  }
}
.lnil-go-frame-1 {
  &:before {
    content: $lnil-go-frame-1; 
  }
}
.lnil-idea-location {
  &:before {
    content: $lnil-idea-location; 
  }
}
.lnil-mobile-target {
  &:before {
    content: $lnil-mobile-target; 
  }
}
.lnil-money-idea {
  &:before {
    content: $lnil-money-idea; 
  }
}
.lnil-go-frame {
  &:before {
    content: $lnil-go-frame; 
  }
}
.lnil-idea-label {
  &:before {
    content: $lnil-idea-label; 
  }
}
.lnil-idea-agenda {
  &:before {
    content: $lnil-idea-agenda; 
  }
}
.lnil-cloud-idea {
  &:before {
    content: $lnil-cloud-idea; 
  }
}
.lnil-paper-idea {
  &:before {
    content: $lnil-paper-idea; 
  }
}
.lnil-law-book {
  &:before {
    content: $lnil-law-book; 
  }
}
.lnil-jail {
  &:before {
    content: $lnil-jail; 
  }
}
.lnil-police-badge {
  &:before {
    content: $lnil-police-badge; 
  }
}
.lnil-jury {
  &:before {
    content: $lnil-jury; 
  }
}
.lnil-underline {
  &:before {
    content: $lnil-underline; 
  }
}
.lnil-undo-alt {
  &:before {
    content: $lnil-undo-alt; 
  }
}
.lnil-highlight-alt1 {
  &:before {
    content: $lnil-highlight-alt1; 
  }
}
.lnil-text-format-remove {
  &:before {
    content: $lnil-text-format-remove; 
  }
}
.lnil-text-align-center {
  &:before {
    content: $lnil-text-align-center; 
  }
}
.lnil-strikethrough {
  &:before {
    content: $lnil-strikethrough; 
  }
}
.lnil-pagination {
  &:before {
    content: $lnil-pagination; 
  }
}
.lnil-pilcrow {
  &:before {
    content: $lnil-pilcrow; 
  }
}
.lnil-line-dashed {
  &:before {
    content: $lnil-line-dashed; 
  }
}
.lnil-vertical-line {
  &:before {
    content: $lnil-vertical-line; 
  }
}
.lnil-text-align-justify {
  &:before {
    content: $lnil-text-align-justify; 
  }
}
.lnil-sort-alpha-asc {
  &:before {
    content: $lnil-sort-alpha-asc; 
  }
}
.lnil-bold {
  &:before {
    content: $lnil-bold; 
  }
}
.lnil-line-dotted {
  &:before {
    content: $lnil-line-dotted; 
  }
}
.lnil-text-format {
  &:before {
    content: $lnil-text-format; 
  }
}
.lnil-grammer {
  &:before {
    content: $lnil-grammer; 
  }
}
.lnil-text {
  &:before {
    content: $lnil-text; 
  }
}
.lnil-page-break {
  &:before {
    content: $lnil-page-break; 
  }
}
.lnil-text-alt {
  &:before {
    content: $lnil-text-alt; 
  }
}
.lnil-capital-letter {
  &:before {
    content: $lnil-capital-letter; 
  }
}
.lnil-redo {
  &:before {
    content: $lnil-redo; 
  }
}
.lnil-clear-format {
  &:before {
    content: $lnil-clear-format; 
  }
}
.lnil-spellcheck {
  &:before {
    content: $lnil-spellcheck; 
  }
}
.lnil-text-align-left {
  &:before {
    content: $lnil-text-align-left; 
  }
}
.lnil-reflect {
  &:before {
    content: $lnil-reflect; 
  }
}
.lnil-line-spacing {
  &:before {
    content: $lnil-line-spacing; 
  }
}
.lnil-page1 {
  &:before {
    content: $lnil-page1; 
  }
}
.lnil-idea-alt1 {
  &:before {
    content: $lnil-idea-alt1; 
  }
}
.lnil-sort-amount-asc {
  &:before {
    content: $lnil-sort-amount-asc; 
  }
}
.lnil-symbol {
  &:before {
    content: $lnil-symbol; 
  }
}
.lnil-height {
  &:before {
    content: $lnil-height; 
  }
}
.lnil-font-alt {
  &:before {
    content: $lnil-font-alt; 
  }
}
.lnil-quote-alt-4 {
  &:before {
    content: $lnil-quote-alt-4; 
  }
}
.lnil-indent-increase {
  &:before {
    content: $lnil-indent-increase; 
  }
}
.lnil-quote-alt-5 {
  &:before {
    content: $lnil-quote-alt-5; 
  }
}
.lnil-text-align-right {
  &:before {
    content: $lnil-text-align-right; 
  }
}
.lnil-text-underline-alt {
  &:before {
    content: $lnil-text-underline-alt; 
  }
}
.lnil-undo {
  &:before {
    content: $lnil-undo; 
  }
}
.lnil-italic {
  &:before {
    content: $lnil-italic; 
  }
}
.lnil-quote-alt-7 {
  &:before {
    content: $lnil-quote-alt-7; 
  }
}
.lnil-quote-alt-6 {
  &:before {
    content: $lnil-quote-alt-6; 
  }
}
.lnil-sort-amount-dsc {
  &:before {
    content: $lnil-sort-amount-dsc; 
  }
}
.lnil-indent-decrease {
  &:before {
    content: $lnil-indent-decrease; 
  }
}
.lnil-quote-alt-2 {
  &:before {
    content: $lnil-quote-alt-2; 
  }
}
.lnil-quote-alt-3 {
  &:before {
    content: $lnil-quote-alt-3; 
  }
}
.lnil-quote-alt {
  &:before {
    content: $lnil-quote-alt; 
  }
}
.lnil-strikethrough-alt {
  &:before {
    content: $lnil-strikethrough-alt; 
  }
}
.lnil-quote-alt-1 {
  &:before {
    content: $lnil-quote-alt-1; 
  }
}
.lnil-line-double {
  &:before {
    content: $lnil-line-double; 
  }
}
.lnil-font {
  &:before {
    content: $lnil-font; 
  }
}
.lnil-wheelchair-alt {
  &:before {
    content: $lnil-wheelchair-alt; 
  }
}
.lnil-capsule-alt {
  &:before {
    content: $lnil-capsule-alt; 
  }
}
.lnil-capsule {
  &:before {
    content: $lnil-capsule; 
  }
}
.lnil-laptop-alt-medical {
  &:before {
    content: $lnil-laptop-alt-medical; 
  }
}
.lnil-virus {
  &:before {
    content: $lnil-virus; 
  }
}
.lnil-laptop-report-medical {
  &:before {
    content: $lnil-laptop-report-medical; 
  }
}
.lnil-smartwatch-alt-1 {
  &:before {
    content: $lnil-smartwatch-alt-1; 
  }
}
.lnil-signboard-hospital {
  &:before {
    content: $lnil-signboard-hospital; 
  }
}
.lnil-hospital-location {
  &:before {
    content: $lnil-hospital-location; 
  }
}
.lnil-symbols-female {
  &:before {
    content: $lnil-symbols-female; 
  }
}
.lnil-file-medical-1 {
  &:before {
    content: $lnil-file-medical-1; 
  }
}
.lnil-medicine {
  &:before {
    content: $lnil-medicine; 
  }
}
.lnil-dropper {
  &:before {
    content: $lnil-dropper; 
  }
}
.lnil-broken-bone {
  &:before {
    content: $lnil-broken-bone; 
  }
}
.lnil-chat-vet {
  &:before {
    content: $lnil-chat-vet; 
  }
}
.lnil-chest {
  &:before {
    content: $lnil-chest; 
  }
}
.lnil-heart-plus1 {
  &:before {
    content: $lnil-heart-plus1; 
  }
}
.lnil-user-medical {
  &:before {
    content: $lnil-user-medical; 
  }
}
.lnil-ambulance-2 {
  &:before {
    content: $lnil-ambulance-2; 
  }
}
.lnil-yoga-mat1 {
  &:before {
    content: $lnil-yoga-mat1; 
  }
}
.lnil-healthcare {
  &:before {
    content: $lnil-healthcare; 
  }
}
.lnil-dropper-alt-2 {
  &:before {
    content: $lnil-dropper-alt-2; 
  }
}
.lnil-file-alt-medical {
  &:before {
    content: $lnil-file-alt-medical; 
  }
}
.lnil-test-tube-alt {
  &:before {
    content: $lnil-test-tube-alt; 
  }
}
.lnil-hospital-bed-alt-1 {
  &:before {
    content: $lnil-hospital-bed-alt-1; 
  }
}
.lnil-hospital-alt {
  &:before {
    content: $lnil-hospital-alt; 
  }
}
.lnil-hospital-building {
  &:before {
    content: $lnil-hospital-building; 
  }
}
.lnil-medical-briefcase {
  &:before {
    content: $lnil-medical-briefcase; 
  }
}
.lnil-hospital-bed-patient {
  &:before {
    content: $lnil-hospital-bed-patient; 
  }
}
.lnil-liver {
  &:before {
    content: $lnil-liver; 
  }
}
.lnil-brain-check {
  &:before {
    content: $lnil-brain-check; 
  }
}
.lnil-dropper-alt-1 {
  &:before {
    content: $lnil-dropper-alt-1; 
  }
}
.lnil-smartphone-alt-medical {
  &:before {
    content: $lnil-smartphone-alt-medical; 
  }
}
.lnil-tweezers {
  &:before {
    content: $lnil-tweezers; 
  }
}
.lnil-diagnosis-alt {
  &:before {
    content: $lnil-diagnosis-alt; 
  }
}
.lnil-walker {
  &:before {
    content: $lnil-walker; 
  }
}
.lnil-nose {
  &:before {
    content: $lnil-nose; 
  }
}
.lnil-brain {
  &:before {
    content: $lnil-brain; 
  }
}
.lnil-male {
  &:before {
    content: $lnil-male; 
  }
}
.lnil-hospital-bed-alt-2 {
  &:before {
    content: $lnil-hospital-bed-alt-2; 
  }
}
.lnil-refresh-medical {
  &:before {
    content: $lnil-refresh-medical; 
  }
}
.lnil-mobile-phones-medical {
  &:before {
    content: $lnil-mobile-phones-medical; 
  }
}
.lnil-cotton-buds {
  &:before {
    content: $lnil-cotton-buds; 
  }
}
.lnil-medical-smartphone-alt-1 {
  &:before {
    content: $lnil-medical-smartphone-alt-1; 
  }
}
.lnil-signal-hospital {
  &:before {
    content: $lnil-signal-hospital; 
  }
}
.lnil-ambulance-alt {
  &:before {
    content: $lnil-ambulance-alt; 
  }
}
.lnil-scalpel {
  &:before {
    content: $lnil-scalpel; 
  }
}
.lnil-eye-test {
  &:before {
    content: $lnil-eye-test; 
  }
}
.lnil-diagnosis {
  &:before {
    content: $lnil-diagnosis; 
  }
}
.lnil-monitor-heart-alt {
  &:before {
    content: $lnil-monitor-heart-alt; 
  }
}
.lnil-ambulance {
  &:before {
    content: $lnil-ambulance; 
  }
}
.lnil-report-laptop {
  &:before {
    content: $lnil-report-laptop; 
  }
}
.lnil-saline-alt-1 {
  &:before {
    content: $lnil-saline-alt-1; 
  }
}
.lnil-heartrate-monitor {
  &:before {
    content: $lnil-heartrate-monitor; 
  }
}
.lnil-stethoscope-alt {
  &:before {
    content: $lnil-stethoscope-alt; 
  }
}
.lnil-dna {
  &:before {
    content: $lnil-dna; 
  }
}
.lnil-hat-medical {
  &:before {
    content: $lnil-hat-medical; 
  }
}
.lnil-desktop-computer-alt {
  &:before {
    content: $lnil-desktop-computer-alt; 
  }
}
.lnil-desktop-monitor-heart {
  &:before {
    content: $lnil-desktop-monitor-heart; 
  }
}
.lnil-thermometer {
  &:before {
    content: $lnil-thermometer; 
  }
}
.lnil-medical-protection {
  &:before {
    content: $lnil-medical-protection; 
  }
}
.lnil-gluten-free {
  &:before {
    content: $lnil-gluten-free; 
  }
}
.lnil-kidney-alt {
  &:before {
    content: $lnil-kidney-alt; 
  }
}
.lnil-patient-alt-1 {
  &:before {
    content: $lnil-patient-alt-1; 
  }
}
.lnil-medical-sign {
  &:before {
    content: $lnil-medical-sign; 
  }
}
.lnil-capsule-alt-1 {
  &:before {
    content: $lnil-capsule-alt-1; 
  }
}
.lnil-temparature-measuring {
  &:before {
    content: $lnil-temparature-measuring; 
  }
}
.lnil-bed-patient {
  &:before {
    content: $lnil-bed-patient; 
  }
}
.lnil-share-alt1 {
  &:before {
    content: $lnil-share-alt1; 
  }
}
.lnil-monitor-medical {
  &:before {
    content: $lnil-monitor-medical; 
  }
}
.lnil-heart-check {
  &:before {
    content: $lnil-heart-check; 
  }
}
.lnil-hospital {
  &:before {
    content: $lnil-hospital; 
  }
}
.lnil-scale1 {
  &:before {
    content: $lnil-scale1; 
  }
}
.lnil-bed-alt-patient {
  &:before {
    content: $lnil-bed-alt-patient; 
  }
}
.lnil-vaccine {
  &:before {
    content: $lnil-vaccine; 
  }
}
.lnil-lungs-alt {
  &:before {
    content: $lnil-lungs-alt; 
  }
}
.lnil-suitcase-medical {
  &:before {
    content: $lnil-suitcase-medical; 
  }
}
.lnil-skipping-rope {
  &:before {
    content: $lnil-skipping-rope; 
  }
}
.lnil-saline {
  &:before {
    content: $lnil-saline; 
  }
}
.lnil-lights-medical {
  &:before {
    content: $lnil-lights-medical; 
  }
}
.lnil-clipboard-alt {
  &:before {
    content: $lnil-clipboard-alt; 
  }
}
.lnil-injection {
  &:before {
    content: $lnil-injection; 
  }
}
.lnil-smartphone-heart-beat {
  &:before {
    content: $lnil-smartphone-heart-beat; 
  }
}
.lnil-bandage-alt {
  &:before {
    content: $lnil-bandage-alt; 
  }
}
.lnil-monitor-alt-2 {
  &:before {
    content: $lnil-monitor-alt-2; 
  }
}
.lnil-syringe {
  &:before {
    content: $lnil-syringe; 
  }
}
.lnil-inhaler {
  &:before {
    content: $lnil-inhaler; 
  }
}
.lnil-saline-alt {
  &:before {
    content: $lnil-saline-alt; 
  }
}
.lnil-kettleball {
  &:before {
    content: $lnil-kettleball; 
  }
}
.lnil-injury {
  &:before {
    content: $lnil-injury; 
  }
}
.lnil-cardiology {
  &:before {
    content: $lnil-cardiology; 
  }
}
.lnil-brain-alt {
  &:before {
    content: $lnil-brain-alt; 
  }
}
.lnil-hospital-sign {
  &:before {
    content: $lnil-hospital-sign; 
  }
}
.lnil-heart-monitor {
  &:before {
    content: $lnil-heart-monitor; 
  }
}
.lnil-slim {
  &:before {
    content: $lnil-slim; 
  }
}
.lnil-weight-machine1 {
  &:before {
    content: $lnil-weight-machine1; 
  }
}
.lnil-male-alt {
  &:before {
    content: $lnil-male-alt; 
  }
}
.lnil-smartwatch-medical {
  &:before {
    content: $lnil-smartwatch-medical; 
  }
}
.lnil-medical-call {
  &:before {
    content: $lnil-medical-call; 
  }
}
.lnil-bone {
  &:before {
    content: $lnil-bone; 
  }
}
.lnil-ambulance-alt-1 {
  &:before {
    content: $lnil-ambulance-alt-1; 
  }
}
.lnil-wheelchair {
  &:before {
    content: $lnil-wheelchair; 
  }
}
.lnil-clipboard-medical {
  &:before {
    content: $lnil-clipboard-medical; 
  }
}
.lnil-cannula {
  &:before {
    content: $lnil-cannula; 
  }
}
.lnil-medicine-alt {
  &:before {
    content: $lnil-medicine-alt; 
  }
}
.lnil-sharing {
  &:before {
    content: $lnil-sharing; 
  }
}
.lnil-dropper-alt {
  &:before {
    content: $lnil-dropper-alt; 
  }
}
.lnil-suitcase-alt-medical {
  &:before {
    content: $lnil-suitcase-alt-medical; 
  }
}
.lnil-hospital-alt-3 {
  &:before {
    content: $lnil-hospital-alt-3; 
  }
}
.lnil-basketball {
  &:before {
    content: $lnil-basketball; 
  }
}
.lnil-online-checkup {
  &:before {
    content: $lnil-online-checkup; 
  }
}
.lnil-lungs {
  &:before {
    content: $lnil-lungs; 
  }
}
.lnil-dumbbell {
  &:before {
    content: $lnil-dumbbell; 
  }
}
.lnil-fluid {
  &:before {
    content: $lnil-fluid; 
  }
}
.lnil-hospital-alt-2 {
  &:before {
    content: $lnil-hospital-alt-2; 
  }
}
.lnil-sthethoscope {
  &:before {
    content: $lnil-sthethoscope; 
  }
}
.lnil-thermometer-alt {
  &:before {
    content: $lnil-thermometer-alt; 
  }
}
.lnil-uterus {
  &:before {
    content: $lnil-uterus; 
  }
}
.lnil-microscope {
  &:before {
    content: $lnil-microscope; 
  }
}
.lnil-test-tube {
  &:before {
    content: $lnil-test-tube; 
  }
}
.lnil-first-aid {
  &:before {
    content: $lnil-first-aid; 
  }
}
.lnil-bed-dentist {
  &:before {
    content: $lnil-bed-dentist; 
  }
}
.lnil-smartwatch-alt-heart-monitor {
  &:before {
    content: $lnil-smartwatch-alt-heart-monitor; 
  }
}
.lnil-hospital-bed-alt {
  &:before {
    content: $lnil-hospital-bed-alt; 
  }
}
.lnil-reload-medical {
  &:before {
    content: $lnil-reload-medical; 
  }
}
.lnil-desktop-computer {
  &:before {
    content: $lnil-desktop-computer; 
  }
}
.lnil-tooth {
  &:before {
    content: $lnil-tooth; 
  }
}
.lnil-hospital-alt-1 {
  &:before {
    content: $lnil-hospital-alt-1; 
  }
}
.lnil-medicine-bottole {
  &:before {
    content: $lnil-medicine-bottole; 
  }
}
.lnil-share-medical {
  &:before {
    content: $lnil-share-medical; 
  }
}
.lnil-hospital-alt-5 {
  &:before {
    content: $lnil-hospital-alt-5; 
  }
}
.lnil-medical-mask {
  &:before {
    content: $lnil-medical-mask; 
  }
}
.lnil-blood-donation {
  &:before {
    content: $lnil-blood-donation; 
  }
}
.lnil-square-medical {
  &:before {
    content: $lnil-square-medical; 
  }
}
.lnil-medical-briefcase-1 {
  &:before {
    content: $lnil-medical-briefcase-1; 
  }
}
.lnil-hospital-alt-4 {
  &:before {
    content: $lnil-hospital-alt-4; 
  }
}
.lnil-hospital-location-alt {
  &:before {
    content: $lnil-hospital-location-alt; 
  }
}
.lnil-virus-alt {
  &:before {
    content: $lnil-virus-alt; 
  }
}
.lnil-life-guard {
  &:before {
    content: $lnil-life-guard; 
  }
}
.lnil-female {
  &:before {
    content: $lnil-female; 
  }
}
.lnil-hospital-alt-6 {
  &:before {
    content: $lnil-hospital-alt-6; 
  }
}
.lnil-hospital-location-alt-1 {
  &:before {
    content: $lnil-hospital-location-alt-1; 
  }
}
.lnil-pulse {
  &:before {
    content: $lnil-pulse; 
  }
}
.lnil-weight {
  &:before {
    content: $lnil-weight; 
  }
}
.lnil-capsules {
  &:before {
    content: $lnil-capsules; 
  }
}
.lnil-brain-check-alt {
  &:before {
    content: $lnil-brain-check-alt; 
  }
}
.lnil-medical-sign-alt {
  &:before {
    content: $lnil-medical-sign-alt; 
  }
}
.lnil-hospital-bed {
  &:before {
    content: $lnil-hospital-bed; 
  }
}
.lnil-disabled {
  &:before {
    content: $lnil-disabled; 
  }
}
.lnil-dollar-value-up {
  &:before {
    content: $lnil-dollar-value-up; 
  }
}
.lnil-rupee-up {
  &:before {
    content: $lnil-rupee-up; 
  }
}
.lnil-atm-alt {
  &:before {
    content: $lnil-atm-alt; 
  }
}
.lnil-yen-note {
  &:before {
    content: $lnil-yen-note; 
  }
}
.lnil-savings {
  &:before {
    content: $lnil-savings; 
  }
}
.lnil-bitcoin-laptop {
  &:before {
    content: $lnil-bitcoin-laptop; 
  }
}
.lnil-atm {
  &:before {
    content: $lnil-atm; 
  }
}
.lnil-bitcoin-website {
  &:before {
    content: $lnil-bitcoin-website; 
  }
}
.lnil-rupee {
  &:before {
    content: $lnil-rupee; 
  }
}
.lnil-pound-down {
  &:before {
    content: $lnil-pound-down; 
  }
}
.lnil-dollar {
  &:before {
    content: $lnil-dollar; 
  }
}
.lnil-money-envelope {
  &:before {
    content: $lnil-money-envelope; 
  }
}
.lnil-check-alt-1 {
  &:before {
    content: $lnil-check-alt-1; 
  }
}
.lnil-vault {
  &:before {
    content: $lnil-vault; 
  }
}
.lnil-mobile-coin {
  &:before {
    content: $lnil-mobile-coin; 
  }
}
.lnil-briefcase-bitcoin {
  &:before {
    content: $lnil-briefcase-bitcoin; 
  }
}
.lnil-taka-coin {
  &:before {
    content: $lnil-taka-coin; 
  }
}
.lnil-nfc {
  &:before {
    content: $lnil-nfc; 
  }
}
.lnil-safebox-1 {
  &:before {
    content: $lnil-safebox-1; 
  }
}
.lnil-dollar-up {
  &:before {
    content: $lnil-dollar-up; 
  }
}
.lnil-taka-up {
  &:before {
    content: $lnil-taka-up; 
  }
}
.lnil-calculator-alt {
  &:before {
    content: $lnil-calculator-alt; 
  }
}
.lnil-bitcoin-cloud {
  &:before {
    content: $lnil-bitcoin-cloud; 
  }
}
.lnil-yen-card {
  &:before {
    content: $lnil-yen-card; 
  }
}
.lnil-euro-coin1 {
  &:before {
    content: $lnil-euro-coin1; 
  }
}
.lnil-money-stash {
  &:before {
    content: $lnil-money-stash; 
  }
}
.lnil-safebox-2 {
  &:before {
    content: $lnil-safebox-2; 
  }
}
.lnil-ruble {
  &:before {
    content: $lnil-ruble; 
  }
}
.lnil-placeholder-dollar {
  &:before {
    content: $lnil-placeholder-dollar; 
  }
}
.lnil-yen-note-1 {
  &:before {
    content: $lnil-yen-note-1; 
  }
}
.lnil-safe-deposit {
  &:before {
    content: $lnil-safe-deposit; 
  }
}
.lnil-bank-alt-1 {
  &:before {
    content: $lnil-bank-alt-1; 
  }
}
.lnil-euro {
  &:before {
    content: $lnil-euro; 
  }
}
.lnil-pound-card {
  &:before {
    content: $lnil-pound-card; 
  }
}
.lnil-head-idea {
  &:before {
    content: $lnil-head-idea; 
  }
}
.lnil-website-money {
  &:before {
    content: $lnil-website-money; 
  }
}
.lnil-tree-money {
  &:before {
    content: $lnil-tree-money; 
  }
}
.lnil-wallet-alt-1 {
  &:before {
    content: $lnil-wallet-alt-1; 
  }
}
.lnil-diamond-alt {
  &:before {
    content: $lnil-diamond-alt; 
  }
}
.lnil-pound {
  &:before {
    content: $lnil-pound; 
  }
}
.lnil-ruble-up {
  &:before {
    content: $lnil-ruble-up; 
  }
}
.lnil-money-protection {
  &:before {
    content: $lnil-money-protection; 
  }
}
.lnil-wallet {
  &:before {
    content: $lnil-wallet; 
  }
}
.lnil-credit-cards1 {
  &:before {
    content: $lnil-credit-cards1; 
  }
}
.lnil-gold-bar {
  &:before {
    content: $lnil-gold-bar; 
  }
}
.lnil-dollar-bag-1 {
  &:before {
    content: $lnil-dollar-bag-1; 
  }
}
.lnil-taxes {
  &:before {
    content: $lnil-taxes; 
  }
}
.lnil-smartphone-stat {
  &:before {
    content: $lnil-smartphone-stat; 
  }
}
.lnil-euro-up {
  &:before {
    content: $lnil-euro-up; 
  }
}
.lnil-rupee-coin {
  &:before {
    content: $lnil-rupee-coin; 
  }
}
.lnil-yen-down {
  &:before {
    content: $lnil-yen-down; 
  }
}
.lnil-dollar-down {
  &:before {
    content: $lnil-dollar-down; 
  }
}
.lnil-money-location {
  &:before {
    content: $lnil-money-location; 
  }
}
.lnil-euro-down {
  &:before {
    content: $lnil-euro-down; 
  }
}
.lnil-coins1 {
  &:before {
    content: $lnil-coins1; 
  }
}
.lnil-dollar-euro-exchange {
  &:before {
    content: $lnil-dollar-euro-exchange; 
  }
}
.lnil-pound-sterling-2 {
  &:before {
    content: $lnil-pound-sterling-2; 
  }
}
.lnil-taka-down {
  &:before {
    content: $lnil-taka-down; 
  }
}
.lnil-coin {
  &:before {
    content: $lnil-coin; 
  }
}
.lnil-rupee-card {
  &:before {
    content: $lnil-rupee-card; 
  }
}
.lnil-placeholder-ruble {
  &:before {
    content: $lnil-placeholder-ruble; 
  }
}
.lnil-yen-up {
  &:before {
    content: $lnil-yen-up; 
  }
}
.lnil-dollar-eye {
  &:before {
    content: $lnil-dollar-eye; 
  }
}
.lnil-placeholder-euro {
  &:before {
    content: $lnil-placeholder-euro; 
  }
}
.lnil-pound-coin {
  &:before {
    content: $lnil-pound-coin; 
  }
}
.lnil-withdraw-money {
  &:before {
    content: $lnil-withdraw-money; 
  }
}
.lnil-bank {
  &:before {
    content: $lnil-bank; 
  }
}
.lnil-credit-card {
  &:before {
    content: $lnil-credit-card; 
  }
}
.lnil-phone-call-dollar {
  &:before {
    content: $lnil-phone-call-dollar; 
  }
}
.lnil-atm-alt-1 {
  &:before {
    content: $lnil-atm-alt-1; 
  }
}
.lnil-dollar-value-down {
  &:before {
    content: $lnil-dollar-value-down; 
  }
}
.lnil-pound-sterling {
  &:before {
    content: $lnil-pound-sterling; 
  }
}
.lnil-shekel {
  &:before {
    content: $lnil-shekel; 
  }
}
.lnil-diamond1 {
  &:before {
    content: $lnil-diamond1; 
  }
}
.lnil-placeholder-pound {
  &:before {
    content: $lnil-placeholder-pound; 
  }
}
.lnil-taka-card {
  &:before {
    content: $lnil-taka-card; 
  }
}
.lnil-dollar-connect {
  &:before {
    content: $lnil-dollar-connect; 
  }
}
.lnil-ruble-down {
  &:before {
    content: $lnil-ruble-down; 
  }
}
.lnil-profit {
  &:before {
    content: $lnil-profit; 
  }
}
.lnil-rupee-down {
  &:before {
    content: $lnil-rupee-down; 
  }
}
.lnil-yen-coin {
  &:before {
    content: $lnil-yen-coin; 
  }
}
.lnil-bank-alt {
  &:before {
    content: $lnil-bank-alt; 
  }
}
.lnil-dollar-card {
  &:before {
    content: $lnil-dollar-card; 
  }
}
.lnil-euro-card {
  &:before {
    content: $lnil-euro-card; 
  }
}
.lnil-cent {
  &:before {
    content: $lnil-cent; 
  }
}
.lnil-bitcoin-up {
  &:before {
    content: $lnil-bitcoin-up; 
  }
}
.lnil-yen {
  &:before {
    content: $lnil-yen; 
  }
}
.lnil-manager {
  &:before {
    content: $lnil-manager; 
  }
}
.lnil-hourglass-dollar {
  &:before {
    content: $lnil-hourglass-dollar; 
  }
}
.lnil-payment-terminal {
  &:before {
    content: $lnil-payment-terminal; 
  }
}
.lnil-pound-up {
  &:before {
    content: $lnil-pound-up; 
  }
}
.lnil-property-balance {
  &:before {
    content: $lnil-property-balance; 
  }
}
.lnil-calculator1 {
  &:before {
    content: $lnil-calculator1; 
  }
}
.lnil-statistics {
  &:before {
    content: $lnil-statistics; 
  }
}
.lnil-money-bag1 {
  &:before {
    content: $lnil-money-bag1; 
  }
}
.lnil-taka {
  &:before {
    content: $lnil-taka; 
  }
}
.lnil-money-protection-alt {
  &:before {
    content: $lnil-money-protection-alt; 
  }
}
.lnil-check-alt {
  &:before {
    content: $lnil-check-alt; 
  }
}
.lnil-safe-box {
  &:before {
    content: $lnil-safe-box; 
  }
}
.lnil-mobile-coin-taka {
  &:before {
    content: $lnil-mobile-coin-taka; 
  }
}
.lnil-bitcoin-chip {
  &:before {
    content: $lnil-bitcoin-chip; 
  }
}
.lnil-clock-dollar {
  &:before {
    content: $lnil-clock-dollar; 
  }
}
.lnil-suitcase-alt {
  &:before {
    content: $lnil-suitcase-alt; 
  }
}
.lnil-tent-alt {
  &:before {
    content: $lnil-tent-alt; 
  }
}
.lnil-taj-mahal {
  &:before {
    content: $lnil-taj-mahal; 
  }
}
.lnil-tent-alt-1 {
  &:before {
    content: $lnil-tent-alt-1; 
  }
}
.lnil-surfboard-alt-2 {
  &:before {
    content: $lnil-surfboard-alt-2; 
  }
}
.lnil-surfboard-alt {
  &:before {
    content: $lnil-surfboard-alt; 
  }
}
.lnil-suitcase-alt-1 {
  &:before {
    content: $lnil-suitcase-alt-1; 
  }
}
.lnil-surfboard-alt-1 {
  &:before {
    content: $lnil-surfboard-alt-1; 
  }
}
.lnil-lighthouse {
  &:before {
    content: $lnil-lighthouse; 
  }
}
.lnil-cable-car {
  &:before {
    content: $lnil-cable-car; 
  }
}
.lnil-travel {
  &:before {
    content: $lnil-travel; 
  }
}
.lnil-beach-ball {
  &:before {
    content: $lnil-beach-ball; 
  }
}
.lnil-beach-chair {
  &:before {
    content: $lnil-beach-chair; 
  }
}
.lnil-tent {
  &:before {
    content: $lnil-tent; 
  }
}
.lnil-eiffel-tower {
  &:before {
    content: $lnil-eiffel-tower; 
  }
}
.lnil-pyramids {
  &:before {
    content: $lnil-pyramids; 
  }
}
.lnil-empire-state-building {
  &:before {
    content: $lnil-empire-state-building; 
  }
}
.lnil-sydney-opera-house {
  &:before {
    content: $lnil-sydney-opera-house; 
  }
}
.lnil-hot-air-baloon {
  &:before {
    content: $lnil-hot-air-baloon; 
  }
}
.lnil-flight-search {
  &:before {
    content: $lnil-flight-search; 
  }
}
.lnil-suitcase {
  &:before {
    content: $lnil-suitcase; 
  }
}
.lnil-airport {
  &:before {
    content: $lnil-airport; 
  }
}
.lnil-beach {
  &:before {
    content: $lnil-beach; 
  }
}
.lnil-surf-board {
  &:before {
    content: $lnil-surf-board; 
  }
}
.lnil-smart-home {
  &:before {
    content: $lnil-smart-home; 
  }
}
.lnil-buy-house {
  &:before {
    content: $lnil-buy-house; 
  }
}
.lnil-lift {
  &:before {
    content: $lnil-lift; 
  }
}
.lnil-fountain {
  &:before {
    content: $lnil-fountain; 
  }
}
.lnil-house-cancelled {
  &:before {
    content: $lnil-house-cancelled; 
  }
}
.lnil-home3 {
  &:before {
    content: $lnil-home3; 
  }
}
.lnil-house-auction-1 {
  &:before {
    content: $lnil-house-auction-1; 
  }
}
.lnil-placeholder-map {
  &:before {
    content: $lnil-placeholder-map; 
  }
}
.lnil-condominium {
  &:before {
    content: $lnil-condominium; 
  }
}
.lnil-air-conditioner1 {
  &:before {
    content: $lnil-air-conditioner1; 
  }
}
.lnil-shopping-mall {
  &:before {
    content: $lnil-shopping-mall; 
  }
}
.lnil-blueprint {
  &:before {
    content: $lnil-blueprint; 
  }
}
.lnil-house-rating {
  &:before {
    content: $lnil-house-rating; 
  }
}
.lnil-house-add {
  &:before {
    content: $lnil-house-add; 
  }
}
.lnil-house-discount {
  &:before {
    content: $lnil-house-discount; 
  }
}
.lnil-fence1 {
  &:before {
    content: $lnil-fence1; 
  }
}
.lnil-hospital1 {
  &:before {
    content: $lnil-hospital1; 
  }
}
.lnil-house-auction {
  &:before {
    content: $lnil-house-auction; 
  }
}
.lnil-apartments {
  &:before {
    content: $lnil-apartments; 
  }
}
.lnil-smart-home-control {
  &:before {
    content: $lnil-smart-home-control; 
  }
}
.lnil-placeholder-house {
  &:before {
    content: $lnil-placeholder-house; 
  }
}
.lnil-website-house {
  &:before {
    content: $lnil-website-house; 
  }
}
.lnil-sign-sale {
  &:before {
    content: $lnil-sign-sale; 
  }
}
.lnil-condominium-1 {
  &:before {
    content: $lnil-condominium-1; 
  }
}
.lnil-billboard2 {
  &:before {
    content: $lnil-billboard2; 
  }
}
.lnil-home-documents {
  &:before {
    content: $lnil-home-documents; 
  }
}
.lnil-warehouse {
  &:before {
    content: $lnil-warehouse; 
  }
}
.lnil-home-11 {
  &:before {
    content: $lnil-home-11; 
  }
}
.lnil-sign-rent {
  &:before {
    content: $lnil-sign-rent; 
  }
}
.lnil-flats {
  &:before {
    content: $lnil-flats; 
  }
}
.lnil-search-home {
  &:before {
    content: $lnil-search-home; 
  }
}
.lnil-swimming-pool1 {
  &:before {
    content: $lnil-swimming-pool1; 
  }
}
.lnil-house-chosen {
  &:before {
    content: $lnil-house-chosen; 
  }
}
.lnil-cottage {
  &:before {
    content: $lnil-cottage; 
  }
}
.lnil-apartments-alt-10 {
  &:before {
    content: $lnil-apartments-alt-10; 
  }
}
.lnil-apartments-alt-1 {
  &:before {
    content: $lnil-apartments-alt-1; 
  }
}
.lnil-factory-alt {
  &:before {
    content: $lnil-factory-alt; 
  }
}
.lnil-apartments-alt-11 {
  &:before {
    content: $lnil-apartments-alt-11; 
  }
}
.lnil-church-alt {
  &:before {
    content: $lnil-church-alt; 
  }
}
.lnil-apartments-alt-2 {
  &:before {
    content: $lnil-apartments-alt-2; 
  }
}
.lnil-boiler {
  &:before {
    content: $lnil-boiler; 
  }
}
.lnil-cottage-alt {
  &:before {
    content: $lnil-cottage-alt; 
  }
}
.lnil-hut-alt-4 {
  &:before {
    content: $lnil-hut-alt-4; 
  }
}
.lnil-apartments-alt-3 {
  &:before {
    content: $lnil-apartments-alt-3; 
  }
}
.lnil-apartments-alt-12 {
  &:before {
    content: $lnil-apartments-alt-12; 
  }
}
.lnil-apartments-alt {
  &:before {
    content: $lnil-apartments-alt; 
  }
}
.lnil-apartments-alt-7 {
  &:before {
    content: $lnil-apartments-alt-7; 
  }
}
.lnil-archway {
  &:before {
    content: $lnil-archway; 
  }
}
.lnil-church1 {
  &:before {
    content: $lnil-church1; 
  }
}
.lnil-apartments-alt-6 {
  &:before {
    content: $lnil-apartments-alt-6; 
  }
}
.lnil-apartments-alt-4 {
  &:before {
    content: $lnil-apartments-alt-4; 
  }
}
.lnil-hut-alt-3 {
  &:before {
    content: $lnil-hut-alt-3; 
  }
}
.lnil-hut-alt-2 {
  &:before {
    content: $lnil-hut-alt-2; 
  }
}
.lnil-flats-alt-2 {
  &:before {
    content: $lnil-flats-alt-2; 
  }
}
.lnil-court-house {
  &:before {
    content: $lnil-court-house; 
  }
}
.lnil-apartments-alt-5 {
  &:before {
    content: $lnil-apartments-alt-5; 
  }
}
.lnil-home-alt {
  &:before {
    content: $lnil-home-alt; 
  }
}
.lnil-buildings-alt-2 {
  &:before {
    content: $lnil-buildings-alt-2; 
  }
}
.lnil-buildings {
  &:before {
    content: $lnil-buildings; 
  }
}
.lnil-castle-alt-7 {
  &:before {
    content: $lnil-castle-alt-7; 
  }
}
.lnil-castle-alt-6 {
  &:before {
    content: $lnil-castle-alt-6; 
  }
}
.lnil-buildings-alt-3 {
  &:before {
    content: $lnil-buildings-alt-3; 
  }
}
.lnil-burj-al-arab {
  &:before {
    content: $lnil-burj-al-arab; 
  }
}
.lnil-cottage-alt-1 {
  &:before {
    content: $lnil-cottage-alt-1; 
  }
}
.lnil-buildings-alt-1 {
  &:before {
    content: $lnil-buildings-alt-1; 
  }
}
.lnil-castle-alt-5 {
  &:before {
    content: $lnil-castle-alt-5; 
  }
}
.lnil-castle {
  &:before {
    content: $lnil-castle; 
  }
}
.lnil-cottage-alt-2 {
  &:before {
    content: $lnil-cottage-alt-2; 
  }
}
.lnil-buildings-alt-4 {
  &:before {
    content: $lnil-buildings-alt-4; 
  }
}
.lnil-castle-alt-1 {
  &:before {
    content: $lnil-castle-alt-1; 
  }
}
.lnil-home-alt-13 {
  &:before {
    content: $lnil-home-alt-13; 
  }
}
.lnil-court {
  &:before {
    content: $lnil-court; 
  }
}
.lnil-home-alt-12 {
  &:before {
    content: $lnil-home-alt-12; 
  }
}
.lnil-buildings-alt-5 {
  &:before {
    content: $lnil-buildings-alt-5; 
  }
}
.lnil-factory {
  &:before {
    content: $lnil-factory; 
  }
}
.lnil-castle-alt-2 {
  &:before {
    content: $lnil-castle-alt-2; 
  }
}
.lnil-castle-alt-3 {
  &:before {
    content: $lnil-castle-alt-3; 
  }
}
.lnil-boiler-alt {
  &:before {
    content: $lnil-boiler-alt; 
  }
}
.lnil-housing-alt {
  &:before {
    content: $lnil-housing-alt; 
  }
}
.lnil-court-building {
  &:before {
    content: $lnil-court-building; 
  }
}
.lnil-apartments1 {
  &:before {
    content: $lnil-apartments1; 
  }
}
.lnil-fortress {
  &:before {
    content: $lnil-fortress; 
  }
}
.lnil-flats-alt {
  &:before {
    content: $lnil-flats-alt; 
  }
}
.lnil-home-alt-6 {
  &:before {
    content: $lnil-home-alt-6; 
  }
}
.lnil-factory-alt-1 {
  &:before {
    content: $lnil-factory-alt-1; 
  }
}
.lnil-house-alt-4 {
  &:before {
    content: $lnil-house-alt-4; 
  }
}
.lnil-apartments-alt-8 {
  &:before {
    content: $lnil-apartments-alt-8; 
  }
}
.lnil-house-alt {
  &:before {
    content: $lnil-house-alt; 
  }
}
.lnil-apartments-alt-9 {
  &:before {
    content: $lnil-apartments-alt-9; 
  }
}
.lnil-factory-alt-2 {
  &:before {
    content: $lnil-factory-alt-2; 
  }
}
.lnil-house-alt-7 {
  &:before {
    content: $lnil-house-alt-7; 
  }
}
.lnil-house-alt-6 {
  &:before {
    content: $lnil-house-alt-6; 
  }
}
.lnil-factory-alt-3 {
  &:before {
    content: $lnil-factory-alt-3; 
  }
}
.lnil-court-alt {
  &:before {
    content: $lnil-court-alt; 
  }
}
.lnil-home-alt-4 {
  &:before {
    content: $lnil-home-alt-4; 
  }
}
.lnil-buildings-alt {
  &:before {
    content: $lnil-buildings-alt; 
  }
}
.lnil-arch {
  &:before {
    content: $lnil-arch; 
  }
}
.lnil-house-alt-3 {
  &:before {
    content: $lnil-house-alt-3; 
  }
}
.lnil-factory-alt-6 {
  &:before {
    content: $lnil-factory-alt-6; 
  }
}
.lnil-factory-alt-4 {
  &:before {
    content: $lnil-factory-alt-4; 
  }
}
.lnil-house-alt-11 {
  &:before {
    content: $lnil-house-alt-11; 
  }
}
.lnil-castle-alt {
  &:before {
    content: $lnil-castle-alt; 
  }
}
.lnil-flats1 {
  &:before {
    content: $lnil-flats1; 
  }
}
.lnil-factory-alt-5 {
  &:before {
    content: $lnil-factory-alt-5; 
  }
}
.lnil-cctv {
  &:before {
    content: $lnil-cctv; 
  }
}
.lnil-video-playback {
  &:before {
    content: $lnil-video-playback; 
  }
}
.lnil-camera-3 {
  &:before {
    content: $lnil-camera-3; 
  }
}
.lnil-repeat-one {
  &:before {
    content: $lnil-repeat-one; 
  }
}
.lnil-backward-alt {
  &:before {
    content: $lnil-backward-alt; 
  }
}
.lnil-hd-video-alt {
  &:before {
    content: $lnil-hd-video-alt; 
  }
}
.lnil-webcam {
  &:before {
    content: $lnil-webcam; 
  }
}
.lnil-gallery {
  &:before {
    content: $lnil-gallery; 
  }
}
.lnil-expand-alt-1 {
  &:before {
    content: $lnil-expand-alt-1; 
  }
}
.lnil-volume {
  &:before {
    content: $lnil-volume; 
  }
}
.lnil-stop {
  &:before {
    content: $lnil-stop; 
  }
}
.lnil-video-camera-alt {
  &:before {
    content: $lnil-video-camera-alt; 
  }
}
.lnil-volume-mute {
  &:before {
    content: $lnil-volume-mute; 
  }
}
.lnil-previous {
  &:before {
    content: $lnil-previous; 
  }
}
.lnil-fast-forward {
  &:before {
    content: $lnil-fast-forward; 
  }
}
.lnil-volume-alt {
  &:before {
    content: $lnil-volume-alt; 
  }
}
.lnil-play {
  &:before {
    content: $lnil-play; 
  }
}
.lnil-backward {
  &:before {
    content: $lnil-backward; 
  }
}
.lnil-volume-medium {
  &:before {
    content: $lnil-volume-medium; 
  }
}
.lnil-full-screen {
  &:before {
    content: $lnil-full-screen; 
  }
}
.lnil-next {
  &:before {
    content: $lnil-next; 
  }
}
.lnil-volume-high {
  &:before {
    content: $lnil-volume-high; 
  }
}
.lnil-video {
  &:before {
    content: $lnil-video; 
  }
}
.lnil-video-cut {
  &:before {
    content: $lnil-video-cut; 
  }
}
.lnil-video-alt {
  &:before {
    content: $lnil-video-alt; 
  }
}
.lnil-volume-low {
  &:before {
    content: $lnil-volume-low; 
  }
}
.lnil-video-camera-alt-4 {
  &:before {
    content: $lnil-video-camera-alt-4; 
  }
}
.lnil-shuffle {
  &:before {
    content: $lnil-shuffle; 
  }
}
.lnil-pause {
  &:before {
    content: $lnil-pause; 
  }
}
.lnil-forward {
  &:before {
    content: $lnil-forward; 
  }
}
.lnil-slideshow {
  &:before {
    content: $lnil-slideshow; 
  }
}
.lnil-video-alt-1 {
  &:before {
    content: $lnil-video-alt-1; 
  }
}
.lnil-video-camera-alt-1 {
  &:before {
    content: $lnil-video-camera-alt-1; 
  }
}
.lnil-video-camera-alt-3 {
  &:before {
    content: $lnil-video-camera-alt-3; 
  }
}
.lnil-film {
  &:before {
    content: $lnil-film; 
  }
}
.lnil-hd-video {
  &:before {
    content: $lnil-hd-video; 
  }
}
.lnil-frame-expand {
  &:before {
    content: $lnil-frame-expand; 
  }
}
.lnil-video-camera-alt-2 {
  &:before {
    content: $lnil-video-camera-alt-2; 
  }
}

