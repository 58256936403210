.footer {
  background-color: $color-tertiary-dark-1;
  padding: 5rem 0 0;
  font-size: 1.2rem;

  &:hover {
    .lni-facebook-oval {
      color: #4267b2;
    }
    .lni-youtube {
      color: red;
      // border-top-left-radius: 2rem;
    }

    .lni-instagram-original {
      background: linear-gradient(
        45deg,
        #405de6,
        #5851db,
        #833ab4,
        #c13584,
        #e1306c,
        #fd1d1d
      );
      color: white;
      border-radius: 2rem;
    }
    .lni-whatsapp {
      color: #8bc34a;
    }
  }

  &-title {
    color: $color-primary-dark;
    text-align: right;
  }
  &-title-secondary {
    color: $color-white;
    padding-left: 5rem;
  }

  &-flag {
    width: 100px;
    & img {
      width: 100%;
    }
  }
  &-address {
    color: $color-white;
  }

  &__logo {
    width: 15rem;
    height: auto;
  }

  &__navigation {
    border-top: 1px solid $color-grey-dark;
    padding-top: 2rem;
    display: block;
    &:not(:last-child) {
      margin-bottom: 5rem;
    }

    span {
      &:not(:last-child) {
        padding-right: 3rem;
      }
      i {
        color: $color-grey-dark;
        font-size: 5rem;
      }
    }
  }

  &__list {
    list-style: none;
  }

  &__item {
    // display: inline-block;
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
  &__link {
    border: none;
    background: none;
    color: $color-white;

    &:link,
    &:visited {
      color: $color-grey-light-1;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
    }
    &:hover,
    &:active {
      color: $color-primary;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.4);
    }
  }
  &__copyright {
    border-top: 1px solid $color-grey-light-2;
    text-align: center;
    padding-top: 2rem;
    margin-top: 5rem !important;
    display: inline-block;
    width: 80%;
    color: $color-grey-light-2;
  }
  p {
    color: $color-grey-light-2;
    font-size: 1.4rem;
  }
}
