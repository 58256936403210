$buttonHeight: 54;
.btn {
  &,
  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 1.5rem 4rem;
    display: inline-block;
    // border-radius: 10rem;
    transition: all 0.2s;
    position: relative;
    font-size: $default-font-size;

    // Changes for  <buuton> element
    border: none;
    cursor: pointer;
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2rem rgba($color-black, 0.2);

    &::after {
      transform: scaleX(1.4) scaleY(1.6);
      opacity: 0;
    }
  }

  &:active,
  &:focus {
    transform: translateY(-1px);
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.2);
  }

  &--white {
    background-color: $color-white;
    color: $color-grey-dark;

    &::after {
      background-color: $color-white;
    }
  }

  &--green {
    background-color: $color-primary;
    color: $color-white;
    width: 100%;
    &::after {
      background-color: $color-primary-dark;
    }
  }

  &--yellow {
    background-color: $color-yellow;
    color: $color-black;

    &::after {
      color: $color-white;
      background-color: $color-yellow;
    }
  }

  &--outline {
    background-color: $color-white;
    color: $color-black;
    border: 1px solid $color-grey-dark-3;

    &::after {
      // color: $color-white;
      // background-color: $color-yellow;
    }
  }
  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    // border-radius: 10rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
  }

  &--animated {
    animation: moveInBottom 0.5s ease-out;
    animation-fill-mode: backwards;
  }
}

.btn-text {
  &:link,
  &:visited {
    font-size: $default-font-size;
    color: $color-primary;
    display: inline-block;
    text-decoration: none;
    border-bottom: 0.1rem solid $color-primary;
    padding: 0.3rem;
  }

  &-small {
    padding: 0.5rem !important;
  }

  &:hover {
    background-color: $color-primary;
    color: $color-white;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.15);
    transform: translateY(-0.2rem);
  }

  &:active {
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.15);
    transform: translateY(0);
  }
}

.btn-nav {
  padding: 0.5rem;
  &,
  &:link,
  &:visited {
    text-decoration: none;
    display: inline-block;
    font-size: $default-font-size;
    color: $color-grey-dark-3;
    font-weight: 400;
    border: none;
    cursor: pointer;
    transition: all 0.4s;
  }
  &:not(:last-child) {
    margin-right: 1rem;
  }
  &:not(:first-child) {
    margin-left: 0.5rem;
  }

  &:hover,
  &:active {
    // background-color: rgba($color-yellow, 1);
    color: rgba($color-primary, 1);
    // padding: 3.5rem 1.5rem;
  }
  &:last-child {
    border: 1px solid rgba($color-primary, 1);
    padding: 0.5rem 2rem;
    border-radius: 0.5rem;
    color: rgba($color-primary, 1);

    &:hover,
    &:active {
      color: rgba($color-white, 1) !important;
      background-color: rgba($color-primary, 1);
    }
  }
}
.active {
  // background-color: rgb(20 66 51 / 80%) !important;
  color: rgba($color-primary, 1) !important;
  font-weight: 600 !important;
  &:last-child {
    color: rgba($color-white, 1) !important;
    background-color: rgba($color-primary, 1);
  }
}

.btn-text {
  &:link,
  &:visited {
    font-size: $default-font-size;
    color: $color-grey-dark-3;
    display: inline-block;
    text-decoration: none;
    border-bottom: 0.1rem solid $color-grey-dark-3;
    padding: 0.3rem;
  }

  &:hover {
    background-color: $color-grey-dark-3;
    color: $color-white;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.15);
    transform: translateY(-0.2rem);
  }

  &:active {
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.15);
    transform: translateY(0);
  }
}

.btn-text-orange {
  &:link,
  &:visited {
    font-size: #ff9800;
    color: white;
    display: inline-block;
    text-decoration: none;
    border-bottom: 0.1rem solid $color-grey-dark-3;
    padding: 0.3rem 1rem;
    background-color: #ff9800;
    font-size: 2rem;
  }

  &:hover {
    background-color: #ff9800;
    color: $color-white;
    box-shadow: 0 1rem 2rem rgba($color-black, 0.15);
    transform: translateY(-0.2rem);
  }

  &:active {
    box-shadow: 0 0.5rem 1rem rgba($color-black, 0.15);
    transform: translateY(0);
  }
}
.btn-anim-bevl {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0;
  height: #{$buttonHeight + "px"};
  // border-radius: 5rem;

  font-size: 4rem;
  text-align: center;
  color: #4b958b;
  letter-spacing: 0.4px;
  overflow: hidden;
  outline: 0 !important;
  padding: 0 1rem;
  transform: scale(1.01);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:before,
  &:after {
    content: " ";
    // width: 80px;
    // // max-width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.135s ease-in-out;
    transition: all 0.135s cubic-bezier(0.74, 0.16, 0.24, 0.92);
    transition-delay: 0.15s;
  }
  // &:before {
  //   left: 0;
  // }
  // &:after {
  //   right: 0;
  // }
  &:hover {
    transform: scale(1.07);
    font-size: 4.1rem;

    // i {
    //   box-shadow: 0 0 4rem rgba($color-black, 0.2);
    //   border-radius: 5rem;
    // }
  }
}
