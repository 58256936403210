.form {
  &__group:not(:last-child) {
    margin-bottom: 1rem;
  }
  &__input {
    -webkit-appearance: none;
    font-size: 1rem;
    font-family: inherit;
    color: inherit;
    padding: 0.5rem 2rem;

    border-radius: 2px;
    background-color: rgb(227 231 230);
    border: none;
    border-bottom: 3px solid transparent;
    width: 100%;
    display: block;
    transition: all 0.3s;

    &:focus {
      outline: none;
      box-shadow: 0 1rem 2rem rgba($color-black, 0.1);
      border-bottom: 3px solid $color-primary;

      &:invalid {
        border-bottom: 3px solid $color-secondary-dark;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-grey-dark-2;
    }
  }

  &__label {
    font-size: 1rem;
    font-weight: 700;
    // margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
  }
  &__input:placeholder-shown + &__label {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-4rem);
    height: 0;
  }

  &__radio-group {
    width: 40%;
    display: inline-block;
  }
  &__radio-input {
    display: none;
  }
  &__radio-label {
    font-size: $default-font-size;
    cursor: pointer;
    position: relative;
    padding-left: 4rem;
  }
  &__radio-button {
    height: 3rem;
    width: 3rem;
    border: 5px solid $color-primary;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
    top: -0.5rem;

    &::after {
      content: "";
      display: block;
      height: 1.3rem;
      width: 1.3rem;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $color-primary;
      opacity: 0;
      transition: opacity 0.2s;
    }
  }

  &__radio-input:checked ~ &__radio-label &__radio-button::after {
    opacity: 1;
  }
}

.thank-msg {
  font-size: 1.6rem;
  color: $color-primary-dark;
  padding: 2rem 0;
}

.loader {
  border: 7px solid #f3f3f3;
  border-radius: 50%;
  border-top: 7px solid #009688;
  width: 5rem;
  height: 5rem;
  position: absolute;
  right: -9.5rem;
  top: 0;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
