body {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: $color-grey-dark-3;
  padding: 0;
  box-sizing: border-box;
  background-color: rgba($color-white, 1);
  overflow-x: hidden;
}

.heading-primary {
  color: $color-white;
  text-transform: uppercase;
  backface-visibility: hidden;
  margin-bottom: 6rem;

  &--main {
    display: block;
    font-size: 6rem;
    font-weight: 400;
    letter-spacing: 3.5rem;
    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    /*   
    animation-delay: 3s;
    animation-iteration-count: 3;
     */

    // @include respond(phone) {
    //   letter-spacing: 1rem;
    //   font-size: 5rem;
    // }
  }

  &--sub {
    display: block;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 1.75rem;
    animation: moveInRight 1s ease-out;

    @include respond(phone) {
      letter-spacing: 0.5rem;
    }
  }
}

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  display: inline-block;
  animation-name: moveInLeft;
  animation-duration: 1s;
  animation-timing-function: ease-out;

  &--sub {
    display: block;
    font-size: 2rem;
    animation: moveInRight 1s ease-out;

    @include respond(phone) {
      letter-spacing: 0.5rem;
    }
  }
}

.heading-tertiary {
  font-size: $default-font-size, font-we 700;
  text-transform: uppercase;
}

.heading-quaternary {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-transform: uppercase;
}

.paragraph {
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.error {
  font-size: $default-font-size;
  color: $color-danger;
}
